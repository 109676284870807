import React from 'react';
import "./ProjectsShowcase.scss";
import { Card } from 'primereact/card';
import Aryanaow from '../../../../Assets/Our-Work/AryaNow_Image_Projects.png';
import Laulama from '../../../../Assets/Our-Work/LaulamaSchools_Image_Projects.png';
import Autism from '../../../../Assets/Our-Work/ACC_Image_Projects.png';
import { Button } from 'primereact/button';

const ProjectsShowcase = () => {
    return (
        <div className='projectshowcase-section'>
            <div className='m-5'>
                <div className='project-content'>
                    <h3 className='project-heading'>Projects Showcase</h3>
                    <span>Explore our curated showcase of startups & student-led startups poised for growth and ready for investment.</span>
                </div>

                <div className="flex align-items-center flex-wrap projects-details-section mt-5">
                    <div className="col-4 h-full col-section">
                        <div className=" h-full">
                            <div className=" flex flex-column  h-full">
                                <div className="projects-contents">
                                    <div>
                                        <img src={Aryanaow} alt='Aryanaow' />
                                    </div>
                                    <div className='project-content-section'>
                                        <h3 className="projects-heading"><b>AryaNow</b></h3>
                                        <p>Sector: <span>Mental Health Sector</span></p>
                                        <p className='paragraph'>AryaNow is a virtual mental health care provider focused on providing the best patient experience. We use audio and video capabilities from the digital devices our patients use securely
                                            to help solve their mental health issues anytime, anywhere. There is no waiting for treatment, and it is Easy to access and patient-centric.</p>
                                    </div>
                                    <div className='flex align-items-center justify-content-between project-money-details'>
                                        <div>
                                            <p>Raised</p>
                                            <span>$$$$</span>
                                        </div>
                                        <div>
                                            <p>Investors</p>
                                            <span>$$$$</span>
                                        </div>
                                        <div>
                                            <a href="/investor/AryaNow"><Button className="learnmore">Learn More</Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4 h-full col-section">
                        <div className=" h-full">
                            <div className=" flex flex-column  h-full">
                                <div className="projects-contents">
                                    <div>
                                        <img src={Laulama} alt='Laulama' />
                                    </div>
                                    <div className='project-content-section'>
                                        <h3 className="projects-heading"><b>Laulama</b></h3>
                                        <p>Sector: <span>Education Sector</span></p>
                                        <p className='paragraph'>Laulama school is working to innovate education’s underlying improvement system for breakthroughs that transform schools for their leaders, educators, and students. Laulama Learning and Laulama Schools result from a 13-year venture focused on impacting education in Hawaii. This venture started
                                            in 2010 with the formation of an organizational transformation practice called Optimum Business Solutions.</p>
                                    </div>
                                    <div className='flex align-items-center justify-content-between project-money-details'>
                                        <div>
                                            <p>Raised</p>
                                            <span>$$$$</span>
                                        </div>
                                        <div>
                                            <p>Investors</p>
                                            <span>$$$$</span>
                                        </div>
                                        <div>
                                            <a href="/investor/Laulama"><Button className="learnmore">Learn More</Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4 h-full col-section">
                        <div className=" h-full">
                            <div className=" flex flex-column  h-full">
                                <div className="projects-contents">
                                    <div>
                                        <img src={Autism} alt='Autism' />
                                    </div>
                                    <div className='project-content-section'>
                                        <h3 className="projects-heading"><b>Autism Complete Care</b></h3>
                                        <p>Sector: <span>Child Autism Sector</span></p>
                                        <p className='paragraph'>Autism Complete Care started with a team of experts in behavior analysis, psychiatry, and technologists who wanted to change how autism therapy is done. They saw that every child is special and that therapy should be made just for them. They made a clinic that understands each child’s needs, includes expert mental health advice, and works closely with families in the therapy journey.
                                            We began in a small, friendly place, but with a big dedication to our values and the families we help. Our story is about caring deeply, never giving up, and believing in the bright future of every child with autism.</p>
                                    </div>
                                    <div className='flex align-items-center justify-content-between project-money-details'>
                                        <div>
                                            <p>Raised</p>
                                            <span>$$$$</span>
                                        </div>
                                        <div>
                                            <p>Investors</p>
                                            <span>$$$$</span>
                                        </div>
                                        <div>
                                            <a href="/investor/Autism"><Button className="learnmore">Learn More</Button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectsShowcase;