import React from 'react';
import "./Startup2launchTeam.scss";
import Venket from '../../../Assets/AboutUs/Team cards/Group 105.png';
import Guillermo from '../../../Assets/AboutUs/Team cards/Group 83.png';
import Prathyusha from '../../../Assets/AboutUs/Team cards/Group 113.png';
import Mahika from '../../../Assets/AboutUs/Team cards/Group 135.png';
import Harish from '../../../Assets/AboutUs/Team cards/Group 109.png';
import Krishna from '../../../Assets/AboutUs/Team cards/Group 110.png';
import Ram from'../../../Assets/AboutUs/Team cards/Group 112.png';
import Jayanth from '../../../Assets/AboutUs/Team cards/Group 107.png';
import Shaji from '../../../Assets/AboutUs/Team cards/Group 85.png';
import Tania from '../../../Assets/AboutUs/Team cards/Group 82.png';
 import Dani from '../../../Assets/AboutUs/Team cards/Group 111.png';
import Asiba from '../../../Assets/AboutUs/Team cards/Group 87.png';
import Jancy from '../../../Assets/AboutUs/Team cards/Group 106.png';
import Nanthini from '../../../Assets/AboutUs/Team cards/Group 90.png';
import Richardson from '../../../Assets/AboutUs/Team cards/Group 92.png';
import Pravin from'../../../Assets/AboutUs/Team cards/Group 108.png';
import Jerin from '../../../Assets/AboutUs/Team cards/Group 94.png';
import John from '../../../Assets/AboutUs/Team cards/Group 91.png';
import Raja from '../../../Assets/AboutUs/Team cards/Group 93.png';
import Kumareson from '../../../Assets/AboutUs/Team cards/Group 88.png';
import Anto from '../../../Assets/AboutUs/Team cards/Group 89.png';


const Startup2launchTeam = () => {

    return (
        <>

            <div id='ourTeam' className='main-sec-TM'>

                <div className='headerSec'>
                    <div className='left'>
                        <span>Our Team </span>
                    </div>
                    <div className='right'>
                        Collaboration is at our core, with a highly skilled team of 13 experts spanning the USA, Mexico, and India. We possess the diverse knowledge and experience necessary to bring your vision to fruition.
                    </div>
                </div>

                <div className='team-sec'>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Anto} alt='AntoImage' />
                        </div>
                        <div className='name'>Anto</div>
                        <div className='position'>Software Engineer</div>
                    </div> */}

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Dani} alt='ArpitaImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Arpita</div>
                            <div className='position'>Head of Recruitment</div>
                        </div>
                    </div>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Asiba} alt='AsibaImage' />
                        </div>
                        <div className='name'>Asiba</div>
                        <div className='position'>Software Engineer</div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Dani} alt='DaniImage' />
                        </div>
                        <div className='name'>Dani</div>
                        <div className='position'>Director, Customer Experience</div>
                    </div> */}

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Guillermo} alt='GuillermoImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Guillermo</div>
                            <div className='position'>Sr. UX, Experience Analyst</div>
                        </div>
                    </div>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Harish} alt='HarishImage' />
                        </div>
                        <div className='name'>Harish</div>
                        <div className='position'>Director, Software Engineering</div>
                    </div> */}

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Jancy} alt='JancyImage' />
                        </div>
                        <div className='name'>Jancy</div>
                        <div className='position'>Quality Control Engineer</div>
                    </div> */}

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Jayanth} alt='JayanthImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Jayanth</div>
                            <div className='position'>Software Engineer</div>
                        </div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Jerin} alt='JerinImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Jerin</div>
                            <div className='position'>Sr. Software Engineer</div>
                        </div>
                    </div>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={John} alt='JohnImage' />
                        </div>
                        <div className='name'>John</div>
                        <div className='position'>Quality Control Engineer</div>
                    </div> */}

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Krishna} alt='KrishnaImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Krishna</div>
                            <div className='position'>Director, Software Engineering</div>
                        </div>
                    </div>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Kumareson} alt='KumaresonImage' />
                        </div>
                        <div className='name'>Kumareson</div>
                        <div className='position'>Software Engineer</div>
                    </div> */}

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Mahika} alt='MahikaImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Mahika</div>
                            <div className='position'>Product Owner</div>
                        </div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Harish} alt='NickImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Nick</div>
                            <div className='position'>Strategic Advisor</div>
                        </div>
                    </div>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Nanthini} alt='NanthiniImage' />
                        </div>
                        <div className='name'>Nanthini</div>
                        <div className='position'>Software Engineer</div>
                    </div> */}

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Prathyusha} alt='PrathyushaImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Prathyusha</div>
                            <div className='position'>Lead, Software Engineer</div>
                        </div>
                    </div>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Pravin} alt='PravinImage' />
                        </div>
                        <div className='name'>Pravin</div>
                        <div className='position'>Sr. Software Engineer</div>
                    </div> */}

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Raja} alt='RajaImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Raja</div>
                            <div className='position'>DevOps Engineer</div>
                        </div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Ram} alt='RamImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Ram</div>
                            <div className='position'>Sr. Data Scientist</div>
                        </div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Richardson} alt='RichardsonImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Richardson</div>
                            <div className='position'>Sr. Software Engineer</div>
                        </div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Shaji} alt='ShajiImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Shaji</div>
                            <div className='position'>Lead, Software Engineer</div>
                        </div>
                    </div>

                    <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Tania} alt='TaniaImage' />
                        </div>
                        <div className='details'>
                            <div className='name'>Tania</div>
                            <div className='position'>Sr. UI,  Experience Analyst</div>
                        </div>
                    </div>

                    {/* <div className='team-member'>
                        <div className='img-sec'>
                            <img src={Venket} alt='VenketImage' />
                        </div>
                        <div className='name'>Venket</div>
                        <div className='position'>Technical Advisor</div>
                    </div> */}

                </div>

            </div>
            

        </>
    );
};

export default Startup2launchTeam;
