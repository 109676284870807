import React, { useEffect, useState } from 'react';
import Imageppl from "../../../Assets/Recruitment/POD-mode.svg";
import ImageUser from "../../../Assets/Recruitment/Unicorn-hire.svg";
import Bannerimage from "../../../Assets/Recruitment/Launch-your-career-banner.png";
import { Button } from 'primereact/button';

const TalentHiringSection = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className="section-heading m-5 text-center">
                <h1>Hiring Talent Models</h1>
            </div>

            <div className="hiring-section grid m-4 gap-4 pb-5">

                <div className="content-section col p-5">
                    <div className='flex section'>
                        <img src={Imageppl} alt="Logo" width={110} />
                        <div className='content-text' >
                            <span>
                                <b>Full Teams</b>
                            </span>
                            <span>
                                Build a tailored team for your startup's growth
                            </span>
                        </div>
                    </div>
                </div>

                <div className="content-section col p-5">
                    <div className='flex section'>
                        <img src={ImageUser} alt="Logo" width={100} />
                        <div className='content-text' >
                            <span>
                                <b>Key Hire</b>
                            </span>
                            <span>
                                Enhance your team's capabilities by adding specialized, expert talent.
                            </span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="m-5 text-center">
                <Button className='button-style-primary'><a href="/newclientform">Start Hiring</a></Button>
            </div>

            <div className='m-5 launch-career-section'>
                <div className='border-y-3'>
                    <div className="my-4">

                        {window.innerWidth <= 1128 && (
                            <div className="section-heading m-5 text-center">
                                <h1 style={{ color: "#000" }}>Launch Your <span style={{ borderRadius: "50px", background: "rgba(255, 185, 137, 0.25)", width: "fit-content", padding: "0 15px" }}>Career</span></h1>
                            </div>
                        )}

                        <section className="layout py-0">
                            <div className='banner-content'>
                                <section >
                                    {windowWidth >= 1129 &&
                                        <h1 className='my-5 text-left' style={{ color: "#000" }}>Launch Your <span style={{ borderRadius: "50px", background: "rgba(255, 185, 137, 0.25)", width: "fit-content", padding: "0 15px" }}>Career</span></h1>
                                    }
                                    <p style={{ color: "#000000", fontWeight: "normal" }}>Join us for impactful work, collaborative culture, and professional growth.</p>
                                    <p style={{ color: "#000000", fontWeight: "normal" }}>Shape the future of entrepreneurship with us! </p>
                                </section>

                                <div className='banner-button'>
                                    <Button className='button-style-primary'><a href="https://apply.workable.com/startup2launch/" target='_blank'>Explore Opportunities</a></Button >
                                </div>
                            </div>

                            <div className='banner-images'>
                                <img src={Bannerimage} alt='Bannerimage' />
                            </div>

                        </section>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TalentHiringSection