import React, { useState, useEffect } from 'react'
import './NewClient-Form.scss';
import Contactus from '../../Assets/NewClient-Form/Contact_Us_Image.png';
import logo from '../../Assets/NewClient-Form/logo.png';
import Header from '../Home/Header/Header';
import Footer from '../Home/Footer/Footer';
import { ScrollTop } from 'primereact/scrolltop';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Contact_Us, CONTENT_HEADERS } from '../../Utils/LandingService';
import CalendlyComponent from '../../Shared-Components/Calendly/Calendly';
import { Dialog } from 'primereact/dialog';

const NewClientForm = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        phoneNumberType: '',
        category: '',
        description: '',
        starTtime: '',
        endTime: '',
        selectedDays: [],
        termsChecked: false,
    });

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const onSubmit = () => {
        console.log(formData);
        const clientFormData = {
            name: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            type: formData.phoneNumberType,
            category: formData.category,
            description: formData.description,
            contactHours: formData.starTtime + '-' + formData.endTime,
            daysOfWeek: formData.selectedDays,
        };

        axios
            .post(Contact_Us, clientFormData, { headers: CONTENT_HEADERS })
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            termsChecked: e.checked,
        });
    }

    const [visible, setVisible] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            Request Info
        </div>
    );

    const footerContent = (
        <div>
            <Button type="submit" label="Send me more info" severity="primary" onClick={onSubmit} />
        </div>
    );

    const pageSettings = {
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '#8DAF61',
        textColor: '#352750'
    }

    return (
        <>
            <ScrollTop />
            <Header />
            <div className='contact-us'>
                <div className="contact-section grid">
                    <div className="contact-left-section col-12 md:col-12 lg:col-6">
                        <img className='contactus-img' src={Contactus} />
                        <div className='info'>
                            <div className='cu-sub-text'>Your idea deserves the best start. Contact us for insights and support on your journey to market fit and beyond.</div>
                            <div className='ceo'>Startup2Launch CEO</div>
                            <div className='contact-info'>
                                <div className='phone-info'>
                                    <div className='phone-text'>Call Us</div>
                                    <div className='ph-no'>(469) 271-9376</div>
                                </div>
                                <div className='or'>Or</div>
                                <div>
                                    <div className='socials'>Follow Us on</div>
                                    <ul className='socialicons-list'>
                                        <a href='https://www.instagram.com/startup2launchhq' target="_blank">
                                            <i className="pi pi-instagram mr-3" style={{ color: "white", fontSize: "1.5rem" }}></i>
                                        </a>
                                        <a href='https://www.facebook.com/profile.php?id=61553933195892' target="_blank">
                                            <i className="pi pi-facebook mr-3" style={{ color: "white", fontSize: "1.5rem" }}></i>
                                        </a>
                                        <a href='https://www.linkedin.com/company/startup2launch/?trk=feed_main-feed-card_feed-actor-name' target="_blank">
                                            <i className="pi pi-linkedin mr-3" style={{ color: "white", fontSize: "1.5rem" }}></i>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="contact-right-section col-12 md:col-12 lg:col-6">
                        <div className='border-left'>
                            <div className='text1'>Ready to turn your big idea into reality? We're here to help!</div>
                        </div>
                        <div className='border-left'>
                            <div className='text1'>From key hires to full teams, we turn your vision into a winning lineup.</div>
                        </div>
                        <div className='border-left'>
                            <div className='text1'>High schoolers, become a Startup Scholar with us-launch your future today!</div>
                        </div>
                        <div className='text2'>Get in touch – we're eager to learn about your project and offer our support.</div>
                        <div className='contactus-buttons'>
                            <div className='calendly-component'>
                                <CalendlyComponent 
                                buttonLabel="Schedule an appointment"
                                pageSettings={pageSettings}
                                url="https://calendly.com/contactus-s2l/30min"/>
                            </div>
                            <div>
                                <Button className='ask-button' label="Request Info" onClick={() => setVisible(true)} severity="help" outlined />
                            </div>
                        </div>
                    </div>
                    <Dialog visible={visible} modal header={headerElement} footer={footerContent} className="request-form-dialog" draggable={false} onHide={() => setVisible(false)}>
                        <div className="client-form">
                            <div className="grid">
                                <div className="field col-12 md:col-6 flex flex-column gap-2">
                                    <label htmlFor="firstName">First Name</label>
                                    <InputText id="firstName" name="firstName" value={formData.firstName} onChange={onInputChange} />
                                </div>
                                <div className="field col-12 md:col-6 flex flex-column gap-2">
                                    <label htmlFor="lastName">Last Name</label>
                                    <InputText id="lastName" name="lastName" value={formData.lastName} onChange={onInputChange} />
                                </div>

                                <div className="field col-12 flex flex-column gap-2">
                                    <label htmlFor="email">Email</label>
                                    <InputText id="email" name="email" value={formData.email} onChange={onInputChange} />
                                </div>
                                <div className="field col-12 flex flex-column gap-2">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <InputMask
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        mask="(999) 999-9999"
                                        value={formData.phoneNumber}
                                        onChange={onInputChange}
                                    />
                                </div>
                                {/* <div className="field col-12 md:col-6 flex flex-column gap-2">
                                        <label htmlFor="phoneNumberType">Phone Number Type</label>
                                        <Dropdown
                                            id="phoneNumberType"
                                            name="phoneNumberType"
                                            options={phoneNumberTypes}
                                            value={formData.phoneNumberType}
                                            onChange={onInputChange}
                                            placeholder="Select a type"
                                        />
                                    </div> */}
                                {/* <div className="field col-12 flex flex-column gap-2">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea id="description" name="description" rows={6} value={formData.description} onChange={onInputChange} />
                                    </div> */}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default NewClientForm