import React from 'react';
import banner from "../../../Assets/Investor/Investors_Header.png";
import "./InvestorBanner.scss";

const InvestorBanner = () => {
    return (
        <div>
            <div className="investor-section-banner section-top-space">
                <div className="heading">
                    <h2>Empower Future Join Startup Transformation.</h2>
                </div>
                <div className="banner-image-investor">
                    <img src={banner} alt="Banner Image" />
                </div>
            </div>
        </div>
    )
}

export default InvestorBanner