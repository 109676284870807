import React from 'react';
import mentorImage from "../../../Assets/Investor/Mentor_high_school.png";
import { Button } from 'primereact/button';
import "./MentorSection.scss";

const MentorSection = () => {
    return (
        <div>
            <div className="investor-section-banner mentor-section">
                <div className="heading ">
                    <h2>Mentor High School Entrepreneurs</h2>
                    <p>S2L Investors mentor aspiring high school entrepreneurs,
                        sharing knowledge to nurture the next generation of innovators and impact their futures.</p>
                    <div>
                        <Button className="button-style-primary-square"><a href="newclientform">Become a Mentor</a></Button>
                    </div>
                </div>
                <div className="mentor-image-investors" >
                    <img src={mentorImage} alt="Invest_and_mentor Image" />
                </div>
            </div>
        </div>
    )
}

export default MentorSection;