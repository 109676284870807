import { Button } from 'primereact/button';
import React from 'react';
import bannerImg from "../../../../Assets/Academy/BannerImage.png";
import "./AcademyBanner.scss";

const AcademyBanner = () => {
    return (
        <>
            <div className='s2l-banner-section mx-2'>
                <div className='banner-content'>
                    <h2>Welcome <span className="startup-text">Enthusiasts</span><br /></h2>
                    <p>A Unique Entrepreneurship Program for Middle and High School Students</p>
                    <p>S2L Academy transforms middle and high schoolers' ideas into startups through an inspiring, educational, 
                        hands-on entrepreneurship program. Unlock potential and launch your venture.</p>
                    <Button className='purple-bg'><a href='/8-weekcourse'>Register</a></Button>
                </div>


                <div className='banner-image'>
                    <img src={bannerImg} alt='People working around a table' />
                </div>
            </div>

        </>
    )
}

export default AcademyBanner