import React from 'react';
import './JoinAcademy.scss';
import { Button } from 'primereact/button';
import jobBanner from "../../../../Assets/Recruitment/are-you-seeking-talent-or-a-job-banner-background.png"
import CalendlyComponent from '../../../../Shared-Components//Calendly/Calendly';

const JoinAcademy = () => {

  const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '#F9AB60',
    textColor: '#220D50'
  }

  return (
    <>
      <div className='join-academy-section'>
        <div className='m-5 p-5'>
          <div className=' main-section'>
            <div className='join-heading'>Why Join Startup Scholars Program?</div>
            {/* <div className='join-subhead'> Why S2L Academy is the Right Choice for Aspiring Young Entrepreneurs</div> */}
          </div>

          <div className='join-points-section p-5 pt-6'>
            <div className='join'>
              <div className='join-details'>
                <div className='flex gap-3'>
                  <div><Button className='join-button'>1</Button></div>
                  <div>
                    <div className='join-headings'>Hands-on Learning</div>
                    <div className='join-para'>Experience the thrill of turning an idea into a prototype and potentially launching your own business.</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='join'>
              <div className='join-details'>
                <div className='flex gap-3'>
                  <div><Button className='join-button'>2</Button></div>
                  <div>
                    <div className='join-headings'>Expert Guidance</div>
                    <div className='join-para'>Learn from seasoned entrepreneurs and business experts through personalized coaching and mentorship.</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='join'>
              <div className='join-details'>
                <div className='flex gap-3'>
                  <div><Button className='join-button'>3</Button></div>
                  <div>
                    <div className='join-headings'>Real-World Skills</div>
                    <div className='join-para'>Acquire practical skills in market research, product development, and digital marketing, along with essential soft skills like leadership and time management.</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='join'>
              <div className='join-details'>
                <div className='flex gap-3'>
                  <div><Button className='join-button'>4</Button></div>
                  <div>
                    <div className='join-headings'>Community and Support</div>
                    <div className='join-para'>Join a vibrant community of like-minded peers and gain lifelong connections and support from the entrepreneurial ecosystem.</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='join'>
              <div className='join-details'>
                <div className='flex gap-3'>
                  <div><Button className='join-button'>5</Button></div>
                  <div>
                    <div className='join-headings'>Equity in Your Business</div>
                    <div className='join-para'>Through the program, each group can retain a 90% equity stake in the business you launch.</div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>


      <div className='join-next-section'>
        <div className="m-5 job-Banner flex justify-content-around align-items-center">
          <div className="job-content flex justify-content-around align-items-center col-12 p-8">
            <div className='col-10'>
              <div className='join-next-head pb-2'>Join the Next Cohort</div>
              <div className='join-next-subhead pb-2'>Ready to Turn Your Dream into a Bussines?</div>
              <div className='join-next-para py-2'>Invitation to apply for the upcoming cohort, detailing the application process, key dates, and a link to the application form. Encourage prospective students to take the first step towards becoming successful young entrepreneurs with S2L Academy.</div>
            </div>
            <div className='col-2'>
              <CalendlyComponent
                buttonLabel="Learn More"
                pageSettings={pageSettings}
                url="https://calendly.com/contactus-s2l/s2l-academy" />
            </div>
          </div>
          <img src={jobBanner} alt="" />
        </div>
      </div>
    </>

  );
};

export default JoinAcademy;
