import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import roundOneImage from "../../../Assets/Recruitment/Recruitment-Consultation.png";
import roundTwoImage from "../../../Assets/Recruitment/Sourcing-the-best-talent.png";
import roundThreeImage from "../../../Assets/Recruitment/Customized-interview-processes.png";
import roundFourImage from "../../../Assets/Recruitment/Secure-top-talen-extend-offers.png";
import roundFiveImage from "../../../Assets/Recruitment/Onboarding-support.png";
import '../Recruitment.scss';

const OurApproach = () => {
    const reviews = [
        {
            no: "1",
            name: 'Recruitment Consultation',
            image: roundOneImage,
            content: "It's not just about finding talent; we take the time to understand your startup's spirit for accelerated growth.",
        },
        {
            no: "2",
            name: 'Sourcing the Best Talent',
            image: roundTwoImage,
            content: "We don't settle for mediocrity. Our strategic approach involves targeted searches to identify top talent.",
        },
        {
            no: "3",
            name: 'Customized Interview Processes',
            image: roundThreeImage,
            content: "We ensure that candidates not only meet technical requirements but also meet cultural fitment through Analytical & Behavioral Assessment.",
        },
        {
            no: "4",
            name: 'Secure Top Talent - Extend Offers',
            image: roundFourImage,
            content: "Found your perfect match? Let’s craft and present offers that align with your startup's ethos.",
        },
        {
            no: "5",
            name: 'Onboarding Support',
            image: roundFiveImage,
            content: "Beyond recruitment, we offer seamless onboarding support for integrating new talent into your startup's culture.",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        // speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    return (
        <div className='recruitment-section'>
            <div className="section-heading m-4 pt-3 text-center mt-0">
                <h1>Our Approach</h1>
            </div>

            <div className='approach'>
                <div className="review-sec">
                    <Slider {...settings}>
                        {reviews.map((d) => (
                            <div className="gap-6">
                                <div className='grid align-items-center justify-content-around p-2 approach-section'>
                                    <div className='a'>
                                        <img src={d.image} alt="" width={""} />
                                    </div>
                                    {/* <div> */}
                                    <div className='grid align-items-center justify-content-between p-2 gap-3 b'>
                                        <span>{d.no}</span>
                                        <div>
                                            <h2>{d.name}</h2>
                                            <p className="approach-content" style={{ color: "" }}>{d.content}</p>
                                            <hr style={{ height: "15px", background: "rgba(141, 175, 97, 0.62)", border: "none" }} />
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

            </div>
        </div>

    )
}

export default OurApproach