import React from 'react';
import './HomeBanner.scss';
import { Button } from 'primereact/button';
import Bannerimage from '../../../Assets/Home Page/Three-people-standing-header-startup2launch.png';
import clock from "../../../Assets/Home Page/clock-three-solid.svg";
import trophy from "../../../Assets/Home Page/trophy-solid 1 (1).svg";
import dollar from "../../../Assets/Home Page/circle-dollar-solid.svg";
import { useNavigate } from 'react-router-dom';

const HomeBanner = () => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/newclientform');
    }

    return (
        <>
            <div className='curved'>
                <div className="-mt-2">
                    <section className="layout">
                        <div className='banner-content'>
                            <section className='banner-section-content'>
                                <h1>Your Vision. Our Expertise. Unlimited Possibilities.</h1>
                            </section>

                            <div className='banner-button'>
                                <Button onClick={handleRedirect}>Start Your Journey Today</Button>
                            </div>
                        </div>

                        <div className='banner-images'>
                            <img src={Bannerimage} alt='Bannerimage' />
                        </div>
                    </section>

                    <section className="banner-entrepreneur-section-content">

                        <svg viewBox="0 0 1440 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1440 33.1998C479 -101.332 817 242.665 0 33.1998V506H1440V33.1998Z" fill="#EFE5D9" />
                            <path d="M0 35.389C510.5 -112.268 623 264.646 1440 35.389V506H0V35.389Z" fill="#352750" />
                        </svg>

                        <div className="benefits-section">
                            <h2 className='border-bottom-3'>Benefits to Entrepreneur</h2>
                            <p>Launch your business with us: Efficient, Easy, Smartly Priced</p>

                            <div className="banner-entrepreneur">
                                <div className="banner-entrepreneur-section">
                                    <div className="flex font-bold p-4 gap-4">
                                        <img src={clock} alt="" width={80} />
                                        <div>
                                            <div className="heading">
                                                Fast-Track Your Launch
                                            </div>
                                            <div className="content">
                                                From concept to market, optimizing your journey to success with efficient strategies.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex font-bold p-4 mx-4 gap-4">
                                        <img src={trophy} alt="" width={80} />

                                        <div>
                                            <div className="heading">
                                                Ease Your Journey
                                            </div>
                                            <div className="content">
                                                Transform your ideas with minimal effort; our experts handle the complexities.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex font-bold p-4 gap-4">
                                        <img src={dollar} alt="" width={80} />

                                        <div>
                                            <div className="heading">
                                                Economize Your Dream
                                            </div>
                                            <div className="content">
                                                Launch your business without breaking the bank. Top-tier services at a fraction.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </>
    )
}

export default HomeBanner;