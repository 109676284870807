import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Home/Header/Header';
import { Button } from 'primereact/button';
import { FaChevronLeft } from 'react-icons/fa';
import Footer from '../../Home/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { CONTENT_HEADERS, SENTIMENT_ANALYSIS, TRANSCRIPTION } from '../../../Utils/LandingService';
import SpeechChart from './SpeechChart';
import axios from 'axios';
const {
    TranscribeClient,
    StartTranscriptionJobCommand,
    GetTranscriptionJobCommand,
    ListTranscriptionJobsCommand
  } = require("@aws-sdk/client-transcribe");

const VideoAnalysis = () => {
    const [show, setShow] = useState(false);
    const videoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [audioEnabled, setAudioEnabled] = useState(true);

    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);

    const metersRef = useRef([]);
    const animationIdRef = useRef(null);

    const blobsRef = useRef([]);
    const audioBlobsRef = useRef([]);

    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [, setAudioRecorder] = useState(null);

    const [videoStream, setVideoStream] = useState();
    const [audioStream, setAudioStream] = useState();

    const [videoTrack, setVideoTrack] = useState(null);

    const [rekognition, setRekognition] = useState(null);
    const [isEmotionDataLoaded, setIsEmotionDataLoaded] = useState(false);
    const [emotionData, setEmotionData] = useState(null);

    const [speechData, setSpeechSentiment] = useState(null);
    const [speechTranscribe, setSpeechTranscribe] = useState(null);
    const [client, setTranscribe] = useState(null);

    const [isScanning, setIsScanning] = useState(false);

    const handleRedirect = () => {
        setShow(true);
    };


    useEffect(() => {
        const initAWS = async () => {
            const AWS = require('aws-sdk');
            AWS.config.region = 'us-east-1';
            AWS.config.credentials = new AWS.Credentials('AKIARU7F3QOFYVGWWR6A', '1F5uALj5BmnZQqlSKBsIbQrwI2kWas7WcfO0F8as');
            setRekognition(new AWS.Rekognition());
           const transcribeStreamingClient = new TranscribeClient({
            region: 'us-east-1',
            credentials: {
              accessKeyId: 'AKIARU7F3QOFYVGWWR6A',
              secretAccessKey: '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',
            },
          });
          setTranscribe(transcribeStreamingClient)
          };
      
          initAWS();
        const getUserMedia = async () => {
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                setStream(mediaStream);
                setVideoTrack(mediaStream)
            } catch (error) {
                console.error('Error accessing media devices.', error);
            }
        };

        getUserMedia();

        return () => {
            if (videoStream) {
                videoStream.getTracks().forEach(track => track.stop());
            }
            if (audioStream) {
                audioStream.getTracks().forEach(track => track.stop());
            }
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, [videoStream, audioStream,]);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }

        if (stream) {
            setupAudioMeter();
        }

        return () => {
            cancelAnimationFrame(animationIdRef.current);
        };
    }, [stream, show]);

    useEffect(() => {
        const handleStopRecording = async () => {

            const images = await extractFramesFromBlobs(blobsRef.current);
            await sendAudioData(audioBlobsRef.current);
            setIsScanning(false);

            if (rekognition) {
                const allEmotionData = [];
                for (const imageBlob of images) {
                    const imageArrayBuffer = await imageBlob.arrayBuffer();
                    const params = {
                        Image: {
                            Bytes: imageArrayBuffer,
                        },
                        Attributes: ['ALL'],
                    };

                    rekognition.detectFaces(params, (err, data) => {
                        if (err) {
                            console.error(err, err.stack);
                        } else if (data.FaceDetails.length > 0) {
                            const firstFace = data.FaceDetails[0].Emotions;
                            setEmotionData(firstFace);
                        }
                    });
                }
            }
        };

        if (mediaRecorder) {
            mediaRecorder.onstop = handleStopRecording;
        }
    }, [mediaRecorder, rekognition, emotionData]);


    const setupAudioMeter = () => {
        if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 256;
        }
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);
        drawMeter();
    };

    const drawMeter = () => {

        if (!analyserRef.current || metersRef.current.length === 0) return;
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            analyserRef.current.getByteFrequencyData(dataArray);

            for (let i = 0; i < metersRef.current.length; i++) {
                const average = dataArray[i * Math.floor(bufferLength / metersRef.current.length)];
                let color;
                if (average) {
                    color = '#220D50';
                } else {
                    color = '#cccccc';
                }
                if (metersRef.current[i]) {
                    metersRef.current[i].style.backgroundColor = color;
                }
            }

            animationIdRef.current = requestAnimationFrame(draw);
        };
        draw();
    };

    const toggleVideo = () => {
        if (stream) {
            stream.getVideoTracks().forEach(track => (track.enabled = !track.enabled));
            setVideoEnabled(!videoEnabled);
        }
    };

    const toggleAudio = () => {
        if (stream) {
            stream.getAudioTracks().forEach(track => (track.enabled = !track.enabled));
            setAudioEnabled(!audioEnabled);
        }
    }

    const extractFramesFromBlobs = async (blobArray) => {
        const frames = [];

        for (const blob of blobArray) {
            const videoUrl = URL.createObjectURL(blob);
            const video = document.createElement('video');
            video.src = videoUrl;

            await new Promise(resolve => {
                video.addEventListener('loadedmetadata', () => {
                    resolve();
                });
            });

            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const context = canvas.getContext('2d');

            for (let currentTime = 0; currentTime < video.duration; currentTime += 1) {
                video.currentTime = currentTime;
                await new Promise(resolve => {
                    video.addEventListener('seeked', async () => {
                        requestAnimationFrame(() => {
                            context.drawImage(video, 0, 0, canvas.width, canvas.height);
                            const imageDataUrl = canvas.toDataURL('image/jpeg');
                            const imageBlob = dataURLToBlob(imageDataUrl);
                            frames.push(imageBlob);
                            resolve();
                        });
                    });
                });
            }
        }
        return frames;
    }


    const dataURLToBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const byteString = atob(arr[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: mime });
    };


    const sendAudioData = async (audioChunks) => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const formData = new FormData();
        formData.append('audioFile', audioBlob, 'audio.webm');

        axios
            .post(TRANSCRIPTION, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                const data = response.data;
                setSpeechTranscribe(data.text);
                sentimentAnalysis(data.text)
            })
            .catch(error => {
                console.error(error);
            });
    }

    const sentimentAnalysis = async (sentiment) => {

        axios
            .post(SENTIMENT_ANALYSIS, sentiment, { headers: CONTENT_HEADERS })
            .then(response => {
                const data = response.data;
                setSpeechSentiment(data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const startRecording = async () => {
        blobsRef.current = [];
        audioBlobsRef.current = [];
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setVideoStream(stream);
            setAudioStream(stream);

            setIsScanning(true);

            const videoRecorder = new MediaRecorder(stream);
            setMediaRecorder(videoRecorder);

            const video = videoRef.current;
            if (!video) {
                throw new Error('Video element not found');
            }
            video.srcObject = stream;

            videoRecorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    blobsRef.current.push(e.data);
                }
            };

            videoRecorder.start();

            const audioTrack = stream.getAudioTracks()[0];
            const audioStream = new MediaStream([audioTrack]);

            const audioRecorder = new MediaRecorder(audioStream);
            setAudioRecorder(audioRecorder);

            audioRecorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    audioBlobsRef.current.push(e.data);
                }
            };
            audioRecorder.start();
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            startRecording();
        }
    }, [videoRef]);

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            setVideoStream(null);
            setAudioStream(null);
            setMediaRecorder(null);
        }
    };

    return (
        <div>
            <Header />
            <div className="aryanow-project-section-payment">
                <div className="demo-back-button">
                    <Button className='back-button'><a href="/ourWork"> <FaChevronLeft /> Back</a></Button>
                </div>

                <div className="payment-section">
                    <h2>Facial video and Image analysis</h2>
                </div>

                {show ? (
                    <div className="payment-section">
                        <div className="demo-instruction">
                            <div className='border-right col-6 video-preview'>
                                <h2>Make sure camera and microphone are turn in purple to assure they are on.</h2>

                                <div className='flex justify-content-center'>
                                    <div className='preview-video-screen'>

                                        <video id='local-video' className='local-video' ref={videoRef} autoPlay playsInline muted />

                                        <div className="controls">
                                            <div className='flex gap-4 p-1 w-full justify-content-around'>
                                                <div className='flex align-items-end justify-content-evenly gap-4'>
                                                    <FontAwesomeIcon icon={videoEnabled ? faVideo : faVideoSlash} />
                                                    <div className={`control-item toggle-button ${videoEnabled ? 'enabled' : 'disabled'}`} onClick={toggleVideo}></div>
                                                </div>

                                                <div className='flex align-items-end justify-content-evenly gap-4'>
                                                    <FontAwesomeIcon icon={audioEnabled ? faMicrophone : faMicrophoneSlash} />
                                                    <div className={`control-item toggle-button ${audioEnabled ? 'enabled' : 'disabled'}`} onClick={toggleAudio}></div>
                                                </div>

                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    {[...Array(10)].map((_, i) => (
                                                        <div
                                                            key={i}
                                                            ref={el => metersRef.current[i] = el}
                                                            className={`volume-bar ${i < 5 ? 'active' : ""}`}
                                                        ></div>
                                                    ))}
                                                </div>

                                                {/* <FontAwesomeIcon icon={faCog} className="settings-icon" /> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 demo-right-section authorized-screen'>
                                <h2>Instructions:</h2>
                                <ul>
                                    <ol>1. Click on Start scanning.</ol>
                                    <ol>2. When you finish click on stop the scanning and see the results.</ol>
                                </ul>
                                <h2>Video Scanning</h2>

                                <div className="align-two-btn">
                                    <div className="scaning-button">
                                        <Button
                                            className={`p-button ${isScanning ? 'disabled-button' : 'p-button'}`}
                                            onClick={startRecording}
                                        >
                                            Start scanning
                                        </Button>
                                    </div>
                                    <div className="scaning-button">
                                        <Button
                                            className={`p-button ${isScanning ? 'p-button' : 'disabled-button'}`}
                                            onClick={stopRecording}
                                        >
                                            Stop scanning
                                        </Button>
                                    </div>
                                </div>

                                {speechTranscribe && (
                                    <>
                                        <h2>Results:</h2>

                                        <SpeechChart speechData={emotionData} speechTranscribe={speechTranscribe} />
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="payment-section">
                            <p>
                                Startup2launch (S2L) offers cutting-edge image and facial video analysis tools for entrepreneurs,
                                ideal for analyzing emotions in video interviews within the medical or recruitment sectors.
                            </p>

                            <p>
                                These technologies provide deep insights into participant reactions, enhancing understanding
                                and decision-making in critical service and product development areas.
                            </p>
                        </div>

                        <div className="m-5">
                            <Button onClick={handleRedirect} className='p-button'> Try Now</Button>
                        </div>
                    </>
                )}
            </div>

            <Footer />

        </div>
    )
}

export default VideoAnalysis