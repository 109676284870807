import React from 'react';
import './AcademyProgram.scss';
import SmilingGirl from '../../../../Assets/AcademyProgram/Empowering_Young_Entrepreneurs_Woman_Image.png';
import BoywithLaptop from '../../../../Assets/AcademyProgram/Empowering_Young_Entrepreneurs_Man_Image.png';
import GirlwithLaptop from '../../../../Assets/AcademyProgram/Empowering_Young_Entrepreneurs_Young_Woman_Image.png';
import MobBoywithLaptop from '../../../../Assets/AcademyProgram/Empowering_Young_Entrepreneurs_Man_Image_open.png';
import MobSmilingGirl from '../../../../Assets/AcademyProgram/Empowering_Young_Entrepreneurs_Woman_Image_open.png';
import MobGirlwithLaptop from '../../../../Assets/AcademyProgram/Empowering_Young_Entrepreneurs_Young_Woman_Image_open.png';
const AcademyProgram = () => {
    return (
        <>
        <div className='academyprogram-section'>
            <div className='m-5'>
                <div className='academyprogram-heading'>About the Program</div>
                <p className='academyprogram-subhead'>Please click on below images to learn more</p>

                <div className='academy-images-section mt-8'>
                    <div className='academy'>
                        <div className='academy-details'>
                            <div className='heading'>Empowering Young Entrepreneurs</div>
                            <div className="hover-content">Learn about S2L Academy's mission and how we're dedicated to transforming the entrepreneurial dreams of high school students into reality.</div>
                            <div className='img-sec-1'>
                                <img src={SmilingGirl} alt='Smilinggirl' />
                            </div>
                        </div>
                    </div>

                    <div className='academy'>
                        <div className='academy-details'>
                            <div className='heading'>Curriculum Highlights</div>
                            <div className="hover-content">Each cohort will have 21 students, divided into groups of 3 students, and will cover foundational business skills and advanced topics like market research, prototyping, and business launching.</div>
                            <div className='img-sec-2'>
                                <img src={BoywithLaptop} alt='BoywithLaptop' />
                            </div>
                        </div>
                    </div>

                    <div className='academy'>
                        <div className='academy-details'>
                            <div className='heading'>Cohort Experience</div>
                            <div className="hover-content">Details on the cohort structure, including the collaborative environment, mentorship opportunities, and the selection process for projects to be launched.</div>
                            <div className='img-sec-3'>
                                <img src={GirlwithLaptop} alt='GirlwithLaptop' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div className='mobile-academyprogram-section'>
<div className='m-5'>
    <div className='academyprogram-heading'>About the Program</div>
    <p className='academyprogram-subhead'>Our Program at a Glance</p>

    <div className='academy-images-section'>
        <div className='academy'>
            <div className='academy-details'>
                <div className='heading'>Empowering Young Entrepreneurs</div>
                <div className="hover-content p-3"> Learn about S2L Academy's mission and how we're dedicated to transforming the entrepreneurial dreams of high school students into reality.</div>
                <div className='img-sec-1'>
                        <img src={MobSmilingGirl} alt='MobSmilinggirl' />
                </div>
            </div>
        </div>

        <div className='academy'>
            <div className='academy-details'>
                <div className='heading'>Curriculum Highlights</div>
                <div className="hover-content  p-3">Each cohort will have 21 students, divided into groups of 3 students, and will cover foundational business skills and advanced topics like market research, prototyping, and business launching.</div>
                <div className='img-sec-2'>
                    <img src={MobBoywithLaptop} alt='MobBoywithLaptop' />
                </div>
            </div>
        </div>

        <div className='academy'>
            <div className='academy-details'>
                <div className='heading'>Cohort Experience</div>
                <div className="hover-content  p-3">Details on the cohort structure, including the collaborative environment, mentorship opportunities, and the selection process for projects to be launched.</div>
                <div className='img-sec-3'>
                    <img src={MobGirlwithLaptop} alt='MobGirlwithLaptop' />
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</>
    );
};

export default AcademyProgram;
