import React from 'react';
import './Startup2launchValues.scss';

import missionImage from '../../../Assets/AboutUs/ourvalues/Mission.png'; // Replace with the path to your mission image
import visionImage from '../../../Assets/AboutUs/ourvalues/Vision.png'; // Replace with the path to your vision image

const Startup2launchValues = () => {
  return (
    <div id='MissionVision' className="mission-vision-container">
      <div className="mission-section">
        <div className='mission-content'>
          <img src={missionImage} alt="Mission" className="image-circle" />
          <div className="text-section">
            <h2>Mission</h2>
            <p>Building Tomorrow's Businesses – Guiding Entrepreneurs from Concept to Market Leadership</p>
          </div>
        </div>
      </div>
      <div className="vision-section">
        <div className='vision-content'>
          <img src={visionImage} alt="Vision" className="image-circle" />
          <div className="text-section">
            <h2>Vision</h2>
            <p>Startup2Launch aims to be the go-to framework for entrepreneurs, helping them start great businesses that change industries.</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Startup2launchValues;
