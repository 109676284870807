import React, { useEffect } from 'react';
import Header from '../Home/Header/Header';
import RecruitmentBanner from './RecruitmentBanner/RecruitmentBanner';
import OurApproach from './OurApproach/OurApproach';
import TalentHiringSection from './TalentHiringSection/TalentHiringSection';
import PricingSection from './PricingSection/PricingSection';
import Footer from '../Home/Footer/Footer-WhiteBackground';
import { ScrollTop } from 'primereact/scrolltop';

const Recruitment = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <ScrollTop />
            <Header />
            <RecruitmentBanner />
            <OurApproach />
            <TalentHiringSection />
            <PricingSection />
            <Footer />
        </>
    )
}

export default Recruitment