import React from 'react';
import './UpcomingEvents.scss'; // You can add your styles here
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import Header from '../../Home/Header/Header';
import Footer from '../../Home/Footer/Footer';

const UpcomingEvents = ({ UpcomingEvents }) => {

    const navigate = useNavigate();

    const handleViewDetails = (url) => {
        navigate(`/upcomingevents/${encodeURIComponent(url.replace(/\s+/g, '-').toLowerCase())}`);
    };

    return (
        <div>
            <Header />
            <div className="upcomingevents-container">
                <div className='ue-heading'>Upcoming Events</div>
                <div className="ue-list">
                    {UpcomingEvents.map((event, index) => (
                        <div
                            key={index}
                            className="ue-event-card"
                            style={{ backgroundImage: `url(${event.backgroundImage})` }}
                        >
                            <div className="event">
                                <div className="grid ue-top-details">
                                    <div className='ue-left-details col-3'>
                                        <div className='ue-closing'>
                                            <div className='ue-day'>{event.closingDate}</div>
                                            <div className='ue-month'>{event.closingMonth}</div>
                                        </div>
                                        <div className='closing-text'>Closing Date</div>
                                    </div>
                                    <div className='ue-center-details col-7'>
                                        <div className='ue-name'>{event.workshopName}</div>
                                        <div className='grid'>
                                            <div className='col-4'>
                                                <p><strong>Date:</strong></p>
                                                <p>{event.date}</p>
                                            </div>
                                            <div className='col-4'>
                                                <p><strong>Time:</strong></p>
                                                <p>{event.time}</p>
                                            </div>
                                            <div className='col-4'>
                                                <p><strong>Location:</strong></p>
                                                <p>{event.location}</p>
                                            </div>
                                        </div>
                                        <div className='grid'>
                                            <div className='col-4'>
                                                <p><strong>{event.companyName}</strong></p>
                                            </div>
                                            <div className='col-4'>
                                                {event.guest.map((guest, index) => (
                                                    <div key={index} className='guest'>

                                                        <p><strong>{guest.name}</strong></p>

                                                    </div>
                                                ))}
                                            </div>
                                            <div className='col-4'>
                                                {event.guest.map((guest, index) => (
                                                    <div key={index} className='guest'>

                                                        <p><strong>{guest.designation}</strong></p>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ue-price-details col-2'>
                                        <div className='ue-price'>${event.price}</div>
                                    </div>
                                </div>
                                <div className='ue-bottom-details'>
                                    <div className='grid'>
                                        <div className='ue-desc col-9'>{event.description}</div>
                                        <div className='col-3 ue-link-button'>
                                            <Button
                                                label="Register Here"
                                                className="p-button-info event-link-button"
                                                onClick={() => handleViewDetails(event.name)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default UpcomingEvents;
