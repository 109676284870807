import React from 'react';
import "./JoinS2L.scss";
import backgroundImg from "../../../Assets/Investor/Join_our_S2L_community.png";
import { Button } from 'primereact/button';

const JoinS2L = () => {
    return (
        <div className="joins2l-container" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div className="joins2l-content">
                <div className="text-section">
                    <h2> Join our Startup2Launch community:</h2>
                    <p>Access high-potential startups and mentorship roles, and
                        connect with a network of forward-thinking investors.</p>
                </div>

                <div className="action-section">
                    <Button className="button-style-white"><a href="/newclientform">Become an Investor</a></Button>
                </div>
            </div>
        </div>
    )
}

export default JoinS2L