import React, { useRef, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import '../Faq/Faq.scss';
import { HiMiniQuestionMarkCircle } from "react-icons/hi2";
import { FaClipboardQuestion, FaUser } from "react-icons/fa6";
import { MdOutlineRocketLaunch } from "react-icons/md";
import Header from '../Home/Header/Header';
import Footer from '../Home/Footer/Footer';

const FAQ = () => {
    const [activeIndexSearch, setactiveIndexSearch] = useState(null);
    const [activeIndexGeneral, setactiveIndexGeneral] = useState(null);
    const [activeIndexPlan, setActiveIndexPlan] = useState(null);
    const [activeIndexPost, setActiveIndexPost] = useState(null);
    const [activeIndexServices, setActiveIndexServices] = useState(null);
    const [activeIndexUiUxServices, setActiveIndexUiUxServices] = useState(null);
    const [activeIndexMarkServices, setActiveIndexMarkServices] = useState(null);
    const [activeIndexTechServices, setActiveIndexTechServices] = useState(null);
    const [activeIndexFinancialServices, setActiveIndexFinancialServices] = useState(null);
    const [activeIndexLegalServices, setActiveIndexLegalServices] = useState(null);
    const [activeIndexPostServices, setActiveIndexPostServices] = useState(null);

    const [searchInput, setSearchInput] = useState('');
    const [filteredQuestions, setFilteredQuestions] = useState([]);


    const generalRef = useRef(null);
    const planspecificRef = useRef(null);
    const postlaunchRef = useRef(null);
    const servicesRef = useRef(null);

    const [generalQuestions] = useState([
        {
            label: 'How do I know if my business idea is viable?',
            content: 'To determine the viability of your business idea, conduct a comprehensive market analysis that includes identifying your target audience, understanding market needs, analyzing competitors, and evaluating market trends. Our Ideation Session and Product Market Fit Analysis can provide valuable insights into the potential success of your idea.',
            highlightedContent: ''
        },
        {
            label: 'What are the first steps to launching my startup?',
            content: 'The first steps include defining your business idea, conducting market research, creating a business plan, securing funding, and developing your product or service. Our Starter Plan is designed to help new entrepreneurs navigate these initial phases with expert guidance.',
            highlightedContent: ''
        },
        {
            label: 'How can I protect my intellectual property?',
            content: 'Protecting your intellectual property (IP) early on is crucial. Consider filing for patents, trademarks, or copyrights as applicable. Our Legal Services include Intellectual Property Protection guidance to safeguard your innovations.',
            highlightedContent: ''
        },
        {
            label: ' What financial planning steps should I take for my startup?',
            content: 'Start with creating a detailed budget, forecasting your financial needs, and planning for funding. Our Financial Planning Services offer assistance in budgeting, forecasting, investment strategy development, and securing capital to ensure financial stability.',
            highlightedContent: ''
        },
    ]);

    const [planSpecificQuestions] = useState([
        {
            label: 'What does the Starter Plan include, and who is it for?',
            content: "The Starter Plan is ideal for new entrepreneurs. It includes an Ideation Session, Product Market Fit Analysis, Preliminary Business Plan, Portal Design, and Pitch Deck. This plan is perfect for those at the conceptual stage who must validate their idea and plan the first steps.",
            highlightedContent: ''
        },
        {
            label: 'What additional benefits does the Standard Launch Plan offer?',
            content: 'The Standard Launch Plan is designed for entrepreneurs ready to launch. It includes Idea Validation Study, Business Modelling, Branding and Identity, Product Development, and Marketing and Launch strategies. This plan suits those who have validated their idea and want to develop and market their product.',
            highlightedContent: ''
        },
        {
            label: ' How does the Premium Launch Plan support post-launch growth?',
            content: "The Premium Launch Plan offers comprehensive full-scale launch and growth support, including Enhanced Product Development, Multi-Channel Marketing, Legal and Compliance Advisory, Sales Optimization, CRM, and Post-Launch Support. This plan is best for entrepreneurs seeking extensive support for scaling their businesses.",
            highlightedContent: ''
        },
    ]);


    const [postLaunchQuestions] = useState([
        {
            label: 'How can I expand my market reach after launching?',
            content: 'Market expansion strategies may include diversifying your product line, exploring new markets, enhancing marketing efforts, and leveraging customer feedback for product improvements. Our Post Launch Services can guide you through these strategies to grow your business.',
            highlightedContent: ''
        },
        {
            label: 'What should I do if I receive negative customer feedback?',
            content: 'Treat negative feedback as an opportunity to improve. Analyze the feedback, identify areas for improvement, and make necessary adjustments to your product or service. Our Customer Feedback Analysis service can help you turn feedback into actionable insights.',
            highlightedContent: ''
        },
        {
            label: 'How often should I update my business plan?',
            content: 'Your business plan should be a living document, updated regularly to reflect market changes, business goals, and financial projections. We recommend reviewing and updating your plan at least annually or as major changes occur in your business or industry.',
            highlightedContent: ''
        },
    ]);

    const [servicesQuestions] = useState([
        {
            label: 'How can S2L help with my business strategy and planning?',
            content: "S2L offers comprehensive services, including detailed market analysis, scalable business modeling, complete financial planning, cohesive branding strategy, and successful launch planning. We help you create a robust foundation for your business, ensuring it's geared for success from the start.",
            highlightedContent: ''
        },
        {
            label: 'What is included in the market analysis service?',
            content: 'Our market analysis service provides in-depth research into your target market, competition, trends, and customer needs. This service is designed to identify business opportunities and inform strategic decisions.',
            highlightedContent: ''
        },
        {
            label: 'How does S2L approach business modeling?',
            content: 'S2L creates scalable business models tailored to your specific industry and growth goals. Our approach includes evaluating revenue streams, cost structures, customer segments, and value propositions to build a model that supports sustainable growth.',
            highlightedContent: ''
        },
    ]);

    const [uiuxServicesQuestions] = useState([
        {
            label: 'Why are UX and UI important for my startup?',
            content: "User Experience (UX) and User Interface (UI) are crucial for ensuring your product or service meets the needs and expectations of your users. Good UX/UI design enhances user satisfaction, improves usability, and increases user engagement, directly impacting your business's success.",
            highlightedContent: ''
        },
        {
            label: 'What does the UX research process involve?',
            content: 'Our UX research involves studying your target users’ behaviors, needs, and challenges. This includes methods like user interviews, surveys, usability testing, and persona development to inform the design and development of your product.',
            highlightedContent: ''
        },
    ]);

    const [marketingServicesQuestions] = useState([
        {
            label: "How can S2L help with my startup's digital marketing strategy?",
            content: "S2L provides comprehensive digital marketing strategies tailored to your startup's goals. This includes content creation, SEO and SEM, social media management, and analytics and reporting to increase your online presence, engage with your target audience, and drive growth.",
            highlightedContent: ''
        },
        {
            label: 'What is SEO, and why is it important?',
            content: "SEO (Search Engine Optimization) is optimizing your website and content to rank higher in search engine results pages (SERPs). It's crucial for increasing visibility, attracting organic traffic, and enhancing credibility in your industry.",
            highlightedContent: ''
        },
    ]);

    const [technologyServicesQuestions] = useState([
        {
            label: "Can S2L assist with mobile app development?",
            content: "Yes, S2L offers mobile app development services tailored to your specific needs. Whether you're looking to create a new app or improve an existing one, our team of experts will ensure it's user-friendly, functional, and aligned with your business goals.",
            highlightedContent: ''
        },
        {
            label: 'What cybersecurity measures does S2L recommend?',
            content: "S2L recommends a comprehensive cybersecurity strategy, including regular security audits, data encryption, secure access controls, and employee training on best practices. Our cybersecurity services are designed to protect your business from cyber threats and data breaches.",
            highlightedContent: ''
        },
    ]);

    const [financialServicesQuestions] = useState([
        {
            label: "How does S2L assist with financial planning and analysis?",
            content: "Our financial planning and analysis services include creating detailed budgets, forecasting financial needs, developing investment strategies, and assisting with funding and capital raising. We help ensure your financial stability and growth potential.",
            highlightedContent: ''
        },
    ]);

    const [legalServicesQuestions] = useState([
        {
            label: "What legal services does S2L provide for startups?",
            content: "S2L offers various legal services, including startup incorporation and structure, intellectual property protection, contract review and negotiation, compliance and regulatory advice, dispute resolution, and litigation support to safeguard your business and its interests.",
            highlightedContent: ''
        },
    ]);

    const [postLaunchServicesQuestions] = useState([
        {
            label: "How does S2L support startups after launch?",
            content: "Post-launch, S2L provides market expansion strategies, customer feedback analysis, ongoing marketing support, product iteration and enhancement, and continued technical support. Our goal is to ensure your startup's continued growth and success.",
            highlightedContent: ''
        },
    ]);

    const toggleAccordion = (section, index) => {
        switch (section) {
            case 'search':
                setactiveIndexSearch(activeIndexSearch === index ? null : index);
                break;
            case 'general':
                setactiveIndexGeneral(activeIndexGeneral === index ? null : index);
                break;
            case 'plan':
                setActiveIndexPlan(activeIndexPlan === index ? null : index);
                break;
            case 'post':
                setActiveIndexPost(activeIndexPost === index ? null : index);
                break;
            case 'services':
                setActiveIndexServices(activeIndexServices === index ? null : index);
                break;
            case 'uiuxservices':
                setActiveIndexUiUxServices(activeIndexUiUxServices === index ? null : index);
                break;
            case 'marketingservices':
                setActiveIndexMarkServices(activeIndexMarkServices === index ? null : index);
                break;
            case 'technologyservices':
                setActiveIndexTechServices(activeIndexTechServices === index ? null : index);
                break;
            case 'financialservices':
                setActiveIndexFinancialServices(activeIndexFinancialServices === index ? null : index);
                break;
            case 'legalservices':
                setActiveIndexLegalServices(activeIndexLegalServices === index ? null : index);
                break;
            case 'postservices':
                setActiveIndexPostServices(activeIndexPostServices === index ? null : index);
                break;
            default:
                break;
        }
    };

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const handleSearchInputChange = (event) => {
        const userInput = event.target.value;
        setSearchInput(userInput);

        if (userInput.trim() === '') {
            setFilteredQuestions([]);
        } else {
            const allQuestions = [...generalQuestions, ...planSpecificQuestions, ...postLaunchQuestions, ...servicesQuestions, ...uiuxServicesQuestions, ...marketingServicesQuestions, ...technologyServicesQuestions, ...financialServicesQuestions, ...legalServicesQuestions, ...postLaunchServicesQuestions];

            const updatedQuestions = allQuestions.filter((question) => {
                return question.label.toLowerCase().includes(userInput.toLowerCase());
            });

            setFilteredQuestions(updatedQuestions);
        }
    };

    return (
        <>
            <Header />
            <div className="search-section faq-page">
                <h2>Frequently Asked Questions </h2>
                <div className='faq-search-section '>
                    <div className="search-box">
                        <input
                            className='search-input'
                            type="text"
                            placeholder="Search..."
                            value={searchInput}
                            onChange={handleSearchInputChange}
                        />
                        <i className="pi pi-search search-icon" />
                    </div>
                </div>
            </div>

            {filteredQuestions.length > 0 && (
                <div className="searchrelated-section m-5">
                    <h2>Related search:</h2>
                    <Accordion>
                        {filteredQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-faq-header ${activeIndexSearch === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('search', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexSearch === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexSearch === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>
            )}

            <div className="Faq-section">
                <h2 className='header-section flex align-items-center justify-content-center'>Frequently Asked Questions</h2>
                <>
                    <div className="flex gap-4 flex-wrap  faq-heading-section">
                        <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(generalRef)}><div className='faq-icon'><HiMiniQuestionMarkCircle /></div>General Questions</div>
                        <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(planspecificRef)}><div className='faq-icon'><FaUser /></div>Plan-Specific Questions</div>
                        <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(postlaunchRef)}><div className='faq-icon'><MdOutlineRocketLaunch /></div>Post-Launch Questions</div>
                        <div className="flex-1 text-boxes text-center p-4 border-round faq-heading" onClick={() => scrollToSection(servicesRef)}><div className='faq-icon'><FaClipboardQuestion /></div>Services Questions</div>
                    </div>
                </>

                <div ref={generalRef} className='mt-6 faq-sub-headings'>
                    <h2>General Questions </h2>
                    <Accordion>
                        {generalQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-faq-header ${activeIndexGeneral === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('general', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexGeneral === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexGeneral === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>

                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>

                <div ref={planspecificRef} className='mt-6 faq-sub-headings'>
                    <h2>Plan-Specific Questions</h2>
                    <Accordion>
                        {planSpecificQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-faq-header ${activeIndexPlan === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('plan', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexPlan === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexPlan === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>

                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>

                <div ref={postlaunchRef} className='mt-6 faq-sub-headings'>
                    <h2>Post-Launch Questions</h2>
                    <Accordion>
                        {postLaunchQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-faq-header ${activeIndexPost === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('post', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexPost === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexPost === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>

                <div ref={servicesRef} className='mt-6 faq-sub-headings'>
                    <h2>Strategy and Planning Services FAQs </h2>
                    <Accordion>
                        {servicesQuestions.map((faq, index) => (
                            <AccordionTab
                                key={index}
                                header={
                                    <div
                                        className={`accordion-faq-header ${activeIndexServices === index ? 'active-tab' : ''}`}
                                        onClick={() => toggleAccordion('services', index)}
                                    >
                                        {faq.label}
                                        <FontAwesomeIcon
                                            icon={activeIndexServices === index ? faMinus : faPlus}
                                            className="accordion-icon"
                                        />
                                    </div>
                                }
                                contentClassName={activeIndexServices === index ? 'active-content' : ''}
                            >
                                <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                <p>{faq.content}</p>
                            </AccordionTab>
                        ))}
                    </Accordion>

                    <div className='mt-5'>
                        <h2>UX & UI Services FAQs </h2>
                        <Accordion>
                            {uiuxServicesQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexUiUxServices === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion('uiuxservices', index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexUiUxServices === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexUiUxServices === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>

                    <div className='mt-5'>
                        <h2>Marketing Services FAQs</h2>
                        <Accordion>
                            {marketingServicesQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexMarkServices === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion('marketingservices', index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexMarkServices === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexMarkServices === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>

                    <div className='mt-5'>
                        <h2>Technology Services FAQs </h2>
                        <Accordion>
                            {technologyServicesQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexTechServices === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion('technologyservices', index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexTechServices === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexTechServices === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>

                    <div className='mt-5'>
                        <h2>Financial Planning Services FAQs</h2>
                        <Accordion>
                            {financialServicesQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexFinancialServices === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion('financialservices', index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexFinancialServices === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexFinancialServices === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>

                    <div className='mt-5'>
                        <h2>Legal Services FAQs</h2>
                        <Accordion>
                            {legalServicesQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexLegalServices === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion('legalservices', index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexLegalServices === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexLegalServices === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>

                    <div className='mt-5'>
                        <h2>Post Launch Services FAQs</h2>
                        <Accordion>
                            {postLaunchServicesQuestions.map((faq, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div
                                            className={`accordion-faq-header ${activeIndexPostServices === index ? 'active-tab' : ''}`}
                                            onClick={() => toggleAccordion('postservices', index)}
                                        >
                                            {faq.label}
                                            <FontAwesomeIcon
                                                icon={activeIndexPostServices === index ? faMinus : faPlus}
                                                className="accordion-icon"
                                            />
                                        </div>
                                    }
                                    contentClassName={activeIndexPostServices === index ? 'active-content' : ''}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: faq.highlightedContent }}></p>
                                    <p>{faq.content}</p>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default FAQ;