import React, { useState } from 'react'
import './Services.scss';
import "primeicons/primeicons.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import servicesbanner from '../../Assets/Services/Our-services-banner.png';
import servicesmobilebanner from '../../Assets/Services/Banner_Mobile.png';
import Strategy from '../../Assets/Services/Strategy-Planning.jpg';
import UXUI from '../../Assets/Services/UX-UI-Services.jpg';
import Marketing from '../../Assets/Services/Marketing Services.jpg';
import Technology from '../../Assets/Services/Tech-Services.jpg';
import Financial from '../../Assets/Services/Financial-Planning.jpg';
import Legal from '../../Assets/Services/Legal-services.jpg';
import Post from '../../Assets/Services/Post-launch-services.jpg';
import Header from '../Home/Header/Header';
import Footer from '../Home/Footer/Footer';
import { ScrollTop } from 'primereact/scrolltop';
import { FaCheck } from "react-icons/fa";

const Services = () => {

    const services = [
        {
            "title": "Strategy and Planning Services",
            "subtitle": "Learn how to ideate, plan and Launch preparedness",
            "image": Strategy,
            "features": [
                "Detailed Market Analysis",
                "Scalable Business Modelling",
                "Complete Financial Planning",
                "Cohesive Branding Strategy",
                "Succesful Lannch Planning",
            ],
        },
        {
            "title": "UX/UI Services",
            "subtitle": "Design, Wireframes, Design System",
            "image": UXUI,
            "features": [
                "User Research",
                "Interface Design",
                "User Experience Strategy",
                "Prototyping and Testing",
                "Usability and Accesibility",
            ],
        },
        {
            "title": "Marketing Services",
            "subtitle": "Strategy, Research Social Media presence, Blogs, YouTube videos",
            "image": Marketing,
            "features": [
                "Digital Marketing Strategy",
                "Content Creation",
                "SEO and SEM",
                "Social Media Managment",
                "Analytics and Reporting",
            ],
        },
        {
            "title": "Technology Services",
            "subtitle": "Architecture, Tech Development, Security and Governance, Audit",
            "image": Technology,
            "features": [
                "Software Development",
                "Mobile App Development",
                "Cloud Computing Services",
                "Cybersecurity Measures",
                "Tech Support and Maintenance",
            ],
        },
        {
            "title": "Financial Planning",
            "subtitle": "Funding strategy, Funding Stages, Equity Planning, Business planning",
            "image": Financial,
            "features": [
                "Financial Planning and Analysis",
                "Budgeting and Forecasting",
                "Investment Strategy Development",
                "Funding and Capital Raising",
                "Compliance and Risk Management",
            ],
        },
        {
            "title": "Legal Services",
            "subtitle": "Legal structuring, HIPPA, FERPA",
            "image": Legal,
            "features": [
                "Startup Incorporation and Structure",
                "Intellectual Property Protection",
                "Contract Review and Negotiation",
                "Compliance and Regulatory Advice",
                "Dispute Resolution and Litigation Support",
            ],
        },
        {
            "title": "Post Launch Services",
            "subtitle": "Ensure the product runs smoothly post-launch",
            "image": Post,
            "features": [
                "Market Expansion Strategies",
                "Customer Feedback Analysis",
                "Ongoing Marketing Support",
                "Product Iteration and Enhancement",
                "Continued Technical Support",
            ],
        }
        // Add more services as needed
    ]

    const isTabOpen = (index) => activeIndex === index;
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <>
            <ScrollTop />
            <Header></Header>
            <div className='services'>
                <div className='services-desktop-view'>
                    <div className='services-header'>
                        <img src={servicesbanner} style={{ width: '100%' }} />
                        <div className="s-header">
                            <div className="s-header-text">Our Services</div>
                            <div className="s-header-subtext">Through our comprehensive range of services including UX, user research, design, technology, and ecosystem-building, we offer tailored benefits that cater to your specific needs.</div>
                        </div>
                    </div>
                    <div className='services-section'>
                        {services.map((service, index) => (
                            <div className='each-grid grid' key={service.id} style={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }}>
                                <div className='services-img col-12 sm:col-12 md:col-12 lg:col-6'>
                                    <img src={service.image} style={{ width: '100%' }} />
                                </div>
                                <div className='col-12 sm:col-12 md:col-12 lg:col-6'>
                                    <div className='services-content'>
                                        <div className='service-title'>{service.title}</div>
                                        <div className='service-subtitle'>{service.subtitle}</div>
                                        {service.features.map(feature => (
                                            <ul className='service-list'>
                                                <li className="service-item">
                                                    <div className='flex gap-2'>
                                                        <div className='check-icon'><FaCheck style={{ color: '#8DAF61' }} /> </div>
                                                        <div>{feature}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='services-mobile-view'>
                    <div className='services-header'>
                        <img src={servicesmobilebanner} style={{ width: '100%' }} />
                        <div className="s-header">
                            <div className="s-header-text">Our Services</div>
                            <div className="s-header-subtext">Through our comprehensive range of services including UX, user research, design, technology, and ecosystem-building, we offer tailored benefits that cater to your specific needs.</div>
                        </div>
                    </div>
                    <div className='services-section'>
                        <Accordion
                            activeIndex={activeIndex}
                            onTabChange={(e) => toggleAccordion(e.index)}
                        >
                            {services.map((service, index) => (
                                <AccordionTab
                                    key={index}
                                    header={
                                        <div className="accordion-header">
                                            <div className="header-text">{service.title}</div>
                                            <div className="header-icon">
                                                <i
                                                    className={`pi ${isTabOpen(index) ? "pi-minus" : "pi-plus"
                                                        }`}
                                                ></i>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div>
                                        <img src={service.image} style={{ width: '100%' }} />
                                        <div className='service-subtitle'>{service.subtitle}</div>
                                        {service.features.map(feature => (
                                            <ul className='service-list'>
                                                <li className="service-item">
                                                    <div className='flex gap-2'>
                                                        <div className='check-icon'><FaCheck style={{ color: '#8DAF61' }} /> </div>
                                                        <div>{feature}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        ))}
                                    </div>

                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div>
                </div>

            </div>
            <Footer></Footer>
        </>
    )
}

export default Services