import React, { useEffect, useRef, useState } from 'react';
import Header from '../../Home/Header/Header';
import Footer from '../../Home/Footer/Footer';
import { Button } from 'primereact/button';
import { FaChevronLeft } from 'react-icons/fa';
import './OurDemoProject.scss';
import ChatOption from '../../../Shared-Components/Chat-Option/ChatOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faVideo, faCog, faExternalLink, faVideoSlash, faMicrophoneSlash, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { ProgressSpinner } from 'primereact/progressspinner';

const CommunicationModule = () => {
    const [showChat, setShowChat] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [screenSize, setScreenSize] = useState(false);
    const videoRef = useRef(null);
    const smallVideoRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [audioEnabled, setAudioEnabled] = useState(true);

    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);

    const metersRef = useRef([]);
    const animationIdRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [endingCall, setEndingCall] = useState(false);

    const handleChatRedirect = () => {
        setShowChat(true);
    };

    const handleScreenSize = () => {
        setScreenSize(true);
    };

    const handleVideoRedirect = () => {
        setShowVideo(true);
    };

    useEffect(() => {
        const getUserMedia = async () => {
            setLoading(true);
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                setStream(mediaStream);
                setLoading(false);
            } catch (error) {
                console.error('Error accessing media devices.', error);
                setLoading(false);
            }
        };

        getUserMedia();

        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);


    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
        if (smallVideoRef.current && stream) {
            smallVideoRef.current.srcObject = stream;
        }

        if (stream) {
            setupAudioMeter();
        }

        return () => {
            cancelAnimationFrame(animationIdRef.current);
        };
    }, [stream, screenSize, showVideo]);

    const setupAudioMeter = () => {
        if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 256;
        }
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);
        drawMeter();
    };

    const drawMeter = () => {

        if (!analyserRef.current || metersRef.current.length === 0) return;
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            analyserRef.current.getByteFrequencyData(dataArray);

            for (let i = 0; i < metersRef.current.length; i++) {
                const average = dataArray[i * Math.floor(bufferLength / metersRef.current.length)];
                let color;
                if (average) {
                    color = '#220D50';
                } else {
                    color = '#cccccc';
                }
                if (metersRef.current[i]) {
                    metersRef.current[i].style.backgroundColor = color;
                }
            }

            animationIdRef.current = requestAnimationFrame(draw);
        };
        draw();
    };

    const toggleVideo = () => {
        if (stream) {
            stream.getVideoTracks().forEach(track => (track.enabled = !track.enabled));
            setVideoEnabled(!videoEnabled);
        }
    };

    const toggleAudio = () => {
        if (stream) {
            stream.getAudioTracks().forEach(track => (track.enabled = !track.enabled));
            setAudioEnabled(!audioEnabled);
        }
    };

    const endCall = () => {
        setEndingCall(true);
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setScreenSize(false);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    };

    return (
        <div>
            <Header />
            <div className="aryanow-project-section-payment">
                <div className="demo-back-button">
                    <Button className='back-button'><a href="/ourWork"> <FaChevronLeft /> Back</a></Button>
                </div>

                <div className="payment-section communiation-section">
                    {
                        showChat ? (
                            <h2>Communication Module - Chat</h2>
                        ) : showVideo ? (
                            <h2>Communication Module - Video</h2>
                        ) : (
                            <h2>Communication Module</h2>
                        )
                    }

                </div>

                {showChat ? (
                    <div className="payment-section">
                        <div className="demo-instruction communication-chat">
                            {
                                screenSize ? (
                                    <>
                                        <div className='col demo-right-section authorized-screen '>
                                            <ChatOption />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='border-right col-6'>
                                            <h2>Instructions:</h2>
                                            <ul>
                                                <ol>1. Start typing any message and send.</ol>
                                                <ol>2. For the purpose of this example our chat bot will answer you.</ol>
                                            </ul>
                                        </div>
                                        <div className='col-6 demo-right-section authorized-screen'>
                                            <ChatOption />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                ) : showVideo ? (
                    <div className="payment-section">
                        <div className="demo-instruction communication-chat">
                            {
                                screenSize ? (
                                    <div className='col demo-right-section authorized-screen video-preview'>
                                        <div className="preview-screen">
                                            <video id='local-video' className='local-video' ref={videoRef} autoPlay playsInline muted />

                                            {/* <FontAwesomeIcon icon={faCog} className="settings-icon" /> */}
                                        </div>
                                        <div className="controls">
                                            <div className='flex align-items-center justify-content-evenly gap-3'>
                                                <div className='flex align-items-center justify-content-evenly gap-2'>
                                                    <div className='flex align-items-center justify-content-evenly gap-2' onClick={toggleVideo}>
                                                        <FontAwesomeIcon icon={videoEnabled ? faVideo : faVideoSlash} />
                                                    </div>
                                                </div>

                                                <div className='flex align-items-center justify-content-evenly gap-2' onClick={toggleAudio}>
                                                    <FontAwesomeIcon icon={audioEnabled ? faMicrophone : faMicrophoneSlash} />
                                                </div>

                                                <div className='flex align-items-center justify-content-evenly gap-2' onClick={endCall}>
                                                    <FontAwesomeIcon icon={faPhoneSlash} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='small-video-container'>
                                            <video id='local-small-video' className='local-small-video' ref={smallVideoRef} autoPlay playsInline muted />
                                        </div>

                                    </div>
                                ) : (
                                    <>
                                        <div className='border-right col-6 video-preview'>
                                            <h2>Make sure camera and microphone are turned on.</h2>

                                            <div className='flex justify-content-center'>
                                                <div className='preview-video-screen'>

                                                    <video id='local-video' className='local-video' ref={videoRef} autoPlay playsInline muted />

                                                    <div className="controls">
                                                        <div className='flex gap-4 p-1 w-full justify-content-around'>
                                                            <div className='flex align-items-end justify-content-evenly gap-4'>
                                                                <FontAwesomeIcon icon={videoEnabled ? faVideo : faVideoSlash} />
                                                                <div className={`control-item toggle-button ${videoEnabled ? 'enabled' : 'disabled'}`} onClick={toggleVideo}></div>
                                                            </div>

                                                            <div className='flex align-items-end justify-content-evenly gap-4'>
                                                                <FontAwesomeIcon icon={audioEnabled ? faMicrophone : faMicrophoneSlash} />
                                                                <div className={`control-item toggle-button ${audioEnabled ? 'enabled' : 'disabled'}`} onClick={toggleAudio}></div>
                                                            </div>

                                                            <div style={{ display: 'flex', gap: '3px' }}>
                                                                {[...Array(10)].map((_, i) => (
                                                                    <div
                                                                        key={i}
                                                                        ref={el => metersRef.current[i] = el}
                                                                        className={`volume-bar ${i < 5 ? 'active' : ""}`}
                                                                    ></div>
                                                                ))}
                                                            </div>

                                                            {/* <FontAwesomeIcon icon={faCog} className="settings-icon" /> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 demo-right-section authorized-screen'>
                                            <h2>Instructions:</h2>
                                            <ul>
                                                <ol>1. Click on the button Video Call.</ol>
                                                <ol>2. For finish the call click on the phone icon.</ol>
                                            </ul>
                                            <h2>Video Call</h2>
                                            <Button onClick={handleScreenSize} className='p-button mt-2'> Video Call</Button>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="payment-section">
                            <p>
                                Startup2launch (S2L) features a comprehensive communication module enabling entrepreneurs
                                to engage with customers through chat, audio, and video.
                            </p>

                            <p>
                                This tool facilitates two-way text messaging, conducts surveys, and maintains constant contact,
                                ensuring entrepreneurs can effectively communicate, gather feedback,
                                and build stronger relationships with their client.
                            </p>
                        </div>

                        <div className="m-5 align-two-btn">
                            <Button onClick={handleChatRedirect} className='p-button'> Try Now Chat</Button>
                            <Button onClick={handleVideoRedirect} className='button-style-primary-square-line'>Try Now Video Call</Button>
                        </div>
                    </>
                )}

                <div className='demo-project-spinner'>
                    {endingCall && (
                        <div className="overlay">
                            <div className="spinner-section">
                                <ProgressSpinner />
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <Footer />
        </div>
    );
};

export default CommunicationModule;;
