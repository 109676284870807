import React, { useEffect, useState } from 'react';
import Header from '../../Home/Header/Header';
import Footer from '../../Home/Footer/Footer';
import { Button } from 'primereact/button';
import { FaChevronLeft } from 'react-icons/fa';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import "./OurDemoProject.scss";
import { PAYMENT } from '../../../Utils/LandingService';

const PaymentOption = () => {
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(false);

    const handleRedirect = () => {
        setShow(true);
    };

    const clientId = 'AfYAXMgORJGiYzQ7OrwnhKoyAVfcQLEg3tbg516GmlyruX61y98zjnYcmy9APaFx97jrSZ8CCRdYCyzQ';
    const [, setOrderId] = useState("");
    const [simpleStatus, setSimpleStatus] = useState('');
    const [date, setDate] = useState('');

    useEffect(() => {
        if (simpleStatus && simpleStatus?.date) {
            const date = simpleStatus?.date;
            const day = new Date(date);
            const options = { month: 'short', day: '2-digit', year: 'numeric' };
            setDate(day.toLocaleDateString('en-US', options));
        }
    }, [simpleStatus]);


    return (
        <div>
            <Header />
            <div className="aryanow-project-section-payment">
                <div className="demo-back-button">
                    <Button className='back-button'><a href="/ourWork"> <FaChevronLeft /> Back</a></Button>
                </div>

                <div className="payment-section">
                    <h2>Payment Options</h2>
                </div>

                {show ? (
                    <div className="payment-section">
                        <div className="demo-instruction">
                            <div className='border-right col-6'>
                                <h2>Instructions:</h2>
                                <ul>
                                    <ol>1. Select the payment method you prefer.</ol>
                                    <ol>2. If you will use the debit or credit card payment method use any information,
                                        you don’t need to use your personal credit or debit card.
                                    </ol>
                                    <ol>3. If you will  use PayPal payment method, you can't use your personal account. Enter this account to see the example:
                                        <p>Email:payment.example.s2l@gmail.com</p>
                                        <p>Password: Test@123</p>
                                    </ol>
                                </ul>
                                <p>
                                    Notes: This is a demo, all the elements to pay are fictional and are only to create the payment flow.
                                    The provided PayPal account is only for test experiment, you can't use for your personal payments.
                                </p>
                                <p>
                                    {`You will not be pay anything.`.toUpperCase()}
                                </p>
                            </div>

                            {status ?
                                (
                                    <div className='col-6 demo-right-section authorized-screen'>
                                        <h2>Payment Authorized</h2>
                                        <p>Payment ID: <span>{simpleStatus?.paymentDetailsId}</span></p>
                                        <p>Date: <span> {date}</span></p>
                                        <p>Time:  <span>{simpleStatus?.time}</span></p>
                                        <p>Payment Method: <span>{simpleStatus?.modeOfPayment}</span></p>
                                        <p>Name: <span> {simpleStatus?.name}</span></p>
                                        <p>Payment Amount: <span>${simpleStatus?.totalAmount + ' ' + simpleStatus?.currencyCode}</span></p>
                                    </div>

                                ) : (
                                    <div className='col-6 demo-right-section'>
                                        <h2>Payment Methods</h2>
                                        <p>Amount to pay: $ 0.50 USD</p>

                                        <PayPalScriptProvider options={{
                                            'client-id': clientId,
                                            'intent': 'authorize'
                                        }}>
                                            <PayPalButtons
                                                style={{
                                                    color: 'white',
                                                    borderColor: 'blue',
                                                }}
                                                createOrder={(data, actions) => {
                                                    return actions.order.create({
                                                        purchase_units: [
                                                            {
                                                                items: [
                                                                    {
                                                                        name: "T-Shirt",
                                                                        description: "Green XL",
                                                                        quantity: "1",
                                                                        unit_amount: {
                                                                            currency_code: "USD",
                                                                            value: 0.50
                                                                        }
                                                                    }
                                                                ],
                                                                amount: {
                                                                    currency_code: "USD",
                                                                    value: 0.50,
                                                                    breakdown: {
                                                                        item_total: {
                                                                            currency_code: "USD",
                                                                            value: 0.50
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        ],
                                                    });
                                                }}
                                                onApprove={async (data) => {
                                                    try {
                                                        const orderId = data.orderID;
                                                        setOrderId(orderId)
                                                        const response = await fetch(`${PAYMENT}${orderId}`, {
                                                            method: 'GET',
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                            },
                                                        });

                                                        if (response.ok) {
                                                            const jsonResponse = await response.json();
                                                            setSimpleStatus(jsonResponse);
                                                            setStatus(true)
                                                        } else {
                                                            console.error('Failed to fetch data:', response.status);
                                                        }
                                                    } catch (error) {
                                                        console.error('Error during authorization:', error);
                                                    }
                                                }}
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                )}
                        </div>

                    </div>
                ) : (
                    <>
                        <div className="payment-section">
                            <p>
                                Startup2launch (S2L) offers versatile payment solutions, enabling entrepreneurs to
                                accept credit card, debit card, and PayPal payments seamlessly.
                            </p>

                            <p>
                                For any specific payment requirements, the S2L team is equipped to develop
                                custom payment modes tailored to your unique business needs, ensuring flexibility
                                and convenience for your customers.
                            </p>
                        </div>

                        <div className="m-5">
                            <Button onClick={handleRedirect} className='p-button'> Try Now</Button>
                        </div>
                    </>
                )}
            </div>

            <Footer />

        </div>
    )
}

export default PaymentOption;