import React, { useState, useEffect } from "react";
import { Card } from 'primereact/card';
import java from "../../../Assets/Recruitment/Java-Developer.jpg";
import frontEnd from "../../../Assets/Recruitment/Front- End Engineer.jpg";
import dataEngg from "../../../Assets/Recruitment/Data Engineer.jpg";
import "../Recruitment.scss"
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import jobBanner from "../../../Assets/Recruitment/are-you-seeking-talent-or-a-job-banner-background.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Get_Job_Openings, CONTENT_HEADERS } from '../../../Utils/LandingService';

const PricingSection = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = () => {
        axios
            .get(Get_Job_Openings, { headers: CONTENT_HEADERS })
            .then((response) => {
                if (response.data && Array.isArray(response.data.jobs)) {
                    const filteredJobs = response.data.jobs.filter(job => job.state === "published").sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setJobs(filteredJobs);
                } else {
                    console.error('No jobs found in the API response');
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    };

    return (
        <>
            <div className="section-heading m-5 text-center">
                <h1> Open opportunities</h1>
            </div>
            <div className="m-5 startupservices-section">
                <div className="grid justify-content-evenly">
                    {jobs.map(job => (
                        <div className="col-12 lg:col-4" key={job.id}>
                            <div className="h-full">
                                <Card className="h-full flex flex-column price-Section">
                                    <div>
                                        <div className='grid justify-content-between p-2 align-items-start flex-nowrap mobile-screen-response'>
                                            <div className='flex align-items-start gap-3'>
                                                <div>
                                                    <h2>{job.title}</h2>
                                                    <p style={{ color: "#B7AFAF" }}>{job.location.location_str}</p>


                                                </div>
                                            </div>

                                            <div className="desktop-view-btn">
                                                <Button className='button-style-primary'><a href={job.url} target="_blank">Apply</a></Button>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="">
                                            <p className="mt-0 text-style">
                                                Location: {job.location?.workplace_type === 'on_site' ? 'Onsite' : 'Remote'}
                                            </p>

                                            <p className="mt-0 text-style">
                                                Job Type: {job.department}
                                            </p>

                                            <p className="mt-0">{job.description}</p>

                                            <p className="price-amount flex align-items-center gap-3 pt-2">
                                                <FontAwesomeIcon icon={faDollar} color='#fff' style={{ fontSize: '0.8em', background: "#1F306E", padding: "8px", borderRadius: "8px" }} />
                                                <div>
                                                    {job.salary ? `$${job.salary.salary_from} - $${job.salary.salary_to} / hour` : 'Salary not provided'}
                                                </div>
                                            </p>
                                        </div>

                                        <div className="mobile-view-apply-btn pt-3">
                                            <Button className='button-style-primary'><a href={job.url} target="_blank">Apply</a></Button>
                                        </div>

                                    </div>
                                </Card>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="m-5 startupservices-section">
                <div className="grid"> */}
            {/* <div className="col-12 lg:col-4 ">
                        <div className="h-full ">
                            <Card className="h-full flex flex-column price-Section">
                                <div>
                                    <div className='grid justify-content-between p-2 align-items-start flex-nowrap mobile-screen-response'>
                                        <div className='flex align-items-start gap-3'>
                                            <Avatar image={java} size='large' shape="circle" width="50px" ></Avatar>
                                            <div>
                                                <h2>Java Developer</h2>
                                                <p style={{ color: "#B7AFAF" }}>Remote - USA</p>
                                            </div>
                                        </div>
                                        <Button className='button-style-primary'> <a href="https://apply.workable.com/startup2launch/jobs" target="_blank"> Apply</a></Button>
                                    </div>

                                    <p className="mt-0">Crafting robust, scalable applications using Java,
                                        focusing on innovation and excellence in software solutions.</p> */}

            {/* <p className="price-amount flex align-items-center gap-3">
                                        <FontAwesomeIcon icon={faDollar} color='#fff' style={{ fontSize: '0.8em', background: "#1F306E", padding: "8px", borderRadius: "8px" }} />

                                        <div>
                                            $$$$$ - $$$$$
                                        </div>
                                    </p> */}
            {/* </div>
                            </Card>
                        </div>
                    </div> */}


            {/* <div className="col-12 lg:col-4">
                        <div className="h-full">
                            <Card className="h-full flex flex-column price-Section">
                                <div>
                                    <div className='grid justify-content-between p-2 align-items-start flex-nowrap mobile-screen-response'>
                                        <div className='flex align-items-start gap-3'>
                                            <Avatar image={frontEnd} size='large' shape="circle" width="50px" ></Avatar>
                                            <div>
                                                <h2>Front- End Engineer</h2>
                                                <p style={{ color: "#B7AFAF" }}>Remote - USA</p>
                                            </div>
                                        </div>
                                        <Button className='button-style-primary'> <a href="https://apply.workable.com/startup2launch/jobs" target="_blank">Apply</a></Button>
                                    </div>

                                    <p className="mt-0">Front End Developer: Designing intuitive,
                                        visually appealing user interfaces with modern web technologies for optimal user experience.</p> */}

            {/* <p className="price-amount flex align-items-center gap-3">
                                        <FontAwesomeIcon icon={faDollar} color='#fff' style={{ fontSize: '0.8em', background: "#1F306E", padding: "8px", borderRadius: "8px" }} />

                                        <div>
                                            $$$$$ - $$$$$
                                        </div>
                                    </p> */}
            {/* </div>
                            </Card>
                        </div>
                    </div> */}

            {/* <div className="col-12 lg:col-4">
                        <div className="h-full">
                            <Card className=" h-full flex flex-column price-Section">
                                <div>
                                    <div className='grid justify-content-between p-2 align-items-start flex-nowrap mobile-screen-response'>
                                        <div className='flex align-items-start gap-3'>
                                            <Avatar image={dataEngg} size='large' shape="circle" width="50px" ></Avatar>
                                            <div>
                                                <h2>Data Engineer</h2>
                                                <p style={{ color: "#B7AFAF" }}>Remote - USA</p>
                                            </div>
                                        </div>
                                        <Button className='button-style-primary'> <a href="https://apply.workable.com/startup2launch/jobs" target="_blank">Apply</a></Button>
                                    </div>

                                    <p className="mt-0">Building and maintaining scalable data pipelines,
                                        ensuring reliable data flow for insightful business decisions.</p> */}

            {/* <p className="price-amount flex align-items-center gap-3">
                                        <FontAwesomeIcon icon={faDollar} color='#fff' style={{ fontSize: '0.8em', background: "#1F306E", padding: "8px", borderRadius: "8px" }} />

                                        <div>
                                            $$$$$ - $$$$$
                                        </div>
                                    </p> */}
            {/* </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="m-5 job-Banner flex justify-content-around align-items-center">
                <div className="job-content flex justify-content-around align-items-center">
                    <div>
                        <h2>Are you seeking talent or a job?</h2>
                        <p>Let our team assist you in reaching your destination.</p>
                    </div>
                    <div className='button-section'>
                        <Button className='button-style-white'><a href="/newclientform">Start Hiring</a></Button>
                        <Button className='button-style-white-secondary'><a href="https://apply.workable.com/startup2launch/jobs" target="_blank">Find a job</a></Button>
                    </div>
                </div>
                <img src={jobBanner} alt="" />
            </div>
        </>
    )
}

export default PricingSection