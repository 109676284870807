import React, { useState, useEffect } from "react";
import '../Our-Work/OurWork.scss';
import Header from "../Home/Header/Header";
import Footer from "../Home/Footer/Footer";
import { Button } from "primereact/button";
import Aryanaow from '../../Assets/Our-Work/AryaNow_Image_Projects.png';
import Laulama from '../../Assets/Our-Work/LaulamaSchools_Image_Projects.png';
import Autism from '../../Assets/Our-Work/ACC_Image_Projects.png';
import Payment from '../../Assets/Our-Work/Payment_options.svg';
import Automated from '../../Assets/Our-Work/Automated_appointment.svg';
import Smile from '../../Assets/Our-Work/Image_and_video_analysis.svg';
import Communication from '../../Assets/Our-Work/Communication.svg';
import Ai from '../../Assets/Our-Work/AI_analysis.svg';
import ChatBot from '../../Assets/Our-Work/chatbot.svg';
import Technologies from '../../Assets/Our-Work/Technologies_logos_complete.svg';
import { useLocation } from "react-router-dom";

const OurWork = () => {
    const [showFullContent1, setShowFullContent1] = useState(false);
    const [showFullContent2, setShowFullContent2] = useState(false);
    const [showFullContent3, setShowFullContent3] = useState(false);

    const toggleContent1 = () => {
        setShowFullContent1(!showFullContent1);
    };
    const toggleContent2 = () => {
        setShowFullContent2(!showFullContent2);
    };
    const toggleContent3 = () => {
        setShowFullContent3(!showFullContent3);
    };

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                const scrollOffset = 200;
                const targetOffsetTop = targetElement.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: targetOffsetTop - scrollOffset, behavior: 'smooth' });
                // targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
        }
    }, [location.hash]);

    return (
        <>
            <Header />
            <div className="our-work-section">
                <div className="work-heading">
                    <h3>Our Work</h3>
                </div>
                <div id="projects" className="project-section m-6">
                    <h4>Projects</h4>
                    <p>Take a deep dive into our  portfolio of innovative and impactful projects that showcase our dedication and expertise.</p>
                    <div>
                        <div className='project-sec pt-5 pb-5'>
                            <div className='project-section'>
                                <div className='img-sec'>
                                    <img src={Aryanaow} alt='Aryanaow' />
                                </div>
                                <div className='name'>Arya Now</div>
                                <div className='project-button'>
                                    <Button>Mental Health </Button>
                                </div>
                                <div className='content'>
                                    {showFullContent1 ?
                                        "AryaNow is a virtual mental health care provider focused on providing the best patient experience. We use audio and video capabilities from the digital devices our patients use securely to help solve their mental health issues anytime, anywhere. There is no waiting for treatment, and it is Easy to access and patient-centric."
                                        :
                                        "AryaNow is a virtual mental health care provider focused on providing the best patient experience. We use audio and video capabilities from the digital devices our patients"}
                                    <span className="see" onClick={toggleContent1}>{showFullContent1 ? "See less" : "See more"}</span>
                                </div>
                            </div>

                            <div className='project-section'>
                                <div className='img-sec'>
                                    <img src={Laulama} alt='Laulama' />
                                </div>
                                <div className='name'>Laulama Schools</div>
                                <div className='project-button'>
                                    <Button>
                                        Education
                                    </Button>
                                </div>
                                <div className='content'>
                                    {showFullContent2 ?
                                        "Laulama school is working to innovate education’s underlying improvement system for breakthroughs that transform schools for their leaders, educators, and students. Laulama Learning and Laulama Schools result from a 13-year venture focused on impacting education in Hawaii. This venture started in 2010 with the formation of an organizational transformation practice called Optimum Business Solutions."
                                        :
                                        "Laulama school is working to innovate education’s underlying improvement system for breakthroughs that transform schools for their leaders, educators, and students."}
                                    <span className="see" onClick={toggleContent2}>{showFullContent2 ? "See less" : "See more"}</span>
                                </div>
                            </div>

                            <div className='project-section'>
                                <div className='img-sec'>
                                    <img src={Autism} alt='Autism' />
                                </div>
                                <div className='name'>Autism Complete Care</div>
                                <div className='project-button'>
                                    <Button>  Mental Health </Button>
                                    <Button> Autism</Button>
                                </div>
                                <div className='content'>
                                    {showFullContent3 ?
                                        "Autism Complete Care started with a team of experts in behavior analysis, psychiatry, and technologists who wanted to change how autism therapy is done. They saw that every child is special and that therapy should be made just for them. They made a clinic that understands each child’s needs, includes expert mental health advice, and works closely with families in the therapy journey. We began in a small, friendly place, but with a big dedication to our values and the families we help. Our story is about caring deeply, never giving up, and believing in the bright future of every child with autism."
                                        :
                                        "Autism Complete Care started with a team of experts in behavior analysis, psychiatry, and technologists who wanted to change how autism therapy is done."}
                                    <span className="see" onClick={toggleContent3}>{showFullContent3 ? "See less" : "See more"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="frameworks" className="frameworks-section ">
                    <div className="m-5">
                        <h4 className="p-2">Frameworks</h4>
                        <p className="p-2">Startup2launch (S2L) prioritizes the latest software and tools to ensure unparalleled customer experiences, seamless growth for client projects, and robust security and compliance. This approach guarantees resiliency and adaptability, keeping clients' applications at the forefront of innovation and market demands.</p>
                        <div className="framework-collections p-5">
                            <div className='frameworkdetails'>
                                <div className='img'>
                                    <img src={Payment} alt='Payment' />
                                </div>
                                <div className='heading'>Payment Options</div>
                                <div className="content"><a href="/ourWork/payment"> Try out</a></div>
                            </div>
                            <div className='frameworkdetails'>
                                <div className='img'>
                                    <img src={Automated} alt='Automated' />
                                </div>
                                <div className='heading'>Automated appointments</div>
                                <div className="content"> <a href="/ourWork/Appointment">Try out</a></div>
                            </div>
                            {/* <div className='frameworkdetails'>
                                <div className='img'>
                                    <img src={Smile} alt='Smile' />
                                </div>
                                <div className='heading'>Image and Video Analysis</div>
                                <div className="content"><a href="/ourWork/Image&VideoAnalysis"> Try out</a></div>
                            </div> */}
                            <div className='frameworkdetails'>
                                <div className='img'>
                                    <img src={Communication} alt='Communication' />
                                </div>
                                <div className='heading'>Communication </div>
                                <div className="content"><a href="/ourWork/CommunicationModule"> Try out</a></div>
                                {/* </div> */}
                            </div>
                            {/* <div className='frameworkdetails'>
                                <div className='img'>
                                    <img src={Ai} alt='Ai' />
                                </div>
                                <div className='heading'>ML and AI - I Smart Dashboard</div>
                                <div className="content"> Try out</div>
                            </div>
                            <div className='frameworkdetails'>
                                <div className='img'>
                                    <img src={Ai} alt='Ai' />
                                </div>
                                <div className='heading'>ML and AI - II Analytics </div>
                                <div className="content"> Try out</div>
                            </div> */}
                            <div className='frameworkdetails'>
                                <div className='img'>
                                    <img src={ChatBot} alt='ChatBot' />
                                </div>
                                <div className='heading'>Chatbot</div>
                                <div className="content"><a href="/ourWork/chatbot"> Try out</a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="technologies" className="technologies-section">
                    <div className="m-5 flex align-items-start justify-content-center flex-wrap">
                        <div className="col-6 technology-details">
                            <h4>Technologies</h4>
                            <p>Allow us to introduce you to a selection of the advanced technologies we employ to drive the success of our projects, each carefully chosen to meet our standards of excellence and innovation. </p>
                        </div>
                        <div className='technology-img col-6'>
                            <img src={Technologies} alt='technologies' />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
};

export default OurWork;
