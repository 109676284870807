import React, { useEffect } from 'react'
import AboutusBanner from './Aboutus-banner/AboutusBanner';
import Startup2launchstory from './Startup2launch-Story/Startup2lauchstory';
import Startup2launchTeam from './Startup2launchteam/Startup2launchTeam';
import Startup2launchMission from './Startup2launchmission/Startup2launchmission';
import Startup2launchValues from './OurValues/Startup2launchValues';
import Header from '../Home/Header/Header';
import Footer from '../Home/Footer/Footer';
import { ScrollTop } from 'primereact/scrolltop';
import { useLocation } from 'react-router-dom';

const AboutUs = () => {

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                const scrollOffset = 200;
                const targetOffsetTop = targetElement.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: targetOffsetTop - scrollOffset, behavior: 'smooth' });
                // targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
        }
    }, [location.hash]);

    return (
        <div>
            <ScrollTop />
            <Header />
            <AboutusBanner />
            <Startup2launchstory />
            <Startup2launchValues />
            <Startup2launchMission />
            <Startup2launchTeam />

            <Footer />
        </div>
    )
}

export default AboutUs