import React, { useState } from "react";
import { Button } from "primereact/button";
import "./Startup2launchstory.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const OurStory = () => {
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);

  const toggleFullText = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  return (
    <div id="ourStory" className="our-story-container">
      <div className="flex align-items-center justify-content-between story">
      <h2 className="our-story-title">Our Story</h2>
      </div>
      <div className="our-story-text">
        <p>
          Born amidst the challenges of the COVID era, we identified a crucial
          gap in the startup ecosystem: a lack of comprehensive, end-to-end
          services to transform ideas into thriving ventures. As founders, we
          embarked on this ambitious journey fueled by a profound belief in the
          power of entrepreneurship.
        </p>
        <div
          className={
            isFullTextVisible ? "additional-text shown" : "additional-text"
          }
        >
          <p>
            Our experience launching a MedTech startup taught us invaluable
            lessons over a demanding 18-month period. This inspired us to
            revolutionize the startup process. We firmly believe that every
            idea, regardless of complexity, should swiftly find its place in the
            market.
          </p>
          <p>
            At Startup2Launch, we operate with an ownership mindset, knowing
            that shared success drives remarkable outcomes. Aligning our
            interests and commitment to your journey as our own startup, we
            require a 3% equity stake to bring your startup to life.
          </p>
          <p>
            Our comprehensive services cover everything you need to succeed,
            from startup consulting, market research, and business planning to
            UI/UX design, branding, marketing, product development, funding,
            investor relations, and legal compliance.
          </p>
        </div>
        <Button
          label={isFullTextVisible ? "View Less" : "View More"}
          onClick={toggleFullText}
        />
      </div>
    </div>
  );
};

export default OurStory;
