import React, { useState } from "react";
import '../Our-Project-Section/ProjectSection.scss';
import Header from "../../Home/Header/Header";
import Footer from "../../Home/Footer/Footer";
import { Button } from "primereact/button";
import AryanaowLogo from '../../../Assets/Our-Work/ARYALOGO.png';
import quote from '../../../Assets/Our-Work/quote-left-solid.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { FaChevronLeft } from "react-icons/fa";
import { Accordion, AccordionTab } from "primereact/accordion";

const initialTestimonialsData = [
    {
        name: 'Mina',
        role: 'Patient',
        description: 'I was very impressed with the quality of technical services during my doctor’s visit. I was able to be seen right away',
    },
    {
        name: 'Ram',
        role: 'Patient',
        description: 'Urgent psychiatry in less than two hours from very experienced and professional psychiatrists.',
    },
    {
        name: 'Jesse',
        role: 'Patient',
        description: 'I had a great experience here. Everything was timely, I felt understood and got the results. I was looking for. Very professional.',
    },
    {
        name: 'Chris',
        role: 'Patient',
        description: 'These are great guys. This service is a life saver for mentally ill people. Their Psychiatrist are top notch.',
    },
    {
        name: 'Bonnie',
        role: 'Patient',
        description: 'These are great guys. This service is a life saver for mentally ill people. Their Psychiatrist are top notch.',
    },
];

const AryaNowProject = () => {

    const [activeSection, setActiveSection] = useState("about");
    const [testimonials, setTestimonials] = useState(initialTestimonialsData);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            setActiveSection(id);
        }
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };


    const projects = [
        {
            title: 'General Information',
            content: (
                <div className="m-5 aryanow-img-details">
                    <div className="aryanow-content-section p-3">
                        <div className="flex align-items-center justify-content-start gap-5 aryanowlogo-sec">
                            <img src={AryanaowLogo} alt='AryanaowLogo' />
                            <div>
                                <div>AryaNow Project</div>
                                <span>2023</span>
                            </div>
                        </div>
                        <div>
                            <p>AryaNow is a virtual mental health care provider focused on providing the best patient experience. We use audio and video capabilities from the digital devices our patients use securely
                                to help solve their mental health issues anytime, anywhere. There is no waiting for treatment, and it is Easy to access and patient-centric.</p>
                            <div className="flex align-items-center justify-content-start gap-5">
                                <Button className="mental-health-button">Mental Health</Button>
                                <Button className="mental-health-button">TeleMedicine</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'About',
            content: (
                <div id="about" className="about-details">
                    <div className="m-5">
                        <p>AryaNow org’s mission is to make psychiatric services accessible, free of cost, and convenient. There are no hoops to jump through, just meaningful, on-demand care at no cost to patients who need quality care.</p>
                        <p> AryaNow org was created by world-class psychiatrists and technologists to overcome barriers like cost, time, and availability, ensuring everyone has access to quality support when and where they need it without worrying about the cost of treatment. </p>
                    </div>
                </div>
            )
        },
        {
            title: 'Technologies',
            content: (
                <div id="technologies" className="technologies-details">
                    <div className="m-5">
                        <p>AryaNow leverages AWS for robust cloud services, responsive design for cross-device compatibility, multi-modal communication for patient-provider interaction, secure payment processing, custom-built HIPAA-compliant EHR, advanced notification systems, intelligent scheduling, and detailed PDF summaries, embodying a comprehensive and secure telehealth platform. </p>
                    </div>
                </div>
            )
        },
        {
            title: 'Frameworks',
            content: (
                <div id="frameworksUsed" className="frameworks-details">
                    <div className="m-5">
                        <ul>
                            <li>AWS suite for scalable and reliable cloud services</li>
                            <li>HTML5, CSS3, and TypeScript with Angular for responsive front-end</li>
                            <li>WebRTC and WebSocket for real-time audio/video and chat communication</li>
                            <li>PayPal APIs for secure payment processing</li>
                            <li>Custom HIPAA-compliant EHR system with Java, API’s</li>
                            <li>Service workers and push APIs for real-time notifications</li>
                            <li>Custom scheduling algorithms or libraries for doctor-patient matching</li>
                            <li>Apache PDFBox for structured PDF report generation</li>
                        </ul>
                    </div>
                </div>
            )
        },
        {
            title: 'Testimonials',
            content: (
                <div id="testimonials" className="testimonials-details">
                    <div className="m-5">
                        <div className="testimonal-section grid">
                            {testimonials.map(testimonial => (
                                <div className="testimonial-card col-11 md:col-11 lg:col-5 xl:col-5">
                                    <div className='testimonial'>
                                        <div className="testimonial-names">
                                            <div className='tuser-name'>{testimonial.name}</div>
                                            <span>{testimonial.role}</span>
                                        </div>
                                        <div><img className='quote-image' src={quote} alt="" /></div>
                                    </div>
                                    <div className='tuser-desc'>{testimonial.description}</div>
                                    <div className='rating'>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-gray' /></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        },
    ];

    return (
        <>
            <Header />
            <div className="aryanow-project-section">
                <div className="back m-5">
                    <Button className='back-button'><a href="/investor"> <FaChevronLeft /> Back</a></Button>
                </div>
                <div className="m-5 aryanow-img-details ">
                    <div className="aryanow-content-section p-3">
                        <div className="flex align-items-center justify-content-start gap-5">
                            <img src={AryanaowLogo} alt='AryanaowLogo' />
                            <div>
                                <div>AryaNow Project</div>
                                <span>2023</span>
                            </div>
                        </div>
                        <div>
                            <p>AryaNow is a virtual mental health care provider focused on providing the best patient experience. We use audio and video capabilities from the digital devices our patients use securely
                                to help solve their mental health issues anytime, anywhere. There is no waiting for treatment, and it is Easy to access and patient-centric.</p>
                            <div className="flex align-items-center justify-content-start gap-5">
                                <Button className="mental-health-button">Mental Health</Button>
                                <Button className="mental-health-button">TeleMedicine</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap m-5 aryanow-main-details">
                    <div className="col-3 arya-sub-headings">
                        <p
                            className={activeSection === "about" ? "active" : ""}
                            onClick={() => scrollToSection("about")}
                            style={{ backgroundColor: activeSection === "about" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "about" ? '#fff' : '#000' }}
                        >
                            About
                        </p>
                        <p
                            className={activeSection === "technologies" ? "active" : ""}
                            onClick={() => scrollToSection("technologies")}
                            style={{ backgroundColor: activeSection === "technologies" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "technologies" ? '#fff' : '#000' }}
                        >
                            Technologies
                        </p>
                        <p
                            className={activeSection === "frameworksUsed" ? "active" : ""}
                            onClick={() => scrollToSection("frameworksUsed")}
                            style={{ backgroundColor: activeSection === "frameworksUsed" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "frameworksUsed" ? '#fff' : '#000' }}
                        >
                            Frameworks
                        </p>
                        <p
                            className={activeSection === "testimonials" ? "active" : ""}
                            onClick={() => scrollToSection("testimonials")}
                            style={{ backgroundColor: activeSection === "testimonials" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "testimonials" ? '#fff' : '#000' }}
                        >
                            Testimonials
                        </p>
                    </div>

                    <div className="col-9 arya-sub-paragraphs">
                        <div id="about">
                            <>
                                <h3>About</h3>
                                <p>AryaNow org’s mission is to make psychiatric services accessible, free of cost, and convenient. There are no hoops to jump through, just meaningful, on-demand care at no cost to patients who need quality care.</p>
                                <p> AryaNow org was created by world-class psychiatrists and technologists to overcome barriers like cost, time, and availability, ensuring everyone has access to quality support when and where they need it without worrying about the cost of treatment. </p>
                            </>
                        </div>
                        <div id="technologies">
                            <>
                                <h3>Technologies</h3>
                                <p>AryaNow leverages AWS for robust cloud services, responsive design for cross-device compatibility, multi-modal communication for patient-provider interaction, secure payment processing, custom-built HIPAA-compliant EHR, advanced notification systems, intelligent scheduling, and detailed PDF summaries, embodying a comprehensive and secure telehealth platform. </p>
                            </>
                        </div>
                        <div id="frameworksUsed">
                            <>
                                <h3>Frameworks</h3>
                                <ul>
                                    <li>AWS suite for scalable and reliable cloud services</li>
                                    <li>HTML5, CSS3, and TypeScript with Angular for responsive front-end</li>
                                    <li>WebRTC and WebSocket for real-time audio/video and chat communication</li>
                                    <li>PayPal APIs for secure payment processing</li>
                                    <li>Custom HIPAA-compliant EHR system with Java, API’s</li>
                                    <li>Service workers and push APIs for real-time notifications</li>
                                    <li>Custom scheduling algorithms or libraries for doctor-patient matching</li>
                                    <li>Apache PDFBox for structured PDF report generation</li>
                                </ul>
                            </>
                        </div>

                        <div id="testimonials">
                            <>
                                <h3>Testimonials</h3>
                                <div className="testimonal-section grid">
                                    {testimonials.map(testimonial => (
                                        <div className="testimonial-card col-12 md:col-12 lg:col-5 xl:col-5">
                                            <div className='testimonial'>
                                                <div className="testimonial-names">
                                                    <div className='tuser-name'>{testimonial.name}</div>
                                                    <span>{testimonial.role}</span>
                                                </div>
                                                <div><img className='quote-image' src={quote} alt="" /></div>
                                            </div>
                                            <div className='tuser-desc'>{testimonial.description}</div>
                                            <div className='rating'>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-gray' /></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='mobile-aryanow-project-section'>
                <div className="back m-5">
                    <Button className='back-button'><a href="/investor"> <FaChevronLeft /> Back</a></Button>
                </div>
                <Accordion>
                    {projects.map((project, index) => (
                        <AccordionTab
                            key={index}
                            header={
                                <div
                                    className={`accordion-header ${activeIndex === index ? 'active-tab' : ''}`}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    {project.title}
                                    <FontAwesomeIcon
                                        icon={activeIndex === index ? faMinus : faPlus}
                                        className="accordion-icon"
                                    />
                                </div>
                            }
                            contentClassName={activeIndex === index ? 'active-content' : ''}
                        >
                            {project.content}
                        </AccordionTab>
                    ))}
                </Accordion>
            </div>
            <Footer />
        </>
    );
};

export default AryaNowProject;
