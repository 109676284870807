/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './Plans.scss';
import plansheader from '../../Assets/Plans/Banner-Plans.png';
import plansplainheader from '../../Assets/Plans/Plans-Plain-Banner.png';
import bulb from '../../Assets/Plans/lightbulb-solid 1.svg';
import breifcase from '../../Assets/Plans/briefcase-solid 1.svg';
import copyright from '../../Assets/Plans/copyright-solid 1.svg';
import sitemap from '../../Assets/Plans/sitemap-solid 1.svg';
import trophy from '../../Assets/Plans/trophy-solid 1.svg';
import ranking from '../../Assets/Plans/ranking-star-solid 1.svg';
import moneybill from '../../Assets/Plans/money-bill-solid 1.svg';
import star from '../../Assets/Plans/star-solid 1.svg';
import { FaCheck } from "react-icons/fa";
import { Button } from 'primereact/button';
import Header from '../Home/Header/Header';
import Footer from '../Home/Footer/Footer';
import { ScrollTop } from 'primereact/scrolltop';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import startup2LaunchLogo from '../../Assets/Home Page/Startup2Launch-Logo-White-LB-2024_.png';
const GreenDot = () => <span className="green-dot" style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></span>;

const Plans = () => {
    const navigate = useNavigate();
    const [desktopEquityDialog, setDesktopEquityDialog] = useState(false);
    const [mobileEquityDialog, setMobileEquityDialog] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRedirect = () => navigate('/newclientform');

    const openDesktopEquityDialog = () => setDesktopEquityDialog(true);
    const closeDesktopEquityDialog = () => setDesktopEquityDialog(false);

    const openMobileEquityDialog = () => setMobileEquityDialog(true);
    const closeMobileEquityDialog = () => setMobileEquityDialog(false);

    // const scrollToSection = (sectionId) => {
    //     const section = document.getElementById(sectionId);
    //     section && section.scrollIntoView({ behavior: 'smooth' });
    // };

    const plans = [
        {
            "title": "Starter Plan",
            "subtitle": "Ignite Your Business Dream Today",
            "features": [
                "Ideation Session",
                "Product Market Fit Report",
                "Preliminary Business plan",
                "Home Page Design",
                "Launch Plan",
            ],
            "price": "$1K",
            "weeks": "4 weeks",
            "equity": "Equity Stake = 1%",
        },
        {
            "title": "Standard Launch Plan",
            "subtitle": "Transform Your Vision into Reality",
            "features": [
                "Features available in Launch Plans",
                "Idea Validations",
                "Business Modeling",
                "Brand and Identity",
                "Product Development",
                "Marketing and Launch",
            ],
            "price": "$25K",
            "weeks": "15 weeks",
            "equity": "Equity Stake = 5%",
        },
        {
            "title": "Premium Launch Plan",
            "subtitle": "From Groundbreaking to Game-Changing",
            "features": [
                "Features available in Launch Plans",
                "Enhanced Product Dev",
                "Multi-Channel Marketing",
                "Legal, Compliance Advisory",
                "Sales Optimization, CRM",
                "Post-Launch Support",
                "Growth Planning",
            ],
            "price": "$25K",
            "weeks": "18 weeks",
            "equity": "Equity Stake = 10%",
        }
    ];

    const planFeatures = [
        {
            "icon": bulb,
            "title": "Idea Validation and Market Analysis",
            "features": [
                "A thorough assessment of your business idea's viability and market potential.",
                "Detailed market analysis to understand the competitive landscape and customer needs.",
            ],
        },
        {
            "icon": breifcase,
            "title": "Business Model Development",
            "features": [
                "Assistance in creating a robust business model, including revenue streams, cost structure, and value proposition.",
                "Guidance on business registration and legal structure setup.",
            ],
        },
        {
            "icon": copyright,
            "title": "Brand and Identity Creation",
            "features": [
                "Development of a unique brand identity, including logo design and brand guidelines.",
                "Crafting a compelling brand story and messaging for your target audience.",
            ],
        },
        {
            "icon": sitemap,
            "title": "Website and Digital Presence Setup",
            "features": [
                "Design and development of a professional website.",
                "Creation of essential digital assets such as social media profiles.",
            ],
        },
        {
            "icon": trophy,
            "title": "Product Development and MVP (Minimum Viable Product)",
            "features": [
                "Guidance on product development processes, from conceptualization to creating an MVP.",
                "Feedback and iteration strategies to refine the product based on initial user experiences.",
            ],
        },
        {
            "icon": ranking,
            "title": "Marketing and Launch Strategy",
            "features": [
                "Development of a tailored marketing strategy to effectively reach your target audience.",
                "Planning and execution of a launch campaign to generate buzz and initial customer traction.",
            ],
        },
        {
            "icon": moneybill,
            "title": "Financial Planning and Funding Strategies",
            "features": [
                "Assistance with financial planning, including budgeting, cash flow management, and pricing strategies.",
                "Guidance on funding options, including pitching to investors or applying for grants and loans.",
            ],
        },
        {
            "icon": star,
            "title": "Sales and Distribution Channels Establishment",
            "features": [
                "Advice on setting up effective sales processes and distribution channels.",
                "Training on sales techniques and customer relationship management.",
            ],
        },
    ];

    const renderEquityDialog = (
        <>

            <Dialog
                visible={desktopEquityDialog}
                style={{ width: '90%' }}
                onHide={closeDesktopEquityDialog}
                header={
                    <div className="equity-stake-dialog-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',flexWrap:"wrap", backgroundColor: '#352750', padding: '1rem' }}>
                        <img src={startup2LaunchLogo} alt="Startup2Launch Logo" style={{ height: '50px' }} />
                        <h2 style={{ color: 'white' }}>Equity Stake</h2>
                    </div>
                }
                draggable={false}
            >

                <div className="equity-stake-dialog-body">

                    
                    
                    <div className='OurPhilosophy'>
                <h3>&nbsp;Our Philosophy</h3>
                <p>Our decision to require an equity stake alongside the cost of our plans is rooted in a deep-seated belief in the potential of your ideas and a commitment to nurturing them into thriving businesses.</p>
                <p>Here's how this approach encapsulates our positive intent and vision:</p></div>
                <h3><span className="green-dot" />&nbsp;A Testament to Belief and Shared Destiny</h3>
                <p>S2L is the inaugural investor in your vision, marking our unwavering belief in your idea's potential. This equity stake symbolizes our vested interest in your success, establishing a foundation for mutual growth. Our investment goes beyond financial implications; it's an investment in confidence, effort, and resources, ensuring that we are as dedicated to realizing your vision as you are.</p>
                
                <h3><span className="green-dot" />&nbsp;Empowering Entrepreneurs through Subsidized Partnership</h3>
                <p>Our equity stake is not merely a financial arrangement but a strategic effort to make our comprehensive services more accessible. This approach allows us to subsidize the cost of bringing your idea to market, ensuring that financial constraints do not hinder innovation. It's our way of leveling the playing field, offering aspiring entrepreneurs the support and resources they need to succeed.</p>
                
                <h3><span className="green-dot" />&nbsp;Cultivating Excellence Through Ownership</h3>
                <p>By taking an equity stake, S2L intertwines its fate with our clients, instilling a profound sense of ownership and responsibility in our team. This shared stake in the outcome drives us to pursue excellence relentlessly, pushing the boundaries of creativity, strategy, and innovation to secure the best possible results for your business.</p>
                
                <h3><span className="green-dot" />&nbsp;Beyond a Provider: A Partner for the Long Haul</h3>
                <p>Our commitment extends beyond the initial launch; it's a pledge for ongoing partnership and support. The equity stake cements our role as long-term allies invested in your continuous growth and adaptation to market changes. We envision a future where S2L and our clients grow and evolve together, facing challenges and seizing opportunities as a unified force.</p>
                
                <h3><span className="green-dot" />&nbsp;Fostering a Community of Innovation</h3>
                <p>S2L's model fosters a dynamic ecosystem of innovative thinkers, where each success story contributes to a larger narrative of entrepreneurial achievement. Our clients become part of a vibrant community, benefiting from shared experiences, insights, and the collective wisdom of fellow trailblazers. This network effect amplifies the impact of our collaboration, driving industry-wide innovation and progress.</p>
                <p>Together, let's build businesses that succeed and redefine what's possible.</p>
            </div>
            
            </Dialog>

            

            <Dialog
                visible={mobileEquityDialog}

                onHide={closeMobileEquityDialog}
                
                header={
                    <div className="equity-stake-dialog-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap:"wrap", backgroundColor: '#352750', padding: '1rem' }}>

                        <h2 style={{ color: 'white' }}>Equity Stake</h2>
                    </div>
                }
                style={{ overflow: 'auto', maxHeight: '60vh', width:"90%" }}
                draggable={false}
            >


                <div className="equity-content-mobile">
                   
                    <h3>Our Philosophy</h3>
                    <p>Our decision to require an equity stake alongside the cost of our plans is rooted in a deep-seated belief in the potential of your ideas and a commitment to nurturing them into thriving businesses.</p>
                    <p>Here's how this approach encapsulates our positive intent and vision:</p>
                    <ul>
                        <p><GreenDot />A Testament to Belief and Shared Destiny</p>
                        <p><GreenDot />Empowering Entrepreneurs through Subsidized Partnership</p>
                        <p><GreenDot />Cultivating Excellence Through Ownership</p>
                        <p><GreenDot />Beyond a Provider: A Partner for the Long Haul</p>
                        <p><GreenDot />Fostering a Community of Innovation</p>
                    </ul>
                    <p>Together, let's build businesses that succeed and redefine what's possible.</p>
                </div>
            </Dialog>
        </>
    );

    return (
        <>
            <ScrollTop />
            <Header />
            <div className='plans'>
                <div className="plans-header">
                    <img className='womenbanner' src={plansheader} alt="" style={{ width: '100%' }} />
                    <img className='plainbanner' src={plansplainheader} alt="" style={{ width: '100%' }} />
                    <div className="header-text">
                        <div className="p-header-s-text">Your Path to Success</div>
                        <div className="p-header-text">Tailored Plans for Every Entrepreneurial Journey</div>
                        <div className="p-header-subtext">Explore Startup2Launch's customized plans, designed for every stage of your entrepreneurial journey, offering the ideal balance of guidance, expertise, and resources to achieve business success.</div>
                    </div>
                </div>
                <div className='plans-packages flex flex-wrap justify-content-evenly'>
                    {plans.map((plan, index) => (
                        <div className='plan-card' style={{ marginTop: index % 2 === 0 ? '3rem' : '2rem' }} key={index}>
                            <div className='most-picked-border' style={{ backgroundColor: index === 1 ? '#352750' : '#8DAF61' }}>
                                {index === 1 ? 'Most Picked' : ''}
                            </div>
                            <div className='card-content'>
                                <div className='card-header'>{plan.title}</div>
                                <div className='card-subtitle'>{plan.subtitle}</div>
                                <div className='price'>
                                    <div className='plans-price'>{plan.price}</div>
                                    <div>Project Time: {plan.weeks}</div>
                                </div>
                                <div className='equity'>
                                    {plan.equity}
                                     <a onClick={openDesktopEquityDialog} className="desktop-learn-more" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#4453DF', margin: '0 5px' }} > Learn More  </a>
                                    <a onClick={openMobileEquityDialog} className="mobile-learn-more" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#4453DF', margin: '0 5px' }}  > Learn More    </a>
                                </div>
                                <div className='includes'>Includes:</div>
                                <div className='features'>
                                    {plan.features.map((feature, index) => (
                                        <div key={index} className='flex gap-2 plan-item'>
                                            <FaCheck style={{ color: '#8DAF61' }} />
                                            {feature}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='plan-button'>
                    <Button onClick={handleRedirect}>Start Your Journey Today</Button>
                </div>
                <div className='p-sub-banner'>
                    <div id="section1" className='p-f-header'>Features available in Launch Plans</div>
                </div>
                <div className='plan-features flex flex-wrap justify-content-center'>
                    {planFeatures.map((feature, index) => (
                        <div key={index} className='feature-plan-card'>
                            <div className='fcard-content'>
                                <div className='feature-card-header'>
                                    <img src={feature.icon} alt="icon" style={{ width: "50px", height: "50px", marginRight: "12px" }} />
                                    <div className='fcard-header'>{feature.title}</div>
                                </div>
                                <ul className='feature-list'>
                                    {feature.features.map((feat, idx) => (
                                        <li key={idx} className="feature-item">
                                            {feat}
                                        </li>
                                    ))}
                               
                               </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {renderEquityDialog}
            <Footer />
        </>
    );
};

export default Plans;
