import React from 'react';
import { ScrollTop } from 'primereact/scrolltop';
import Header from '../../../Home/Header/Header';
import StudentDetails from '../StudentDetails/Studentdetails';
import StudentBanner from '../StudentBanner/StudentBanner';
import StudentWorkshopDetails from '../StudentWorkshopDetails/StudentWorkshopDetails';
import Footer from '../../../Home/Footer/Footer-WhiteBackground';

const Home = () => {

    return (
        <div>
            <ScrollTop />
            <Header/>
            <StudentBanner/>
            <StudentDetails/>
            <StudentWorkshopDetails/>
            <Footer/>
        </div>
    )
}

export default Home