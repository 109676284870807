import React, { useState, useEffect } from 'react';
import './AdBanner.scss';
import { Button } from 'primereact/button';
import { FaClock, FaLocationDot } from 'react-icons/fa6';
import { FaCalendar } from 'react-icons/fa';

const content = [
    {
        heading: "Startup Foundations for teens: Your path to Entrepreneurship",
        description: "When you list 'Founder' on your college application, you're not just another student.",
        place: "3030 Broadmoor Ln, Flower Mound, TX 75022",
        date: "September 8th, Sunday",
        time: "2pm - 4pm CDT",
        eventbriteLink: "https://www.eventbrite.com/e/startup-foundations-your-path-to-entrepreneurship-for-teens-tickets-995550286707?aff=S2LWebsite",
        workshop: "September 8 - Free Workshop at a Library in Flower Mound, TX",
    },
    {
        heading: "Startup Foundations for teens: Your path to Entrepreneurship",
        description: "When you list 'Founder' on your college application, you're not just another student.",
        place: "7501 Independence Parkway Plano, TX 75025",
        date: "Saturday, September 14",
        time: "2 - 4pm CDT",
        eventbriteLink: "https://www.eventbrite.com/e/free-in-personstartup-foundations-for-teens-your-path-to-entrepreneurship-tickets-998875001017?aff=S2Lwebsite",
        workshop: "September 14 - Free Workshop at a Library in Plano, TX",
    },
];

const AdBanner = () => {
    const [showAd, setShowAd] = useState(true);
    const [countdown, setCountdown] = useState(15);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAd(false);
        }, 15000);

        const countdownTimer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(countdownTimer);
        }, 15000);

        return () => {
            clearTimeout(timer);
            clearInterval(countdownTimer);
        };
    }, []);

    return (
        <>
            {showAd ? (
                <div className='AdSection'>
                    {countdown > 0 && (
                        <div className="countdown">{countdown} seconds</div>
                    )}

                    <div className="AdSection-Banner">
                        <h2 className="reg-header">Register to our Upcoming Event</h2>
                        <p className="reg-content">"Join us for a one-day free workshop this September—pick from two convenient locations!"</p>

                        <div className="ad-banner-sections p-0 col">
                            {content.map((event, index) => (
                                <div
                                    key={index}
                                    className="ad-banner-content gap-4 justify-content-center align-items-center col-6"
                                >
                                    <h2 className="text text-center text-white">{event.workshop}</h2>

                                    <p className="heading">{event.heading}</p>

                                    <p className="text">
                                        {event.description}
                                    </p>

                                    <div className='event-details'>
                                        <span><FaLocationDot /> {event.place} </span>
                                        <span><FaCalendar /> {event.date} &nbsp; <FaClock /> {event.time}</span>
                                    </div>

                                    <div className="text-center adSection-btn">
                                        <Button className="border-round-sm">
                                            <a href={event.eventbriteLink} target="_blank" rel="noreferrer">
                                                Register Here
                                            </a>
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            ) : (
                <div className="adSection-hide">
                    <h2>Register to our Upcoming Event</h2>
                    <p>"Join us for a one-day free workshop this September—pick from two convenient locations!"</p>

                    <div className="ad-new-banner-section p-0 col" >
                        {content.map((event, index) => (
                            <div
                                key={index}
                                className="ad-banner-content gap-4 justify-content-center align-items-center col-6"
                                style={{ background: "#132F96", padding: "10px 30px 30px", border: "2px solid #FFFFFF" }}
                            >
                                <h2 className="text text-center text-white">{event.workshop}</h2>

                                <div className='text-center'>
                                    <Button className='button-style-white-secondary border-round-sm'>
                                        <a href={event.eventbriteLink} target='_blank' rel="noreferrer">Register Here</a>
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {window.innerWidth <= 887 && (
                <>
                    {showAd && (
                        <div className="new">
                            {countdown > 0 && (
                                <div className="countdown">{countdown} seconds</div>
                            )}

                            <div className='AdSection-mobile'>
                                <div className="AdSection-Banner">
                                    <h2 className="reg-header">Register to our Upcoming Event</h2>
                                    <p className="reg-content">"Join us for a one-day free workshop this September—pick from two convenient locations!"</p>

                                    <div className="ad-banner-sections p-3 col">
                                        {content.map((event, index) => (
                                            <div
                                                key={index}
                                                className="ad-banner-content gap-4 justify-content-center align-items-center col-6"
                                            >
                                                <h2 className="text text-center text-white">{event.workshop}</h2>

                                                <p className="heading">{event.heading}</p>

                                                <p className="text">{event.description}</p>

                                                <div className='event-details'>
                                                    <span><FaLocationDot /> {event.place} </span>
                                                    <span><FaCalendar /> {event.date}</span>
                                                    <span><FaClock /> {event.time}</span>
                                                </div>

                                                <div className="text-center adSection-btn">
                                                    <Button className="border-round-sm">
                                                        <a href={event.eventbriteLink} target="_blank" rel="noreferrer">
                                                            Register Here
                                                        </a>
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default AdBanner;