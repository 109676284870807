import React, { useState } from "react";
import '../Our-Project-Section/ProjectSection.scss';
import Header from "../../Home/Header/Header";
import Footer from "../../Home/Footer/Footer";
import { Button } from "primereact/button";
import AutismLogo from '../../../Assets/Our-Work/Logo_ACC_Blue_x4.png';
import quote from '../../../Assets/Our-Work/quote-left-solid.svg';
import { FaChevronLeft } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionTab } from "primereact/accordion";


const initialTestimonialsData = [
    {
        name: 'Vicky M',
        role: 'Parent',
        description: 'I am very pleased and happy with services from Dr.Sunkara and his staff. He has helped with my three year old son with severe ADHD. ',
    },
    // {
    //     name: 'Dinah S',
    //     role: '-',
    //     description: 'I am very pleased and happy with services from Dr.Sunkara and his staff. He has helped with my three year old son with severe ADHD. ',
    // },
    {
        name: 'Steve K',
        role: 'Parent',
        description: 'Dr. Sunkara is awesome! Awesome Doc. He is vary caring and really knows his stuff.',
    },
];

const AutismProject = () => {

    const [activeSection, setActiveSection] = useState("about");
    const [testimonials, setTestimonials] = useState(initialTestimonialsData);


    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            setActiveSection(id);
        }
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };


    const projects = [
        {
            title: 'General Information',
            content: (
                <div className="m-5 autism-img-details">
                    <div className="autism-content-section p-3">
                        <div className="flex align-items-center justify-content-between autismlogo-sec">
                            <div className="flex align-items-center justify-content-start gap-5 autismlogo-sec">
                                <div className="flex align-items-center justify-content-center">
                                <img src={AutismLogo} alt='AutismLogo' className="autismlogo" />
                                </div>
                                <div>
                                    <div className="heading">Autism Complete Care Project</div>
                                    <span>2023</span>
                                </div>
                            </div>
                            <div>
                               <a href="https://www.autismcompletecare.com/" target="_/blank" className="flex align-items-center justify-content-center"> <Button>Visit the site </Button></a>
                            </div>
                        </div>
                        <div>
                            <p>Autism Complete Care started with a team of experts in behavior analysis, psychiatry, and technologists who wanted to change how autism therapy is done. They saw that every child is special and that therapy should be made just for them. They made a clinic that understands each child’s needs, includes expert mental health advice, and works closely with families in the therapy journey.
We began in a small, friendly place, but with a big dedication to our values and the families we help. Our story is about caring deeply, never giving up, and believing in the bright future of every child with autism.</p>
                            <div className="flex align-items-center justify-content-start flex-wrap gap-5">
                                <Button className="autism-button">Education </Button>
                                <Button className="autism-button">Autism </Button>
                                <Button className="autism-button">Child Autism </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'About',
            content: (
                <div id="about" className="about-details">
                    <div className="m-5">
                        <p>We envision a world where all children on the autism spectrum receive the support, respect, and opportunities they deserve. To be a beacon of hope and a source of support for families, setting the standard for excellence in autism care through innovative therapies, Psychiatric consultation, compassionate support, and unwavering commitment to the children we serve. At Autism Complete Care, we believe families are integral to the therapeutic process. We are committed to providing therapy for children and support, education, and resources for the whole family. We strive to build strong,
                            collaborative relationships with parents and caregivers, empowering them to advocate for their children within our clinic and in the wider community.</p>
                    </div>
                </div>
            )
        },
        {
            title: 'Technologies',
            content: (
                <div id="technologies" className="technologies-details">
                    <div className="m-5">
                        <span>Here’s a concise summary of the technologies and methodologies employed:</span>
                        <ul>
                            <li>Design Thinking Methodologies: A user-centric (patients and Parents) approach to product development.</li>
                            <li>AWS Cloud Technologies: Provides a robust and scalable infrastructure.</li>
                            <li>React for Front-End: Delivers a dynamic and responsive user interface across devices for parents.</li>
                            <li>API Ecosystem: Facilitates connectivity and integration of various services and data, different third party systems and EHR.</li>
                            <li>Java Framework for Back-End: Offers a secure and robust server-side solution.</li>
                            <li>Scheduling Module: Manages class schedules, appointments, and resources efficiently.</li>
                            <li>Report Generating: Automates the creation of insightful and structured reports.</li>
                        </ul>
                    </div>
                </div>
            )
        },
        {
            title: 'Frameworks',
            content: (
                <div id="frameworksUsed" className="frameworks-details">
                    <div className="m-5">
                        <span>Here’s a concise summary of the technologies and methodologies employed:</span>
                        <ul>
                            <li>Design Thinking Methodologies: A user-centric (patients and Parents) approach to product development.</li>
                            <li>AWS Cloud Technologies: Provides a robust and scalable infrastructure.</li>
                            <li>React for Front-End: Delivers a dynamic and responsive user interface across devices for parents.</li>
                            <li>API Ecosystem: Facilitates connectivity and integration of various services and data, different third party systems and EHR.</li>
                            <li>Java Framework for Back-End: Offers a secure and robust server-side solution.</li>
                            <li>Scheduling Module: Manages class schedules, appointments, and resources efficiently.</li>
                            <li>Report Generating: Automates the creation of insightful and structured reports.</li>
                        </ul>
                    </div>
                </div>
            )
        },
        {
            title: 'Testimonials',
            content: (
                <div id="testimonials" className="testimonials-details">
                    <div className="m-5">
                        <div className="testimonal-section grid">
                            {testimonials.map(testimonial => (
                                <div className="testimonial-card col-11 md:col-11 lg:col-5 xl:col-5">
                                    <div className='testimonial'>
                                        <div className="testimonial-names">
                                            <div className='tuser-name'>{testimonial.name}</div>
                                            <span>{testimonial.role}</span>
                                        </div>
                                        <div><img className='quote-image' src={quote} alt="" /></div>
                                    </div>
                                    <div className='tuser-desc'>{testimonial.description}</div>
                                    <div className='rating'>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-gray' /></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        },
    ];

    return (
        <>
            <Header />
            <div className="autism-project-section">
                <div className="back m-5">
                    <Button className='back-button'><a href="/investor"> <FaChevronLeft /> Back</a></Button>
                </div>
                <div className="m-5 autism-img-details">
                    <div className="autism-content-section p-3">
                        <div className="flex align-items-center justify-content-between">
                            <div className="flex align-items-center justify-content-start gap-5">
                                <img src={AutismLogo} alt='AutismLogo' className="autismlogo" />
                                <div>
                                    <div>Autism Complete Care Project</div>
                                    <span>2023</span>
                                </div>
                            </div>
                            <div>
                            <a href="https://www.autismcompletecare.com/" target="_/blank"><Button>Visit the site </Button></a>
                            </div>
                        </div>
                        <div>
                            <p>Laulama school is working to innovate education’s underlying improvement system for breakthroughs that transform schools for their leaders, educators, and students. Laulama Learning and Laulama Schools result from a 13-year venture focused on impacting education in Hawaii. This venture started
                                in 2010 with the formation of an organizational transformation practice called Optimum Business Solutions.</p>
                            <div className="flex align-items-center justify-content-start gap-5">
                                <Button className="autism-button">Education </Button>
                                <Button className="autism-button">Autism </Button>
                                <Button className="autism-button">Child Autism </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  flex-wrap m-5 autism-main-details">
                    <div className="col-3 autism-sub-headings">
                        <p
                            className={activeSection === "about" ? "active" : ""}
                            onClick={() => scrollToSection("about")}
                            style={{ backgroundColor: activeSection === "about" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "about" ? '#fff' : '#000' }}
                        >
                            About
                        </p>
                        <p
                            className={activeSection === "technologies" ? "active" : ""}
                            onClick={() => scrollToSection("technologies")}
                            style={{ backgroundColor: activeSection === "technologies" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "technologies" ? '#fff' : '#000' }}
                        >
                            Technologies
                        </p>
                        <p
                            className={activeSection === "frameworksUsed" ? "active" : ""}
                            onClick={() => scrollToSection("frameworksUsed")}
                            style={{ backgroundColor: activeSection === "frameworksUsed" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "frameworksUsed" ? '#fff' : '#000' }}
                        >
                            Frameworks
                        </p>
                        <p
                            className={activeSection === "testimonials" ? "active" : ""}
                            onClick={() => scrollToSection("testimonials")}
                            style={{ backgroundColor: activeSection === "testimonials" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "testimonials" ? '#fff' : '#000' }}
                        >
                            Testimonials
                        </p>
                    </div>
                    <div className="col-9 autism-sub-paragraphs">
                        <div id="about">
                            <>
                                <h3>About</h3>
                                <p>We envision a world where all children on the autism spectrum receive the support, respect, and opportunities they deserve. To be a beacon of hope and a source of support for families, setting the standard for excellence in autism care through innovative therapies, Psychiatric consultation, compassionate support, and unwavering commitment to the children we serve. At Autism Complete Care, we believe families are integral to the therapeutic process. We are committed to providing therapy for children and support, education, and resources for the whole family. We strive to build strong,
                                    collaborative relationships with parents and caregivers, empowering them to advocate for their children within our clinic and in the wider community.</p>
                            </>
                        </div>
                        <div id="technologies">
                            <>
                                <h3>Technologies</h3>
                                <span>Here’s a concise summary of the technologies and methodologies employed:</span>
                                <ul>
                                    <li>Design Thinking Methodologies: A user-centric (patients and Parents) approach to product development.</li>
                                    <li>AWS Cloud Technologies: Provides a robust and scalable infrastructure.</li>
                                    <li>React for Front-End: Delivers a dynamic and responsive user interface across devices for parents.</li>
                                    <li>API Ecosystem: Facilitates connectivity and integration of various services and data, different third party systems and EHR.</li>
                                    <li>Java Framework for Back-End: Offers a secure and robust server-side solution.</li>
                                    <li>Scheduling Module: Manages class schedules, appointments, and resources efficiently.</li>
                                    <li>Report Generating: Automates the creation of insightful and structured reports.</li>
                                </ul>
                            </>
                        </div>
                        <div id="frameworksUsed">
                            <>
                                <h3>Frameworks</h3>
                                <span>Here’s a concise summary of the technologies and methodologies employed:</span>
                                <ul>
                                    <li>Design Thinking Methodologies: A user-centric (patients and Parents) approach to product development.</li>
                                    <li>AWS Cloud Technologies: Provides a robust and scalable infrastructure.</li>
                                    <li>React for Front-End: Delivers a dynamic and responsive user interface across devices for parents.</li>
                                    <li>API Ecosystem: Facilitates connectivity and integration of various services and data, different third party systems and EHR.</li>
                                    <li>Java Framework for Back-End: Offers a secure and robust server-side solution.</li>
                                    <li>Scheduling Module: Manages class schedules, appointments, and resources efficiently.</li>
                                    <li>Report Generating: Automates the creation of insightful and structured reports.</li>
                                </ul>
                            </>
                        </div>
                        <div id="testimonials">
                            <>
                                <h3>Testimonials</h3>
                                <div className="testimonal-section grid">
                                    {testimonials.map(testimonial => (
                                        <div className="testimonial-card col-12 md:col-12 lg:col-5 xl:col-5">
                                            <div className='testimonial'>
                                                <div className="testimonial-names">
                                                    <div className='tuser-name'>{testimonial.name}</div>
                                                    <span>{testimonial.role}</span>
                                                </div>
                                                <div><img className='quote-image' src={quote} alt="" /></div>
                                            </div>
                                            <div className='tuser-desc'>{testimonial.description}</div>
                                            <div className='rating'>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-gray' /></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mobile-autism-project-section'>
                <div className="back m-5">
                    <Button className='back-button'><a href="/investor"> <FaChevronLeft /> Back</a></Button>
                </div>
                <Accordion>
                    {projects.map((project, index) => (
                        <AccordionTab
                            key={index}
                            header={
                                <div
                                    className={`accordion-header ${activeIndex === index ? 'active-tab' : ''}`}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    {project.title}
                                    <FontAwesomeIcon
                                        icon={activeIndex === index ? faMinus : faPlus}
                                        className="accordion-icon"
                                    />
                                </div>
                            }
                            contentClassName={activeIndex === index ? 'active-content' : ''}
                        >
                            {project.content}
                        </AccordionTab>
                    ))}
                </Accordion>
            </div>
            <Footer />
        </>

    );
};

export default AutismProject;
