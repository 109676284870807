import React, { useState } from 'react';
import axios from 'axios';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChatOption = () => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSendMessage = async () => {
        setMessages([...messages, { role: 'user', content: input }]);
        setInput('');
        const response = await axios
            .post('https://api.openai.com/v1/chat/completions',
                {
                    temperature: 0.45,
                    max_tokens: 150,
                    top_p: 1,
                    frequency_penalty: 0.0,
                    presence_penalty: 0.0,
                    model: 'gpt-3.5-turbo-1106',
                    messages: [
                        {
                            role: 'system',
                            content: generatePrompt()
                        },
                        {
                            role: 'user',
                            content: input
                        },
                    ],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer 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`,
                    },
                }
            );

        setMessages((prevMessages) => [
            ...prevMessages,
            { role: 'assistant', content: response.data.choices[0].message.content },
        ]);
        setInput('');
    };

    function generatePrompt() {
        return `
            Summarize the information below and answer the question within 50 words or less.
            Answer the question as truthfully as possible, and if you're unsure of the answer, say "Sorry, I don't know the answer to this question. Please contact support emailing  info@startup2launch.com or by calling us at +1 (469) 271-9376".
            Do not answer anything that does not relate to Startuo2Launch and it's activities.
            your name startup2Launch Robort
            
            Startup 2 Launch Blog Content

            Master Entrepreneurship, Excel High School and Ace College Admissions

            Entrepreneurship With Internship

            3030 Broadmoor Ln, Flower Mound, TX 75022

            July 27th, Saturday  11:30 AM to 2:30 PM

            Unlock Your Potential! Join our exclusive 3-hours workshop to explore innovative skills that propel you from classroom to career success. Perfect for high school students eager to lead and innovate. Sign up now and start your journey!
            
            FREE Event for High School students aged 13-18
            It's a great way to meet like-minded peers and gain insights from industry mentors!
            This event would be Perfect for:
            Students looking to enhance their college applications, secure internships, and those aspiring to join Ivy League schools. particularly those involved in DECA or who have an amazing business idea they're eager to launch.
            
            This 3-hours workshop will empower teens with essential skills:

            Innovative Thinking: Learn to think like an entrepreneur with First Principles Thinking.
            Academic Excellence: Boost grades with proven academic strategies.
            Effective Time Management: Master the art of prioritizing tasks.
            Business Launchpad: Steps to start and run a successful business.
            
            Date: Saturday, June 8, 2024 
            
            Time: 10:30 AM - 1:30 PM CDT 
            
            Location: Haggard Library, Plano, TX
            
            For more details 
            
            https://www.eventbrite.com/e/free-in-person-event-entrepreneur-masterclass-for-middle-high-schoolers-tickets-944736019867?aff=S2Lwebsite

            Blog 1: "The Entrepreneurial Mindset: Key to Becoming an Entrepreneur"
            
            Title: The Entrepreneurial Mindset: Key to Becoming an Entrepreneur
            
            Intro: Becoming an entrepreneur is an exciting journey, but it's not just about launching a business; it's about embracing a mindset that sets you on the path to success. In this blog, we'll explore the essential elements of the entrepreneurial mindset and how they play a crucial role in your journey to entrepreneurship.
            
            1. Embracing Risk and Resilience
            
            Becoming an entrepreneur often means taking calculated risks. The ability to embrace uncertainty and pivot when necessary is a hallmark of successful entrepreneurs. We'll discuss strategies for managing risks and building resilience in the face of challenges.
            
            2. Passion and Vision
            
            Your passion and vision are the driving forces behind your entrepreneurial endeavors. We'll delve into how to identify your passions, develop a clear vision for your business, and translate that vision into a successful venture.
            
            3. Adaptability and Continuous Learning
            
            The business landscape is ever-evolving. Entrepreneurs must be adaptable and committed to lifelong learning. We'll explore the importance of staying up-to-date with industry trends and continuously improving your skills.
            
            4. Building a Network and Seeking Mentorship
            
            Networking and mentorship are invaluable resources for budding entrepreneurs. We'll offer tips on building a support network and seeking guidance from experienced mentors to help you on your journey.
            
            5. Financial Savvy
            
            Managing finances is a critical aspect of entrepreneurship. We'll provide insights into financial management, budgeting, and seeking investment opportunities.
            
            Conclusion:
            
            Developing the right mindset is the first step in becoming an entrepreneur. By embracing risk, nurturing your passions, staying adaptable, and building a strong network, you'll be well on your way to a successful entrepreneurial journey.
            
            ---
            
            Blog 2: "From Idea to Business: The Step-by-Step Guide to Launching Your Startup"
            
            Title: From Idea to Business: The Step-by-Step Guide to Launching Your Startup
            
            Intro: You've got a brilliant business idea, and now it's time to turn that idea into a fully-fledged startup. In this blog, we'll walk you through the essential steps to take your concept and transform it into a successful business venture.
            
            1. Market Research and Validation
            
            Begin by conducting thorough market research to validate your idea. We'll explore the importance of understanding your target audience, competitors, and identifying potential gaps in the market.
            
            2. Business Plan Development
            
            A well-crafted business plan is the roadmap to your entrepreneurial success. We'll guide you through creating a comprehensive plan that outlines your goals, strategies, and financial projections.
            
            3. Legal and Financial Considerations
            
            Setting up your business legally and financially is crucial. We'll discuss choosing a business structure, registering your business, and securing funding.
            
            4. Product Development and Prototyping
            
            If your startup involves a product, this step is vital. Learn how to develop prototypes and test your product to ensure it meets market needs.
            
            5. Marketing and Branding
            
            Building a strong brand and effective marketing strategy are key to attracting customers. We'll provide insights into branding, digital marketing, and creating a compelling online presence.
            
            6. Launching and Scaling
            
            Finally, it's time to launch your startup. We'll discuss strategies for a successful launch and how to scale your business as it grows.
            
            Conclusion:
            
            Turning your idea into a successful startup requires careful planning and execution. Follow these steps, and you'll be well on your way to bringing your entrepreneurial vision to life.
            
            ---
            
            Blog 3: "Overcoming Common Challenges on the Path to Entrepreneurship"
            
            Title: Overcoming Common Challenges on the Path to Entrepreneurship
            
            Intro: Becoming an entrepreneur is a rewarding journey, but it's not without its challenges. In this blog, we'll address some of the common obstacles that entrepreneurs face and provide strategies for overcoming them.
            
            1. Fear of Failure
            
            The fear of failure can paralyze even the most ambitious entrepreneurs. We'll discuss ways to overcome this fear, including reframing failure as a learning opportunity.
            
            2. Time Management and Work-Life Balance
            
            Balancing the demands of entrepreneurship and personal life can be tough. We'll share time management techniques and tips for maintaining a healthy work-life balance.
            
            3. Funding and Financial Challenges
            
            Money matters are a constant concern for entrepreneurs. Learn how to secure funding, manage cash flow, and navigate financial challenges.
            
            4. Team Building and Management
            
            Building a strong team is essential for your startup's success. We'll provide insights into hiring, managing, and motivating your team.
            
            5. Marketing and Sales Struggles
            
            Attracting customers and closing sales can be challenging. We'll explore strategies for effective marketing and sales techniques.
            
            6. Adapting to Industry Changes
            
            Industries are always evolving. Discover how to stay ahead of the curve by adapting to changing market dynamics.
            
            Conclusion:
            
            Entrepreneurship is a journey filled with challenges, but with the right mindset and strategies, you can overcome these obstacles and achieve your entrepreneurial goals. Keep these tips in mind as you navigate the path to becoming a successful entrepreneur.
            
            Title: The Importance of Entrepreneurship: Navigating the Path to Success
            
            I. Introduction A. Definition of entrepreneurship B. The dynamic role of entrepreneurs in the economy C. Preview of the blog's key points
            
            II. The Economic Significance of Entrepreneurship A. Job creation and economic growth B. Innovation and technological advancements C. Market competition and consumer choice
            
            III. Personal Fulfillment and Independence A. Pursuing one's passion and vision B. Freedom and flexibility in decision-making C. Escaping the 9-to-5 routine
            
            IV. The Impact on Local Communities A. Strengthening local economies B. Building community resilience C. Encouraging others to take the entrepreneurial leap
            
            V. Challenges and Risks A. Identifying common obstacles faced by entrepreneurs B. Mitigating risks through effective planning and strategy C. Realistic expectations and the role of failure
            
            VI. Entrepreneurship in the Digital Age A. Leveraging technology for business growth B. Online marketing and e-commerce opportunities C. The importance of adaptability in a fast-changing landscape
            
            VII. Steps to Becoming an Entrepreneur A. Identifying your passion and strengths B. Creating a business plan C. Securing financing and resources D. Building a strong network and support system
            
            VIII. Inspirational Success Stories A. Highlighting renowned entrepreneurs B. Discussing their journeys, challenges, and achievements C. Lessons that aspiring entrepreneurs can learn from their experiences
            
            IX. The Role of Education and Training A. The value of entrepreneurship education B. Skill development and continuous learning C. Resources and programs for aspiring entrepreneurs
            
            X. Conclusion A. Recap of the importance of entrepreneurship B. Encouragement for readers to explore entrepreneurial opportunities C. A call to action for sharing the blog with others interested in entrepreneurship
            
            Title: MedTech Revolution: Pioneering Healthcare in the Digital Age
            
            ---
      
            Introduction:
            
            In today's rapidly evolving world, technological advancements have touched every facet of our lives. Healthcare is no exception, and one of the most revolutionary developments in recent years is MedTech, a term used to describe the intersection of medicine and technology. MedTech is not just about fancy gadgets; it's about transforming healthcare, saving lives, and making medical services more accessible. In this blog, we'll delve into the importance of MedTech in today's world and how it's reshaping the healthcare landscape.
            
            ---
            
            The Expanding Reach of MedTech:
            
            1. Remote Patient Monitoring:
            
            o MedTech solutions like wearable devices and telemedicine platforms allow patients to monitor their health from the comfort of their homes.
            
            o Real-time data collection helps in early detection and timely intervention, reducing hospital admissions.
            
            2. Precision Medicine:
            
            o Genetic testing and AI-driven data analysis enable personalized treatment plans based on an individual's unique genetic makeup.
            
            o This approach minimizes side effects and increases treatment efficacy.
            
            3. Streamlining Healthcare Operations:
            
            o Electronic Health Records (EHRs) and administrative software simplify record-keeping and reduce administrative burdens on healthcare providers.
            
            o Improved efficiency leads to better patient care.
            
            ---
            
            MedTech and the Fight Against Pandemics:
            
            1. Diagnosis and Testing:
            
            o Rapid diagnostic tests, such as PCR machines, have played a crucial role in detecting infectious diseases like COVID-19.
            
            o MedTech has enabled the development of home testing kits, making testing widely accessible.
            
            2. Vaccine Development:
            
            o Advanced biotechnology and data analysis have accelerated vaccine development.
            
            o MedTech tools help in tracking vaccine distribution and adverse effects.
            
            3. Telemedicine for Triage:
            
            o Telehealth platforms have been instrumental in providing medical guidance, reducing hospital overcrowding, and ensuring safer consultations during pandemics.
            
            ---
            
            Enhanced Patient Experience:
            
            1. Increased Access to Healthcare:
            
            o Telemedicine breaks down geographical barriers, providing healthcare services to remote and underserved areas.
            
            o Patients can connect with specialists without the need for long-distance travel.
            
            2. Empowerment Through Information:
            
            o MedTech equips patients with information and tools to better manage their health.
            
            o Apps and wearables track vitals and provide reminders for medications and appointments.
            
            3. Reducing Medical Errors:
            
            o Decision support systems and AI-assisted diagnostics help healthcare professionals make more accurate diagnoses and treatment decisions.
            
            ---
        
            Challenges and Considerations:
            
            1. Data Security and Privacy:
            
            o Protecting patient data is a paramount concern.
            
            o Stricter regulations and secure data storage are essential for maintaining trust in MedTech.
            
            2. Equity in Access:
            
            o Ensuring that the benefits of MedTech are accessible to all, regardless of socioeconomic status, is a challenge that needs addressing.
            
            3. Healthcare Professional Training:
            
            o Healthcare providers need ongoing training to keep up with the rapidly evolving technology.
            
            ---
            
            Conclusion:
            
            MedTech is not just a buzzword; it's the future of healthcare. Its importance in today's world cannot be overstated. From enhancing patient experiences and fighting pandemics to revolutionizing the way healthcare is delivered, MedTech is changing lives for the better. As technology continues to advance, it's essential to harness its power responsibly, ensuring that everyone, regardless of their background, can benefit from the healthcare innovations of the digital age. Embrace the MedTech revolution, and be part of a healthier, more connected world
                
            General FAQs Questions 
                Question 1. How do I know if my business idea is viable?
                Answer: To determine the viability of your business idea, conduct a comprehensive market analysis that includes identifying your target audience, understanding market needs, analyzing competitors, and evaluating market trends. Our Ideation Session and Product Market Fit Analysis can provide valuable insights into the potential success of your idea.
                
                Question 2. What are the first steps to launching my startup?
                Answer: The first steps include defining your business idea, conducting market research, creating a business plan, securing funding, and developing your product or service. Our Starter Plan is designed to help new entrepreneurs navigate these initial phases with expert guidance.
                
                Question 3. How can I protect my intellectual property?
                Answer: Protecting your intellectual property (IP) early on is crucial. Consider filing for patents, trademarks, or copyrights as applicable. Our Legal Services include Intellectual Property Protection guidance to safeguard your innovations.
                
                Question 4. What financial planning steps should I take for my startup?
                Answer: Start with creating a detailed budget, forecasting your financial needs, and planning for funding. Our Financial Planning Services offer assistance in budgeting, forecasting, investment strategy development, and securing capital to ensure financial stability.
                Plan-Specific FAQs
                Question 5. What does the Starter Plan include, and who is it for?
                Answer: The Starter Plan is ideal for new entrepreneurs. It includes an Ideation Session, Product Market Fit Analysis, Preliminary Business Plan, Portal Design, and Pitch Deck. This plan is perfect for those at the conceptual stage who must validate their idea and plan the first steps.
                
                Question 6. What additional benefits does the Standard Launch Plan offer?
                Answer: The Standard Launch Plan is designed for entrepreneurs ready to launch. It includes Idea Validation Study, Business Modelling, Branding and Identity, Product Development, and Marketing and Launch strategies. This plan suits those who have validated their idea and want to develop and market their product.
                
                Question 7. How does the Premium Launch Plan support post-launch growth?
                Answer: The Premium Launch Plan offers comprehensive full-scale launch and growth support, including Enhanced Product Development, Multi-Channel Marketing, Legal and Compliance Advisory, Sales Optimization, CRM, and Post-Launch Support. This plan is best for entrepreneurs seeking extensive support for scaling their businesses.
                
                
                Post-Launch FAQs
                Question 8. How can I expand my market reach after launching?
                Answer: Market expansion strategies may include diversifying your product line, exploring new markets, enhancing marketing efforts, and leveraging customer feedback for product improvements. Our Post Launch Services can guide you through these strategies to grow your business.
                
                Question 9. What should I do if I receive negative customer feedback?
                Answer: Treat negative feedback as an opportunity to improve. Analyze the feedback, identify areas for improvement, and make necessary adjustments to your product or service. Our Customer Feedback Analysis service can help you turn feedback into actionable insights.
                
                Question 10. How often should I update my business plan?
                Answer: Your business plan should be a living document, updated regularly to reflect market changes, business goals, and financial projections. We recommend reviewing and updating your plan at least annually or as major changes occur in your business or industry.
                
                Strategy and Planning Services FAQs
                Question 11. How can S2L help with my business strategy and planning?
                Answer: S2L offers comprehensive services, including detailed market analysis, scalable business modeling, complete financial planning, cohesive branding strategy, and successful launch planning. We help you create a robust foundation for your business, ensuring it's geared for success from the start.
                
                Question 12. What is included in the market analysis service?
                Answer: Our market analysis service provides in-depth research into your target market, competition, trends, and customer needs. This service is designed to identify business opportunities and inform strategic decisions.
                
                Question 13. How does S2L approach business modeling?
                Answer: S2L creates scalable business models tailored to your specific industry and growth goals. Our approach includes evaluating revenue streams, cost structures, customer segments, and value propositions to build a model that supports sustainable growth.
                UX & UI Services FAQs
                Question 14. Why are UX and UI important for my startup?
                Answer: User Experience (UX) and User Interface (UI) are crucial for ensuring your product or service meets the needs and expectations of your users. Good UX/UI design enhances user satisfaction, improves usability, and increases user engagement, directly impacting your business's success.

                Question 15. What does the UX research process involve?
                Answer: Our UX research involves studying your target users’ behaviors, needs, and challenges. This includes methods like user interviews, surveys, usability testing, and persona development to inform the design and development of your product.
                
                Marketing Services FAQs
                Question 16. How can S2L help with my startup's digital marketing strategy?
                Answer: S2L provides comprehensive digital marketing strategies tailored to your startup's goals. This includes content creation, SEO and SEM, social media management, and analytics and reporting to increase your online presence, engage with your target audience, and drive growth.
                
                Question 17. What is SEO, and why is it important?
                Answer: SEO (Search Engine Optimization) is optimizing your website and content to rank higher in search engine results pages (SERPs). It's crucial for increasing visibility, attracting organic traffic, and enhancing credibility in your industry.
                Technology Services FAQs
                Question 18. Can S2L assist with mobile app development?
                Answer: Yes, S2L offers mobile app development services tailored to your specific needs. Whether you're looking to create a new app or improve an existing one, our team of experts will ensure it's user-friendly, functional, and aligned with your business goals.
                
                Question 19. What cybersecurity measures does S2L recommend?
                Answer: S2L recommends a comprehensive cybersecurity strategy, including regular security audits, data encryption, secure access controls, and employee training on best practices. Our cybersecurity services are designed to protect your business from cyber threats and data breaches.
                Financial Planning Services FAQs
                Question 20. How does S2L assist with financial planning and analysis?
                Answer: Our financial planning and analysis services include creating detailed budgets, forecasting financial needs, developing investment strategies, and assisting with funding and capital raising. We help ensure your financial stability and growth potential.
                Legal Services FAQs
                Question 21. What legal services does S2L provide for startups?
                Answer: S2L offers various legal services, including startup incorporation and structure, intellectual property protection, contract review and negotiation, compliance and regulatory advice, dispute resolution, and litigation support to safeguard your business and its interests.
                
                Post Launch Services FAQs
                Question 22. How does S2L support startups after launch?
                Answer: Post-launch, S2L provides market expansion strategies, customer feedback analysis, ongoing marketing support, product iteration and enhancement, and continued technical support. Our goal is to ensure your startup's continued growth and success.
                `;
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <div>
            <div className="aryanow-project-section-payment">
                <div className="payment-section">
                    <div className="chatbot-container">
                        <div className="chatbot">
                            <div className="chatbot-header">
                                <h3>Demo Team</h3>
                            </div>

                            <div className="chatbot-body">
                                <div className="chatbot-message">
                                    <div className="chat-history">
                                        {messages.slice().reverse().map((message, index) => (
                                            <div key={index} className={`message ${message.role}`}>
                                                {message.content}
                                            </div>
                                        ))}
                                    </div>

                                    <div className="input-container flex gap-3">
                                        <InputText style={{ height: "60px" }} value={input} onChange={handleInputChange} onKeyPress={input ? handleKeyPress : null} placeholder='Type a message...' />
                                        <Button style={{ height: "38px" }} onClick={input ? handleSendMessage : null}>Send</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatOption;
