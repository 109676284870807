import React from 'react';
import './Services.scss';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const Services = () => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/plans');
    }

    return (
        <>
            <div className="m-0 p-0 startupservices-section">
                <div className="grid m-0 p-0">
                    <div className="col-12 m-0 p-0">
                        <div className="h-full ">
                            <Card className="p-3 h-full flex flex-column sec-one flex-wrap">
                                <div>
                                    <span className="service-heading border-bottom-3"><b>Know our plans</b></span>
                                    <p>Choose the right plan and start creating your ideas.</p>
                                </div>
                                <div className="flex align-items-center plan-section">

                                    <div className="col-4 lg:col-4 h-full col-section">
                                        <div className=" h-full">
                                            <Card className=" flex flex-column sec-three h-full">
                                                <div className="platinum-plan">
                                                    <div>
                                                        <span className="service-heading"><b>Starter Plan</b></span>
                                                        <p>Kickstart your idea with essential tools and guidance.</p>
                                                    </div>
                                                    <div className='services-details'>
                                                        <Button onClick={handleRedirect}>Learn more</Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>

                                    <div className="col-4 lg:col-4 h-full col-section">
                                        <div className="h-full">
                                            <Card className="h-full flex flex-column sec-two">
                                                <div className="gold-plan">
                                                    <div>
                                                        <span className="service-heading" ><b>Standard Launch Plan</b></span>
                                                        <p>Comprehensive support for your business launch.</p>
                                                    </div>
                                                    <div className='services-details'>
                                                        <Button onClick={handleRedirect}>Learn more</Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>

                                    <div className="col-4 lg:col-4 h-full col-section">
                                        <div className="h-full">
                                            <Card className=" flex flex-column sec-three h-full">
                                                <div className="platinum-plan">
                                                    <div>
                                                        <span className="service-heading"><b>Premium Launch Plan</b></span>
                                                        <p>Full-scale launch with ongoing, in-depth support. </p>
                                                    </div>
                                                    <div className='services-details'>
                                                        <Button onClick={handleRedirect}>Learn more</Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Services;