
import React, { useState } from 'react';
import './AcademyInternship.scss';
import CourseBanner from '../../../../Assets/AcademyInternship/CourseBanner.png';
import Header from '../../../Home/Header/Header';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import Footer from '../../../Home/Footer/Footer';
import { InputMask } from 'primereact/inputmask';
import { FaCheckCircle, FaClock } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock, faDollarSign, faLocationDot, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const AcademyInternship = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            message: formData.message
        };

        try {
            const response = fetch('https://nxqrlk3510.execute-api.us-east-1.amazonaws.com/sendEmail', {
                method: 'POST',
                mode: 'no-cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {

                setShowSuccessMessage(true);

                setTimeout(() => {
                    setShowSuccessMessage(false);
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phoneNumber: '',
                        message: ''
                    })
                }, 3000);
            }

            if (!response.ok) {
                const errorText = response.text();
                console.error("HTTP Error: ", response.status, errorText);
                throw new Error(`HTTP Error: ${response.status} ${errorText}`);
            }
        } catch (error) {
            console.error("Error during fetch: ", error);
        }
    };

    const handleRedirect = () => {
        navigate('/s2lAcademy');
    }

    return (
        <>
            <Header />
            <div className='academy-internship-section'>
                <div className='internship-sec'>
                    <div className='internship-content w-full'>
                        <div className='col p-5'>
                            <div className='ic-heading pb-5'>S2L Academy’s 8-Week Course for Middle & High School Students</div>
                            <div className='ic-main-details'>
                                <div className='flex gap-5 pb-4'>
                                    <div className='flex align-items-center justify content-center gap-2'><FontAwesomeIcon icon={faUserGroup} />In-Person Classes</div>
                                </div>
                                <div className='flex align-items-center justify content-center gap-2 pb-4'>
                                    <FontAwesomeIcon icon={faCalendar} /><div>Saturday, Sunday Mornings or may vary</div>
                                </div>
                                <div className='flex gap-5'>
                                    <div className='flex align-items-center justify content-center gap-2'><FontAwesomeIcon icon={faClock} />8 Weeks</div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className='internship-img-sec'>
                        <img src={CourseBanner} alt='CourseBanner' />
                    </div>
                </div>

                <div className="academy-internship-heading text-center">
                    <h2>
                        Join the S2L Academy 8-Week Course for Middle and High School Students!
                    </h2>
                </div>

                {!showSuccessMessage ?
                    <div className='academy-internship-details flex flex-wrap m-5'>
                        <div className='academy-internship-content col-6 mb-4'>
                            <div className='sec-foundation'>
                                <div className="sec-foundation-content">
                                    <p className="sec-foundation-heading">Foundation Building</p>
                                    <p className="sec-foundation-para">Our program begins with a solid foundation focusing on:
                                        <ul>
                                            <li>Clarity: Understanding the startup journey.</li>
                                            <li>Collaboration: Working effectively in teams.</li>
                                            <li>Time Management: Balancing school and startup commitments.</li>
                                            <li>Feedback Gathering: Learning from peers and mentors.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className='sec-foundation'>
                                <div className="sec-idea-content">
                                    <p className="sec-foundation-heading">Idea to Launch</p>
                                    <p className="sec-foundation-para">Students will dive into:
                                        <ul>
                                            <li>Market Research: Identifying target markets and customer needs.</li>
                                            <li>Prototype Development: Turning ideas into tangible products.</li>
                                            <li>Marketing Plan Creation: Developing strategies to reach the audience.</li>
                                            <li>Iteration and Launch Strategies: Refining products and preparing for launch.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                            <div className='sec-foundation'>
                                <div className="sec-splTrack-content">
                                    <p className="sec-foundation-heading">Specialized Tracks & Expert Lectures</p>
                                    <p className="sec-foundation-para">
                                        <ul>
                                            <li>Specializations: Digital Technology and Social Entrepreneurship.</li>
                                            <li>Guest Lectures: Insights from industry experts.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='acedemy-internship-form col-6 '>
                            <h2 className='internship-heading'>Register Now!</h2>
                            <p><FontAwesomeIcon icon={faUserGroup} />In-Person Classes</p>
                            <p><FontAwesomeIcon icon={faCalendar} />Saturday, Sunday Mornings or may vary</p>
                            <p><FontAwesomeIcon icon={faClock} />8 Weeks</p>
                            <form onSubmit={handleSubmit}>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label>Name</label>
                                        <InputText id="firstName" type="text" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                            placeholder='Name'
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="field col">
                                        <label >Last Name</label>
                                        <InputText id="lastname" type="text" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                            placeholder='Last Name'
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="field col-12 flex flex-column gap-2 p-0">
                                    <label>Email</label>
                                    <InputText id="email" name="email" placeholder='Email' value={formData.email} onChange={handleChange} required/>
                                </div>
                                <div className="field col-12 flex flex-column gap-2 p-0">
                                    <label>Phone Number</label>
                                    <InputMask id="phoneNumber" name="phoneNumber" mask="(999) 999-9999" placeholder="(999) 999-9999" value={formData.phoneNumber} onChange={handleChange} required />
                                </div>
                                <div className="field col-12 flex flex-column gap-2 p-0">
                                    <label>Message (Optional):</label>
                                    <InputTextarea id="message" name="message" rows={6} value={formData.message} placeholder="Write here..."
                                        onChange={handleChange} />
                                </div>
                                <hr></hr>
                                <div className='internship-button flex align-items-center justify-content-end py-2'>
                                    <Button>Join the 8 week course now!</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    :
                    <div className="success-messages">
                        <div className="success-message-contents">
                            <FaCheckCircle className='check-icon'></FaCheckCircle>
                            <div className="success-message-headings">
                                Thank you for or showing interest. <br />
                                We will all stay in touch.
                            </div>
                            <Button onClick={handleRedirect}>Return to S2L Academy</Button>
                        </div>
                    </div>
                }

            </div>
            <Footer />
        </>
    )
}

export default AcademyInternship;