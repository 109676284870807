// Workshop1 Images
import headerImage from '../../../Assets/Workshops/Workshop1/Header.png';
import guest1 from '../../../Assets/Workshops/Workshop1/Speaker.png';
import photo1 from '../../../Assets/Workshops/Workshop1/Blog/1.png';
import photo2 from '../../../Assets/Workshops/Workshop1/Blog/2.png';
import photo3 from '../../../Assets/Workshops/Workshop1/Blog/3.png';
import photo4 from '../../../Assets/Workshops/Workshop1/Blog/4.png';
import photo5 from '../../../Assets/Workshops/Workshop1/Blog/5.png';
import photo6 from '../../../Assets/Workshops/Workshop1/Blog/6.png';
import photo7 from '../../../Assets/Workshops/Workshop1/Blog/7.png';
import photo8 from '../../../Assets/Workshops/Workshop1/Blog/8.png';
import photo9 from '../../../Assets/Workshops/Workshop1/Blog/9.png';
import photo10 from '../../../Assets/Workshops/Workshop1/Blog/10.png';
import photo11 from '../../../Assets/Workshops/Workshop1/Blog/11.png';
import photo12 from '../../../Assets/Workshops/Workshop1/Blog/12.png';
import photo13 from '../../../Assets/Workshops/Workshop1/Blog/13.png';
import photo14 from '../../../Assets/Workshops/Workshop1/Blog/14.png';
import photo15 from '../../../Assets/Workshops/Workshop1/Blog/15.png';
import photo16 from '../../../Assets/Workshops/Workshop1/Blog/16.png';
import photo17 from '../../../Assets/Workshops/Workshop1/Blog/17.png';
import photo18 from '../../../Assets/Workshops/Workshop1/Blog/18.png';
import photo19 from '../../../Assets/Workshops/Workshop1/Blog/19.png';
import photo20 from '../../../Assets/Workshops/Workshop1/Blog/20.png';
import photo21 from '../../../Assets/Workshops/Workshop1/Blog/21.png';
import photo22 from '../../../Assets/Workshops/Workshop1/Blog/22.png';
import photo23 from '../../../Assets/Workshops/Workshop1/Blog/23.png';
import photo24 from '../../../Assets/Workshops/Workshop1/Blog/24.png';
import photo25 from '../../../Assets/Workshops/Workshop1/Blog/25.png';
import photo26 from '../../../Assets/Workshops/Workshop1/Blog/26.png';
import photo27 from '../../../Assets/Workshops/Workshop1/Blog/27.png';
import photo28 from '../../../Assets/Workshops/Workshop1/Blog/28.png';
//  Workshop2 Images
import headerImage2 from '../../../Assets/Workshops/Workshop2/header_image.png';
import aboutImage from '../../../Assets/Workshops/Workshop2/about_image.png';
import guest2 from '../../../Assets/Workshops/Workshop2/Speaker.png';
import w2photo1 from '../../../Assets/Workshops/Workshop2/Blog/1.png';
import w2photo2 from '../../../Assets/Workshops/Workshop2/Blog/2.png';
import w2photo3 from '../../../Assets/Workshops/Workshop2/Blog/3.png';
import w2photo4 from '../../../Assets/Workshops/Workshop2/Blog/4.png';
import w2photo5 from '../../../Assets/Workshops/Workshop2/Blog/5.png';
import w2photo6 from '../../../Assets/Workshops/Workshop2/Blog/6.png';
import w2photo7 from '../../../Assets/Workshops/Workshop2/Blog/7.png';
import w2photo8 from '../../../Assets/Workshops/Workshop2/Blog/8.png';
import w2photo9 from '../../../Assets/Workshops/Workshop2/Blog/9.png';
import w2photo10 from '../../../Assets/Workshops/Workshop2/Blog/10.png';
import w2photo11 from '../../../Assets/Workshops/Workshop2/Blog/11.png';
import w2photo12 from '../../../Assets/Workshops/Workshop2/Blog/12.png';
import w2photo13 from '../../../Assets/Workshops/Workshop2/Blog/13.png';
import w2photo14 from '../../../Assets/Workshops/Workshop2/Blog/14.png';
import w2photo15 from '../../../Assets/Workshops/Workshop2/Blog/15.png';
import w2photo16 from '../../../Assets/Workshops/Workshop2/Blog/16.png';
import w2photo17 from '../../../Assets/Workshops/Workshop2/Blog/17.png';
import w2photo18 from '../../../Assets/Workshops/Workshop2/Blog/18.png';
import w2photo19 from '../../../Assets/Workshops/Workshop2/Blog/19.png';
import w2photo20 from '../../../Assets/Workshops/Workshop2/Blog/20.png';
import w2photo21 from '../../../Assets/Workshops/Workshop2/Blog/21.png';
import w2photo22 from '../../../Assets/Workshops/Workshop2/Blog/22.png';
import w2photo23 from '../../../Assets/Workshops/Workshop2/Blog/23.png';
import w2photo24 from '../../../Assets/Workshops/Workshop2/Blog/24.png';
import w2photo25 from '../../../Assets/Workshops/Workshop2/Blog/25.png';
import w2photo26 from '../../../Assets/Workshops/Workshop2/Blog/26.png';
import w2photo27 from '../../../Assets/Workshops/Workshop2/Blog/27.png';
import w2photo28 from '../../../Assets/Workshops/Workshop2/Blog/28.png';
import w2photo29 from '../../../Assets/Workshops/Workshop2/Blog/29.png';
import w2photo30 from '../../../Assets/Workshops/Workshop2/Blog/30.png';
import w2photo31 from '../../../Assets/Workshops/Workshop2/Blog/31.png';
import w2photo32 from '../../../Assets/Workshops/Workshop2/Blog/32.png';
import w2photo33 from '../../../Assets/Workshops/Workshop2/Blog/33.png';
import w2photo34 from '../../../Assets/Workshops/Workshop2/Blog/34.png';
import w2photo35 from '../../../Assets/Workshops/Workshop2/Blog/35.png';
import w2photo36 from '../../../Assets/Workshops/Workshop2/Blog/36.png';
import w2photo37 from '../../../Assets/Workshops/Workshop2/Blog/37.png';
import w2photo38 from '../../../Assets/Workshops/Workshop2/Blog/38.png';
import w2photo39 from '../../../Assets/Workshops/Workshop2/Blog/39.png';
import w2photo40 from '../../../Assets/Workshops/Workshop2/Blog/40.png';
import w2photo41 from '../../../Assets/Workshops/Workshop2/Blog/41.png';
import w2photo42 from '../../../Assets/Workshops/Workshop2/Blog/42.png';
import w2photo43 from '../../../Assets/Workshops/Workshop2/Blog/43.png';
import w2photo44 from '../../../Assets/Workshops/Workshop2/Blog/44.png';
import w2photo45 from '../../../Assets/Workshops/Workshop2/Blog/45.png';
import w2photo46 from '../../../Assets/Workshops/Workshop2/Blog/46.png';
import w2photo47 from '../../../Assets/Workshops/Workshop2/Blog/47.png';
import w2photo48 from '../../../Assets/Workshops/Workshop2/Blog/48.png';
import w2photo49 from '../../../Assets/Workshops/Workshop2/Blog/49.png';
import w2photo50 from '../../../Assets/Workshops/Workshop2/Blog/50.png';


const WSData = [
    {
        "url": "w4/Startup Foundations for Teens: Your path to Entrepreneurship",
        "workshopName": "Startup Foundations for Teens: Your path to Entrepreneurship",
        "date": "September 14th, 2024",
        "description": "Join our Entrepreneurship Masterclass to learn from industry experts and secure valuable internship opportunities!",
        "read_time": null,
        "title": "Startup2Launch",
        "headerImages": [
            headerImage2,

        ],
        "about": [
            "Discover Your True Potential! Participate in our exclusive 3-hours workshop and acquire cutting-edge skills that will distinguish you in your future career. This is a unique chance for high school students eager to lead and innovate. Don’t let this opportunity pass you by; enroll in our workshop and embark on your journey today!",
            "Then workshop will start where you will learn to: Think Differently: Harness the power of First Principles Thinking. Boost Your Grades: Get tips and tricks to master your academics. Manage Your Time: Learn techniques to organize and prioritize effectively. Start It Up: Get the lowdown on launching and running your own business. Meet other like-minded students in the workshop and participate in fun quiz/activities.",
        ],
        "secondImage": [
            aboutImage,
        ],
        "place": " Davis Library, Plano, 7501 Independence Parkway Plano, TX 75025",
        "worksshopDate": "September 14th, 2024",
        "workshopDuration": "2pm to 4pm CDT",
        "assitance": "40 students",
        "topics": [
            {
                "name": "Introduction to S2L Academy",
                "subpoints": [
                    "Receive a comprehensive overview of the S2L Academy program offerings.",
                ]
            },
            {
                "name": "Foundation",
                "subpoints": [
                    "Embrace innovative problem-solving with First Principles of Thinking.",
                ]
            },
            {
                "name": "Academic Mastery",
                "subpoints": [
                    "Unlock strategies to excel in academics and secure better grades.",
                ]
            },
            {
                "name": "Time Management",
                "subpoints": [
                    "Learn how to effectively prioritize and optimize your day.",
                ]
            },
            {
                "name": "Entrepreneurship",
                "subpoints": [
                    "Gain the essential skills to start your own business and become a successful entrepreneur.",
                ]
            },
        ],
        "audience": "Beginners, Students, Professionals",
        "guest": [
            {
                "name": "Sumi Yi",
                "Image": guest2,
                "designation": "College Counselor, C2Educate",
                "description": "Sumi Hanna Yi have been working in private educational sector for last 30 years managing operations, consulting, counseling, budgeting, marketing, sales, staffing, training. The accumulated experience allows to work with any opportunity in hand with different people with different experiences.",
                "fullDescription": "Sumi Hanna Yi have been working in private educational sector for last 30 years managing operations, consulting, counseling, budgeting, marketing, sales, staffing, training. The accumulated experience allows to work with any opportunity in hand with different people with different experiences. Strong project based team worker knowing how to motivate and encourage members to work together utilizing their experience and potential.",
            },
        ],
        "feedback": [
            {
                "comment": "“There was a lot of good activities that we did and it was, it was a really, it like put me into the course, like I was actually learning and like, like how we better use this in the future.”",
                "rating": 5
            },
            {
                "comment": "“I really enjoyed this workshop and I thought it provided me with the skills I needed for the upcoming college applications.”",
                "rating": 5
            },
            {
                "comment": "“The one thing that I enjoyed was basically making the business idea and presenting it because that really helped me like see how much thought process goes into it and what you need to think about for the future.”",
                "rating": 5
            },
            {
                "comment": "“I learned how to properly study, how, like, what I should look forward to in high school, how to properly prepare for high school, and how to look forward.”",
                "rating": 5
            },
            {
                "comment": "“Probably the entrepreneurship and part of it where we made our own business.”",
                "rating": 5
            },
            {
                "comment": "“It was fun coming up with a business idea.”",
                "rating": 5
            }
        ],
        "photos": null,
        "statusCode": "U",
        "status": "Upcoming",
    },
    {
        "url": "w3/Startup Foundations for Teens: Your path to Entrepreneurship",
        "workshopName": "Startup Foundations for Teens: Your path to Entrepreneurship",
        "date": "September 8th, 2024",
        "description": "Join our Entrepreneurship Masterclass to learn from industry experts and secure valuable internship opportunities!",
        "read_time": null,
        "title": "Startup2Launch",
        "headerImages": [
            headerImage2,

        ],
        "about": [
            "Discover Your True Potential! Participate in our exclusive 3-hours workshop and acquire cutting-edge skills that will distinguish you in your future career. This is a unique chance for high school students eager to lead and innovate. Don’t let this opportunity pass you by; enroll in our workshop and embark on your journey today!",
            "Then workshop will start where you will learn to: Think Differently: Harness the power of First Principles Thinking. Boost Your Grades: Get tips and tricks to master your academics. Manage Your Time: Learn techniques to organize and prioritize effectively. Start It Up: Get the lowdown on launching and running your own business. Meet other like-minded students in the workshop and participate in fun quiz/activities.",
        ],
        "secondImage": [
            aboutImage,
        ],
        "place": " Flower Mound Library, 3030 Broadmoor Ln, TX, 75022",
        "worksshopDate": "September 8th, 2024",
        "workshopDuration": "2pm to 4pm CDT",
        "assitance": "40 students",
        "topics": [
            {
                "name": "Introduction to S2L Academy",
                "subpoints": [
                    "Receive a comprehensive overview of the S2L Academy program offerings.",
                ]
            },
            {
                "name": "Foundation",
                "subpoints": [
                    "Embrace innovative problem-solving with First Principles of Thinking.",
                ]
            },
            {
                "name": "Academic Mastery",
                "subpoints": [
                    "Unlock strategies to excel in academics and secure better grades.",
                ]
            },
            {
                "name": "Time Management",
                "subpoints": [
                    "Learn how to effectively prioritize and optimize your day.",
                ]
            },
            {
                "name": "Entrepreneurship",
                "subpoints": [
                    "Gain the essential skills to start your own business and become a successful entrepreneur.",
                ]
            },
        ],
        "audience": "Beginners, Students, Professionals",
        "guest": [
            {
                "name": "Sumi Yi",
                "Image": guest2,
                "designation": "College Counselor, C2Educate",
                "description": "Sumi Hanna Yi have been working in private educational sector for last 30 years managing operations, consulting, counseling, budgeting, marketing, sales, staffing, training. The accumulated experience allows to work with any opportunity in hand with different people with different experiences.",
                "fullDescription": "Sumi Hanna Yi have been working in private educational sector for last 30 years managing operations, consulting, counseling, budgeting, marketing, sales, staffing, training. The accumulated experience allows to work with any opportunity in hand with different people with different experiences. Strong project based team worker knowing how to motivate and encourage members to work together utilizing their experience and potential.",
            },
        ],
        "feedback": [
            {
                "comment": "“There was a lot of good activities that we did and it was, it was a really, it like put me into the course, like I was actually learning and like, like how we better use this in the future.”",
                "rating": 5
            },
            {
                "comment": "“I really enjoyed this workshop and I thought it provided me with the skills I needed for the upcoming college applications.”",
                "rating": 5
            },
            {
                "comment": "“The one thing that I enjoyed was basically making the business idea and presenting it because that really helped me like see how much thought process goes into it and what you need to think about for the future.”",
                "rating": 5
            },
            {
                "comment": "“I learned how to properly study, how, like, what I should look forward to in high school, how to properly prepare for high school, and how to look forward.”",
                "rating": 5
            },
            {
                "comment": "“Probably the entrepreneurship and part of it where we made our own business.”",
                "rating": 5
            },
            {
                "comment": "“It was fun coming up with a business idea.”",
                "rating": 5
            }
        ],
        "photos": null,
        "statusCode": "U",
        "status": "Upcoming",
    },
    {
        "url": "w2/Master Entrepreneurship, Excel High School and Ace College Admissions",
        "workshopName": "Master Entrepreneurship, Excel High School and Ace College Admissions",
        "date": "July 27th, 2024",
        "description": "Discover Your True Potential! Participate in our exclusive 3-hours workshop and acquire cutting-edge skills that will distinguish you in your future career. This is a unique chance for high school students eager to lead and innovate. Don’t let this opportunity pass you by; enroll in our workshop and embark on your journey today!",
        "read_time": "/ 6 min read",
        "title": "Startup2Launch",
        "headerImages": [
            headerImage2,

        ],
        "about": [
            "Discover Your True Potential! Participate in our exclusive 3-hours workshop and acquire cutting-edge skills that will distinguish you in your future career. This is a unique chance for high school students eager to lead and innovate. Don’t let this opportunity pass you by; enroll in our workshop and embark on your journey today!",
            "Then workshop will start where you will learn to: Think Differently: Harness the power of First Principles Thinking. Boost Your Grades: Get tips and tricks to master your academics. Manage Your Time: Learn techniques to organize and prioritize effectively. Start It Up: Get the lowdown on launching and running your own business. Meet other like-minded students in the workshop and participate in fun quiz/activities.",
        ],
        "secondImage": [
            aboutImage,
        ],
        "place": " Flower Mound Library, 3030 Broadmoor Ln, TX, 75022",
        "worksshopDate": "July 27th, 2024",
        "workshopDuration": "11:30 AM to 2:30 PM",
        "assitance": "40 students",
        "topics": [
            {
                "name": "Introduction to S2L Academy",
                "subpoints": [
                    "Receive a comprehensive overview of the S2L Academy program offerings.",
                ]
            },
            {
                "name": "Foundation",
                "subpoints": [
                    "Embrace innovative problem-solving with First Principles of Thinking.",
                ]
            },
            {
                "name": "Academic Mastery",
                "subpoints": [
                    "Unlock strategies to excel in academics and secure better grades.",
                ]
            },
            {
                "name": "Time Management",
                "subpoints": [
                    "Learn how to effectively prioritize and optimize your day.",
                ]
            },
            {
                "name": "Entrepreneurship",
                "subpoints": [
                    "Gain the essential skills to start your own business and become a successful entrepreneur.",
                ]
            },
        ],
        "audience": "Beginners, Students, Professionals",
        "guest": [
            {
                "name": "Sumi Yi",
                "Image": guest2,
                "designation": "College Counselor, C2Educate",
                "description": "Sumi Hanna Yi have been working in private educational sector for last 30 years managing operations, consulting, counseling, budgeting, marketing, sales, staffing, training. The accumulated experience allows to work with any opportunity in hand with different people with different experiences.",
                "fullDescription": "Sumi Hanna Yi have been working in private educational sector for last 30 years managing operations, consulting, counseling, budgeting, marketing, sales, staffing, training. The accumulated experience allows to work with any opportunity in hand with different people with different experiences. Strong project based team worker knowing how to motivate and encourage members to work together utilizing their experience and potential.",
            },
        ],
        "feedback": [
            {
                "comment": "“There was a lot of good activities that we did and it was, it was a really, it like put me into the course, like I was actually learning and like, like how we better use this in the future.”",
                "rating": 5
            },
            {
                "comment": "“I really enjoyed this workshop and I thought it provided me with the skills I needed for the upcoming college applications.”",
                "rating": 5
            },
            {
                "comment": "“The one thing that I enjoyed was basically making the business idea and presenting it because that really helped me like see how much thought process goes into it and what you need to think about for the future.”",
                "rating": 5
            },
            {
                "comment": "“I learned how to properly study, how, like, what I should look forward to in high school, how to properly prepare for high school, and how to look forward.”",
                "rating": 5
            },
            {
                "comment": "“Probably the entrepreneurship and part of it where we made our own business.”",
                "rating": 5
            },
            {
                "comment": "“It was fun coming up with a business idea.”",
                "rating": 5
            }
        ],
        "photos": [
            w2photo1,
            w2photo2,
            w2photo3,
            w2photo4,
            w2photo5,
            w2photo6,
            w2photo7,
            w2photo8,
            w2photo9,
            w2photo10,
            w2photo11,
            w2photo12,
            w2photo13,
            w2photo14,
            w2photo15,
            w2photo16,
            w2photo17,
            w2photo18,
            w2photo19,
            w2photo20,
            w2photo21,
            w2photo22,
            w2photo23,
            w2photo24,
            w2photo25,
            w2photo26,
            w2photo27,
            w2photo28,
            w2photo29,
            w2photo30,
            w2photo31,
            w2photo32,
            w2photo33,
            w2photo34,
            w2photo35,
            w2photo36,
            w2photo37,
            w2photo38,
            w2photo39,
            w2photo40,
            w2photo41,
            w2photo42,
            w2photo43,
            w2photo44,
            w2photo45,
            w2photo46,
            w2photo47,
            w2photo48,
            w2photo49,
            w2photo50
        ],
        "statusCode": "C",
        "status": "Completed",
    },
    {
        "url": "w1/S2L Academy Workshop for Students",
        "workshopName": "S2L Academy Workshop for Students",
        "date": "June 8, 2024",
        "description": "Unlock Your Potential! Join our exclusive 3-hours workshop to gain innovative skills that will set you apart in your future career. This is a great opportunity for high school students who are eager to lead and innovate. Don't miss out;  sign up for an introductory workshop and start your journey!",
        "read_time": "/ 6 min read",
        "title": "Startup2Launch",
        "headerImages": [
            headerImage
        ],
        "about": null,
        "secondImage": null,
        "place": "Plano Hoggard Library, Genealogy Program Room",
        "worksshopDate": "June 8, 2024",
        "workshopDuration": "10:30 AM to 1:30 PM",
        "assitance": "25 students",
        "topics": [
            {
                "name": "Dive into our action-packed workshops where you’ll engage with mentors, tackle real-world challenges, and learn how to:",
                "subpoints": [
                    "Think Differently: Harness the power of First Principles Thinking.",
                    "Boost Your Grades: Get tips and tricks to master your academics.",
                    "Manage Your Time: Learn techniques to organize and prioritize effectively.",
                    "Start It Up: Get the lowdown on launching and running your own business.",
                ]
            },
            {
                "name": "Workshop Highlights:",
                "subpoints": [
                    "Interactive Sessions: Engage in lively discussions and hands-on activities.",
                    "Networking Opportunities: Meet like-minded peers and experienced mentors.",
                    "Practical Learning: Apply what you learn immediately to your daily life and future ambitions.",
                ]
            },
            {
                "name": "Why Attend?",
                "subpoints": [
                    "Sharpen your academic and life skills.",
                    "Get a head start on college prep and career goals.",
                    "Learn essential skills that aren’t taught in regular classes.",
                ]
            },
        ],
        "audience": "Beginners, Students, Professionals",
        "guest": [
            {
                "name": "Phani Ilapakurty",
                "Image": guest1,
                "designation": "Partner, Cherry Bracket",
                "description": "Phani Ilapakurty is a partner at Cherry Bracket. Previously, he was president of Mueller dotKonnect, a related entity of PKF Mueller, a CPA and business advisory firm that serves clients domestically and internationally.",
                "fullDescription": "Phani Ilapakurty is a partner at Cherry Bracket. Previously, he was president of Mueller dotKonnect, a related entity of PKF Mueller, a CPA and business advisory firm that serves clients domestically and internationally. MDK specializes in business process management, business process outsourcing, and tax services, backed by a team of 300-plus finance, accounting, and tax professionals located in Chicago, Dallas, Portland, Philadelphia, Sarasota, and Hyderabad, India.   Ilapakurty is a Chartered Accountant, a Cost & Management Accountant, a Certified Fraud Examiner, and an Enrolled Agent with over 25 years of consulting, finance & accounting industry experience. He is also the co-founder of Qount.io, a SaaS practice management solution to improve, automate and streamline accounting & tax practices to impact the bottom line positively.",
            },
        ],
        "feedback": [
            {
                "comment": "“Thanks for letting my son have the exposure to such a nice exercise. He was excited and has learned a lot in how to express ideas.”",
                "rating": 5
            },
            {
                "comment": "“Organizing this type of workshop is an excellent idea for high school students to develop new skills in the field of entrepreneurship.”",
                "rating": 5
            },
            {
                "comment": "“My highschooler attended this workshop, and she found this class to be very relevant, informative, engaging and enjoyed every activity they did as a part of the program. She wants to signup for the next 8 week program they have to offer.”",
                "rating": 5
            },
            {
                "comment": "“The workshop by S2L academy was an awesome experience for my daughter to understand the basic concepts of entrepreneurship and the exam prep was a very useful session.”",
                "rating": 5
            },
            {
                "comment": "“Starting workshops at a young age is such a great way to help people understand things better and nurture creativity right from the beginning. Entrepreneurial skills aren't just about business—they also bring a whole new level of understanding to how we interact socially. Learning these skills early on helps us think creatively, handle challenges with resilience, and communicate effectively with others. These workshops are a fantastic way to grow personally and prepare for future opportunities, whether in business or everyday life.”",
                "rating": 5
            },
            {
                "comment": "“A great initiative to help high school students learn about Entrepreneurship and life skills. Looking forward to 8 week program. Must Have for Students.”",
                "rating": 5
            }
        ],
        "photos": [
            photo1,
            photo2,
            photo3,
            photo4,
            photo5,
            photo6,
            photo7,
            photo8,
            photo9,
            photo10,
            photo11,
            photo12,
            photo13,
            photo14,
            photo15,
            photo16,
            photo17,
            photo18,
            photo19,
            photo20,
            photo21,
            photo22,
            photo23,
            photo24,
            photo25,
            photo26,
            photo27,
            photo28,
        ],
        "statusCode": "C",
        "status": "Completed",
    },

]

export default WSData;