import React from 'react'
import Header from '../Home/Header/Header';
import InvestorBanner from './InvestorBanner/InvestorBanner';
import HowItWorks from './HowItWorksSection/HowItWorks';
import MentorSection from './MentorSection/MentorSection';
import JoinS2L from './JoinS2L/JoinS2L';
import Footer from '../Home/Footer/Footer';
import ProjectsShowcase from './HowItWorksSection/ProjectsShowcase/ProjectsShowcase';

const Investor = () => {
    return (
        <div>
            <Header />
            <InvestorBanner />
            <HowItWorks />
            <MentorSection />
            <ProjectsShowcase/>
            <JoinS2L />
            <Footer />
        </div>
    )
}

export default Investor;