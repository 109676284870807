import React, { useState } from "react";
import "./Header.scss";
import logoOpen from "../../../Assets/Home Page/S2L-Logo.svg";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from 'react-router-dom';
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleRedirect = () => {
        setShowMenu(false);
        navigate('/newclientform');
    }

    const handleIconClick = (event) => {
        event.preventDefault();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLinkClick = (event, path) => {
        event.preventDefault();
        setShowMenu(false);
        navigate(path);
    };

    return (
        <header className="s2l-header">
            <nav className="navigation">
                <div className="logo">
                    <a href="/"><img src={logoOpen} alt="" /></a>
                </div>

                <div
                    className={`menu-toggle ${showMenu ? "active" : ""}`}
                    onClick={toggleMenu}>
                    <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                    <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                    <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                </div>

                <div className={`menu ${showMenu ? "show-menu" : ""}`}>
                    <ul>
                        <li className={location.pathname === '/aboutus' ? 'active-menu-item' : ''}>
                            <a href="/aboutus">About Us
                                <FaAngleDown className="caret-icon" />
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="/aboutus#ourStory" onClick={(e) => handleLinkClick(e, '/aboutus#ourStory')}>Our Story</a>
                                </li>
                                <li>
                                    <a href="/aboutus#MissionVision" onClick={(e) => handleLinkClick(e, '/aboutus#MissionVision')}>Mission & Vision</a>
                                </li>
                                <li>
                                    <a href="/aboutus#ourTeam" onClick={(e) => handleLinkClick(e, '/aboutus#ourTeam')}>Our Team</a>
                                </li>
                                <li>
                                    <a href="/Faq">FAQ</a>
                                </li>
                            </ul>
                        </li>
                        <li className={location.pathname === '/plans' ? 'active-menu-item' : ''}>
                            <a href="/plans">Plans
                                <FaAngleDown className="caret-icon" />
                            </a>
                            <ul class="dropdown-menu">
                                <li className={location.pathname === '/plans' ? 'active-menu-item' : ''}>
                                    <a href="/plans">Plans</a>
                                </li>
                                <li className={location.pathname === '/services' ? 'active-menu-item' : ''}>
                                    <a href="/services">Services</a>
                                </li>
                            </ul>
                        </li>

                        <li className={location.pathname === '/recruitment' ? 'active-menu-item' : ''}>
                            <a href="/recruitment">Recruitment</a>
                            {/* <ul class="dropdown-menu">
                                <li> 
                                    <a href="/ourWork">Careers</a>
                                </li>
                            </ul> */}
                        </li>
                        <li className={location.pathname === '/s2lAcademy' ? 'active-menu-item' : ''}>
                            <a href="/s2lAcademy">Academy <FaAngleDown className="caret-icon" /> </a>
                            <ul class="dropdown-menu">
                                <li className={location.pathname === '/s2lAcademy' ? 'active-menu-item' : ''}>
                                    <a href="/s2lAcademy">S2L Academy</a>
                                </li>
                                <li className={location.pathname === '/studentprofile' ? 'active-menu-item' : ''}>
                                    <a href="/studentprofile">Students Profile</a>
                                </li>
                                {/* <li className={location.pathname === '/speakers' ? 'active-menu-item' : ''}>
                                    <a href="/speakers">Guest Speakers</a>
                                </li> */}
                                <li className={location.pathname === '/workshops' ? 'active-menu-item' : ''}>
                                    <a href="/workshops">WorkShops</a>
                                </li>
                                {/* <li className={location.pathname === '/upcomingevents' ? 'active-menu-item' : ''}>
                                    <a href="/upcomingevents">Upcoming Events</a>
                                </li> */}
                                <li className={location.pathname === '/8-weekcourse' ? 'active-menu-item' : ''}>
                                    <a href="/8-weekcourse">8-Week Course </a>
                                </li>

                            </ul>
                        </li>
                        <li className={location.pathname === '/investor' ? 'active-menu-item' : ''}>
                            <a href="/investor">Investors</a>
                        </li>
                        <li className={location.pathname === '/ourWork' ? 'active-menu-item' : ''}>
                            <a href="/ourWork">Our Work
                                <FaAngleDown className="caret-icon" />
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="/ourWork#projects" onClick={(e) => handleLinkClick(e, '/ourWork#projects')}>Projects</a>
                                </li>
                                <li>
                                    <a href="/ourWork#frameworks" onClick={(e) => handleLinkClick(e, '/ourWork#frameworks')}>Frameworks</a>
                                </li>
                                <li>
                                    <a href="/ourWork#technologies" onClick={(e) => handleLinkClick(e, '/ourWork#technologies')}>Technologies</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Button onClick={handleRedirect}>Start Your Journey Today</Button>
                        </li>
                    </ul>
                </div>

                {window.innerWidth <= 1315 && (
                    <div className={`menu ${showMenu ? "show-menu" : ""}`}>
                        <ul>
                            <li className={location.pathname === '/aboutus' ? 'active-menu-item' : ''}>
                                <a href="/aboutus">About Us
                                    <FaAngleRight className="caret-icons" onClick={handleIconClick} />
                                </a>
                                {isDropdownOpen && (
                                    <ul className="dropdown-menus">
                                        <li>
                                            <a href="/aboutus#ourStory" onClick={(e) => handleLinkClick(e, '/aboutus#ourStory')}>Our Story</a>
                                        </li>
                                        <li>
                                            <a href="/aboutus#MissionVision" onClick={(e) => handleLinkClick(e, '/aboutus#MissionVision')}>Mission & Vision</a>
                                        </li>
                                        <li>
                                            <a href="/aboutus#ourTeam" onClick={(e) => handleLinkClick(e, '/aboutus#ourTeam')}>Our Team</a>
                                        </li>
                                        <li>
                                            <a href="/Faq">FAQ</a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li className={location.pathname === '/plans' ? 'active-menu-item' : ''}>
                                <a href="/plans">Plans
                                    <FaAngleRight className="caret-icons" onClick={handleIconClick} />
                                </a>
                                {isDropdownOpen && (
                                    <ul className="dropdown-menu">
                                        <li className={location.pathname === '/plans' ? 'active-menu-item' : ''}>
                                            <a href="/plans">Plans</a>
                                        </li>
                                        <li className={location.pathname === '/services' ? 'active-menu-item' : ''}>
                                            <a href="/services">Services</a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li className={location.pathname === '/recruitment' ? 'active-menu-item' : ''}>
                                <a href="/recruitment">Recruitment</a>
                            </li>
                            <li className={location.pathname === '/s2lAcademy' ? 'active-menu-item' : ''}>
                                <a href="/s2lAcademy">Academy <FaAngleRight className="caret-icons" onClick={handleIconClick} /> </a>
                                {isDropdownOpen && (
                                    <ul class="dropdown-menu">
                                        <li className={location.pathname === '/studentprofile' ? 'active-menu-item' : ''}>
                                            <a href="/studentprofile">Student Profile</a>
                                        </li>
                                        {/* <li className={location.pathname === '/speakers' ? 'active-menu-item' : ''}>
                                            <a href="/speakers">Guest Speakers</a>
                                        </li> */}
                                        <li className={location.pathname === '/workshops' ? 'active-menu-item' : ''}>
                                            <a href="/workshops">WorkShops</a>
                                        </li>
                                        {/* <li className={location.pathname === '/upcomingevents' ? 'active-menu-item' : ''}>
                                            <a href="/upcomingevents">Upcoming Events</a>
                                        </li> */}
                                        <li className={location.pathname === '/8-weekcourse ' ? 'active-menu-item' : ''}>
                                            <a href="/8-weekcourse">8-Week Course </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li className={location.pathname === '/investor' ? 'active-menu-item' : ''}>
                                <a href="/investor">Investors</a>
                            </li>
                            <li className={location.pathname === '/ourWork' ? 'active-menu-item' : ''}>
                                <a href="/ourWork">Our Work
                                    <FaAngleRight className="caret-icons" onClick={handleIconClick} />
                                </a>
                                {isDropdownOpen && (
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a href="/ourWork#projects" onClick={(e) => handleLinkClick(e, '/ourWork#projects')}>Projects</a>
                                        </li>
                                        <li>
                                            <a href="/ourWork#frameworks" onClick={(e) => handleLinkClick(e, '/ourWork#frameworks')}>Frameworks</a>
                                        </li>
                                        <li>
                                            <a href="/ourWork#technologies" onClick={(e) => handleLinkClick(e, '/ourWork#technologies')}>Technologies</a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <Button onClick={handleRedirect}>Start Your Journey Today</Button>
                            </li>
                        </ul>
                    </div>
                )}
            </nav >
        </header >
    );
};

export default Header;
