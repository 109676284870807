import React from 'react';
import Bannerimage from '../../../Assets/Recruitment/Hire-smart-scale-fast-header.png';
import BannerMobileImage from '../../../Assets/Recruitment/Figure-header.svg';
import "./RecruitmentBanner.scss";
import { Button } from 'primereact/button';

const RecruitmentBanner = () => {

    return (
        <div className='recruitment-banner-section '>
            <section className="layout">
                <>
                    <div className='banner-content'>
                        <section>
                            <h1 style={{ color: "#000000" }}> <span style={{ borderRadius: "50px", background: "rgba(255, 185, 137, 0.25)", width: "fit-content", padding: "0 10px", marginLeft: "-10px" }}>Hire Smart,</span> Scale Fast</h1>
                            <p style={{ color: "#000000" }}>Most Startups find it <span style={{ background: "#DDE7D0", borderRadius: "50px", padding: "0 5px" }}> difficult</span> to find the right talent that fits their company's culture and ethos.
                            </p>
                            <p style={{ color: "#000000" }}>
                                Our dedicated recruitment specialists are trained to understand your unique needs and will find a perfect fit,
                                allowing you to concentrate on <span style={{ background: "#DDE7D0", borderRadius: "50px", padding: "0 5px" }}> Growing your Business.</span>
                            </p>
                            <div className='mobile-screen-button'>
                                <div className="start-hire-button">
                                    <Button className='button-style-primary'><a href="/newclientform">Start Hiring</a></Button>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='banner-images'>
                        <img src={Bannerimage} alt='Bannerimage' className='desktop-screen-image' />
                        <img src={BannerMobileImage} alt='Bannerimage' className='mobile-screen-image' />
                    </div>
                </>
            </section>

            <div className='desktop-screen-button'>
                <div className='start-hire-button'>
                    <Button className='button-style-primary'><a href="/newclientform">Start Hiring</a></Button>
                </div>
            </div>

        </div>
    )
}

export default RecruitmentBanner