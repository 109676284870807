import React from 'react';

const SpeechChart = ({ speechData, speechTranscribe }) => {

    if (!Array.isArray(speechData)) {
        return <div>Error: speechData is not iterable</div>;
    }

    const sortedData = [...speechData].sort((a, b) => b.Confidence - a.Confidence);

    const top5Emotions = sortedData.slice(0, 5);

    return (
        <>
            <div>
                <h2>Text</h2>
                <p > {speechTranscribe}</p>
                <h2 className='mt-0'>Emotions</h2>
                <p className='mt-0'>
                    <ul className='mt-0'>
                        {top5Emotions.map((emotion, index) => (
                            <li key={index}>
                                {emotion.Type}: {emotion.Confidence.toFixed(2)}%
                            </li>
                        ))}
                    </ul>
                </p>
            </div>
        </>
    )
}

export default SpeechChart;
