import React, { useState } from 'react'
import Header from '../../Home/Header/Header'
import { Button } from 'primereact/button'
import { FaChevronLeft } from 'react-icons/fa'
import CalendlyComponent from '../../../Shared-Components/Calendly/Calendly'
import Footer from '../../Home/Footer/Footer'

const Appointment = () => {
    const [show, setShow] = useState(false);

    const handleRedirect = () => {
        setShow(true);
    };

    return (
        <div>
            <Header />
            <div className="aryanow-project-section-payment">
                <div className="demo-back-button">
                    <Button className='back-button'><a href="/ourWork"> <FaChevronLeft /> Back</a></Button>
                </div>

                <div className="payment-section">
                    <h2>Automated appointments</h2>
                </div>

                {show ? (
                    <div className="payment-section">
                        <div className="demo-instruction">
                            <div className='border-right col-6'>
                                <h2>Instructions:</h2>
                                <ul>
                                    <ol>1. Click on "Schedule an Appointment".</ol>
                                    <ol>2. A pop-up windows will open. </ol>
                                    <ol>3. Select the date of the appointment.</ol>
                                    <ol>4. Select the hour</ol>
                                    <ol>5. Fill the form. Recommendation try to use your personal email
                                        account on this way you can the all the function of this framework.
                                    </ol>
                                    <ol>6. Click on "Schedule Event".</ol>
                                </ul>
                                <p>
                                    Note: This is an example we don't contact with you for the appointment,
                                    but in your email you can reschedule or cancel the appointment.
                                </p>
                            </div>
                            <div className='col-6 demo-right-section text-center place-items-center'>
                                <CalendlyComponent />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="payment-section">
                            <p>
                                Startup2launch (S2L) provides automated appointment-setting
                                options to streamline scheduling for entrepreneurs.
                            </p>

                            <p>
                                These tools minimize:
                                <ul className='list-sections'>
                                    <li>Time spent coordinating calendars </li>
                                    <li>Eliminate human error</li>
                                    <li>Efficiently organize activities related to your products or services.</li>
                                </ul>
                            </p>

                            <p>
                                Ensuring a smooth and professional experience for both entrepreneurs and their clients.
                            </p>
                        </div>

                        <div className="m-5">
                            <Button onClick={handleRedirect} className='p-button'> Try Now</Button>
                        </div>
                    </>
                )}
            </div>

            <Footer />

        </div>
    )
}

export default Appointment;