import React, { useState } from "react";
import '../Our-Project-Section/ProjectSection.scss';
import Header from "../../Home/Header/Header";
import Footer from "../../Home/Footer/Footer";
import { Button } from "primereact/button";
import LaulamaLogo from '../../../Assets/Our-Work/Logo-Laulama.png';
import quote from '../../../Assets/Our-Work/quote-left-solid.svg';
import { FaChevronLeft } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionTab } from "primereact/accordion";

const initialTestimonialsData = [
    {
        name: 'Corey Nakamura',
        role: 'Principal',
        description: 'Fantastic Experience - This is different, something I never learned in my entire career in education.',
    },
    {
        name: 'Leila Kobayashi',
        role: 'Principal',
        description: 'Very insightful and helpful!  - By attending the Conference, I now better understand Laulama and what we have been doing.',
    },
    {
        name: 'Tayler Kanoho',
        role: 'Teacher',
        description: 'The best experience! - I now know what my issue was that coused the results for my class and what I need to change to improve. ',
    },
];


const LaulamaProject = () => {

    const [activeSection, setActiveSection] = useState("about");
    const [testimonials, setTestimonials] = useState(initialTestimonialsData);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            setActiveSection(id);
        }
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const projects = [
        {
            title: 'General Information',
            content: (
                <div className="m-5 laulama-img-details ">
                    <div className="laulama-content-section p-3">
                        <div className="flex align-items-center justify-content-between laulamalogo-sec">
                            <div className="flex align-items-center justify-content-start gap-5 laulamalogo-sec">
                                <img src={LaulamaLogo} alt='LaulamaLogo' />
                                <div>
                                    <div>Laulama Project</div>
                                    <span>2023</span>
                                </div>
                            </div>
                            <div>
                               <a href="https://laulamaschools.com/" target="_blank" className="flex align-items-center justify-content-center"><Button>Visit the site </Button></a>
                            </div>
                        </div>
                        <div>
                            <p>Laulama school is working to innovate education’s underlying improvement system for breakthroughs that transform schools for their leaders, educators, and students. Laulama Learning and Laulama Schools result from a 13-year venture focused on impacting education in Hawaii. This venture started
                                in 2010 with the formation of an organizational transformation practice called Optimum Business Solutions.</p>
                            <Button className="education-button">Education </Button>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'About',
            content: (
                <div id="about" className="about-details">
                    <div className="m-5">
                        <p>Mel Horikami began by learning as much as he could about the challenges facing the public education system in Hawaii. This undertaking involved queries and discussions with a wide range of stakeholders involved in the education arena—business leaders, community members, union representatives, administrators, teachers, students, parents, and many others. He also gained first-hand knowledge
                            by volunteering at schools to work with administrators and staff from elementary to high schools and at district and state offices. Through these efforts, Mel learned about the persistent “nature” of the educational challenge and realized it was not due to a lack of good instructional practices, programs, resources, or dedicated teachers and leaders. He concluded that schools needed a way to combine all the good inputs for increased results and envisioned a platform to serve that purpose.</p>
                    </div>
                </div>
            )
        },
        {
            title: 'Technologies',
            content: (
                <div id="technologies" className="technologies-details">
                    <div className="m-5">
                        <span>Here’s a concise summary of the technologies and methodologies employed:</span>
                        <div>
                            <ul>
                                <li>AWS Cloud Technologies: Provides a robust and scalable infrastructure.</li>
                                <li>React for Front-End: Delivers a dynamic and responsive user interface across devices.</li>
                                <li>Design Thinking Methodologies: Ensures a user-centric approach to product development.</li>
                                <li>API Ecosystem: Facilitates connectivity and integration of various services and data.</li>
                                <li>Java Framework for Back-End: Offers a secure and robust server-side solution.</li>
                                <li>Scheduling Module: Manages class schedules, appointments, and resources efficiently.</li>
                                <li>Report Generating: Automates the creation of insightful and structured reports.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        },
        {
            title: 'Frameworks',
            content: (
                <div id="frameworksUsed" className="frameworks-details">
                    <div className="m-5">
                        <ul>
                            <li>AWS suite for scalable and reliable cloud services</li>
                            <li>ReactJS, HTML5, CSS3, and JavaScript for responsive front-end</li>
                            <li>WebRTC and WebSocket for real-time audio/video and chat communication</li>
                            <li>PayPal APIs for secure payment processing</li>
                            <li>Custom Java frameworks, Spring, API’s</li>
                            <li>Service workers and push APIs for real-time notifications</li>
                            <li>Custom scheduling algorithms.</li>
                            <li>Apache PDFBox for structured PDF report generation</li>
                        </ul>
                    </div>
                </div>
            )
        },
        {
            title: 'Testimonials',
            content: (
                <div id="testimonials" className="testimonials-details">
                    <div className="m-5">
                        <div className="testimonal-section grid">
                            {testimonials.map(testimonial => (
                                <div className="testimonial-card col-12 md:col-12 lg:col-5 xl:col-5">
                                    <div className='testimonial'>
                                        <div className="testimonial-names">
                                            <div className='tuser-name'>{testimonial.name}</div>
                                            <span>{testimonial.role}</span>
                                        </div>
                                        <div><img className='quote-image' src={quote} alt="" /></div>
                                    </div>
                                    <div className='tuser-desc'>{testimonial.description}</div>
                                    <div className='rating'>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                        <div><FontAwesomeIcon icon={faStar} className='star-gray' /></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        },
    ];

    return (
        <>
            <Header />
            <div className="laulama-project-section">
                <div className="back m-5">
                    <Button className='back-button'><a href="/investor"> <FaChevronLeft /> Back</a></Button>
                </div>
                <div className="m-5 laulama-img-details">
                    <div className="laulama-content-section p-3">
                        <div className="flex align-items-center justify-content-between">
                            <div className="flex align-items-center justify-content-start gap-5 ">
                                <img src={LaulamaLogo} alt='LaulamaLogo' />
                                <div>
                                    <div>Laulama Project</div>
                                    <span>2023</span>
                                </div>
                            </div>
                            <div>
                                <a href="https://laulamaschools.com/" target="_blank"><Button>Visit the site </Button></a>
                            </div>
                        </div>
                        <div>
                            <p>Laulama school is working to innovate education’s underlying improvement system for breakthroughs that transform schools for their leaders, educators, and students. Laulama Learning and Laulama Schools result from a 13-year venture focused on impacting education in Hawaii. This venture started
                                in 2010 with the formation of an organizational transformation practice called Optimum Business Solutions.</p>
                            <Button className="education-button">Education </Button>
                        </div>
                    </div>
                </div>
                <div className="flex  flex-wrap m-5 laulama-main-details">
                    <div className="col-3 laulama-sub-headings">
                        <p
                            className={activeSection === "about" ? "active" : ""}
                            onClick={() => scrollToSection("about")}
                            style={{ backgroundColor: activeSection === "about" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "about" ? '#fff' : '#000' }}
                        >
                            About
                        </p>
                        <p
                            className={activeSection === "technologies" ? "active" : ""}
                            onClick={() => scrollToSection("technologies")}
                            style={{ backgroundColor: activeSection === "technologies" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "technologies" ? '#fff' : '#000' }}
                        >
                            Technologies
                        </p>
                        <p
                            className={activeSection === "frameworksUsed" ? "active" : ""}
                            onClick={() => scrollToSection("frameworksUsed")}
                            style={{ backgroundColor: activeSection === "frameworksUsed" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "frameworksUsed" ? '#fff' : '#000' }}
                        >
                            Frameworks
                        </p>
                        <p
                            className={activeSection === "testimonials" ? "active" : ""}
                            onClick={() => scrollToSection("testimonials")}
                            style={{ backgroundColor: activeSection === "testimonials" ? '#220D50' : 'transparent', padding: '0.5rem', borderRadius: "15px", color: activeSection === "testimonials" ? '#fff' : '#000' }}
                        >
                            Testimonials
                        </p>
                    </div>
                    <div className="col-9 laulama-sub-paragraphs">
                        <div id="about">
                            <>
                                <h3>About</h3>
                                <p>Mel Horikami began by learning as much as he could about the challenges facing the public education system in Hawaii. This undertaking involved queries and discussions with a wide range of stakeholders involved in the education arena—business leaders, community members, union representatives, administrators, teachers, students, parents, and many others. He also gained first-hand knowledge
                                    by volunteering at schools to work with administrators and staff from elementary to high schools and at district and state offices. Through these efforts, Mel learned about the persistent “nature” of the educational challenge and realized it was not due to a lack of good instructional practices, programs, resources, or dedicated teachers and leaders. He concluded that schools needed a way to combine all the good inputs for increased results and envisioned a platform to serve that purpose.</p>
                            </>
                        </div>
                        <div id="technologies">
                            <>
                                <h3>Technologies</h3>
                                <span>Here’s a concise summary of the technologies and methodologies employed:</span>
                                <div>
                                    <ul>
                                        <li>AWS Cloud Technologies: Provides a robust and scalable infrastructure.</li>
                                        <li>React for Front-End: Delivers a dynamic and responsive user interface across devices.</li>
                                        <li>Design Thinking Methodologies: Ensures a user-centric approach to product development.</li>
                                        <li>API Ecosystem: Facilitates connectivity and integration of various services and data.</li>
                                        <li>Java Framework for Back-End: Offers a secure and robust server-side solution.</li>
                                        <li>Scheduling Module: Manages class schedules, appointments, and resources efficiently.</li>
                                        <li>Report Generating: Automates the creation of insightful and structured reports.</li>
                                    </ul>
                                </div>
                            </>
                        </div>
                        <div id="frameworksUsed">
                            <>
                                <h3>Frameworks</h3>
                                <ul>
                                    <li>AWS suite for scalable and reliable cloud services</li>
                                    <li>ReactJS, HTML5, CSS3, and JavaScript for responsive front-end</li>
                                    <li>WebRTC and WebSocket for real-time audio/video and chat communication</li>
                                    <li>PayPal APIs for secure payment processing</li>
                                    <li>Custom Java frameworks, Spring, API’s</li>
                                    <li>Service workers and push APIs for real-time notifications</li>
                                    <li>Custom scheduling algorithms.</li>
                                    <li>Apache PDFBox for structured PDF report generation</li>
                                </ul>
                            </>
                        </div>
                        <div id="testimonials">
                            <>
                                <h3>Testimonials</h3>
                                <div className="testimonal-section grid">
                                    {testimonials.map(testimonial => (
                                        <div className="testimonial-card col-12 md:col-12 lg:col-5 xl:col-5">
                                            <div className='testimonial'>
                                                <div className="testimonial-names">
                                                    <div className='tuser-name'>{testimonial.name}</div>
                                                    <span>{testimonial.role}</span>
                                                </div>
                                                <div><img className='quote-image' src={quote} alt="" /></div>
                                            </div>
                                            <div className='tuser-desc'>{testimonial.description}</div>
                                            <div className='rating'>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-green' /></div>
                                                <div><FontAwesomeIcon icon={faStar} className='star-gray' /></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mobile-laulama-project-section'>
                <div className="back m-5">
                    <Button className='back-button'><a href="/investor"> <FaChevronLeft /> Back</a></Button>
                </div>
                <Accordion>
                    {projects.map((project, index) => (
                        <AccordionTab
                            key={index}
                            header={
                                <div
                                    className={`accordion-header ${activeIndex === index ? 'active-tab' : ''}`}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    {project.title}
                                    <FontAwesomeIcon
                                        icon={activeIndex === index ? faMinus : faPlus}
                                        className="accordion-icon"
                                    />
                                </div>
                            }
                            contentClassName={activeIndex === index ? 'active-content' : ''}
                        >
                            {project.content}
                        </AccordionTab>
                    ))}
                </Accordion>
            </div>
            <Footer />
        </>

    );
};

export default LaulamaProject;
