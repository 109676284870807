import React from 'react';
import './StudentWorkshopDetails.scss';
import { Button } from 'primereact/button';
import { FaClock, FaLocationDot } from 'react-icons/fa6';
import { FaCalendar } from 'react-icons/fa';

const StudentWorkshopDetails = () => {

    return (
        <>
            <div className='studentworkshop-section'>
                <div className="container ">
                    <div className="content col" >
                        <div className="card">
                            <h2 className='text'>Startup Foundations: Your path to Entrepreneurship</h2>
                            {/* <h2>Entrepreneurship With Internship</h2> */}
                            <p className="text">
                                When you list 'Founder' on your college application, you're not just another student.
                            </p>
                            <div className='event-details'>
                                <span><FaLocationDot /> 3030 Broadmoor Ln, Flower Mound, TX 75022 </span>
                                <span><FaCalendar /> September 8th, Sunday &nbsp; <FaClock /> 2pm - 4pm CDT</span>
                            </div>



                            <Button className=''>
                                <a href="https://www.eventbrite.com/e/startup-foundations-your-path-to-entrepreneurship-for-teens-tickets-995550286707?aff=oddtdtcreator" target='_blank' rel="noreferrer">Register Here</a>
                            </Button>
                        </div>
                        <div className="img image-one "></div>
                    </div>
                </div>
            </div>

            <>
                <div className='studentworkshop-section-mobile'>
                    <div className="container ">
                        <div className="content" >
                            <div className="card">
                                <div className="img image-one "></div>
                                <h2 className='text'>Startup Foundations: Your path to Entrepreneurship</h2>
                                {/* <h2>Entrepreneurship With Internship</h2> */}
                                <div className='event-details'>
                                    <span><FaLocationDot /> 3030 Broadmoor Ln, Flower Mound, TX 75022 </span>
                                    <span><FaCalendar /> September 8th, Sunday </span>
                                    <span> <FaClock /> 2pm - 4pm CDT</span>
                                </div>

                                <p className="text">
                                    Embark on a transformative journey with us as we
                                    ignite the spark of inspiration and empowerment for
                                    the future leaders and innovators. Join our insightful
                                    session and become part of the movement shaping
                                    tomorrow's visionaries!
                                </p>

                                <Button className='w-full block'>
                                    <a href="https://www.eventbrite.com/e/startup-foundations-your-path-to-entrepreneurship-for-teens-tickets-995550286707?aff=oddtdtcreator" target='_blank' rel="noreferrer">Register Here</a>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default StudentWorkshopDetails;