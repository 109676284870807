import React from 'react';
import "./ProgramDetails.scss";
import eclipseLeft from "../../../../Assets/Academy/Ellipse-left.png";
import eclipseRight from "../../../../Assets/Academy/Ellipse-Right.png";
import step1 from "../../../../Assets/Academy/Step-1.png";
import step2 from "../../../../Assets/Academy/Step-2.png";
import step3 from "../../../../Assets/Academy/Step-3.png";
import step4 from "../../../../Assets/Academy/Step-4.png";

const ProgramDetails = () => {
    return (
        <>
            <div className='program-details-section'>
                <div className="header-section">
                    {/* <h2>Program Details</h2> */}
                    <p>Dive Deep into Your Entrepreneurial Journey</p>
                </div>

                <div className="eclipse-left">
                    <img src={eclipseLeft} alt="" />
                </div>

                <div className="program-details">
                    <div className="program-content-section">
                        <div className="content">
                            <div className="step">
                                <div className="step-count">1</div>
                                <div className="step-img">
                                    <img src={step1} alt="Foundation Building Image" />
                                </div>
                            </div>

                            <div className="main-text">
                                <div className="header">Ideation</div>
                                <div className="content">
                                    Description of the first phase focusing on Clarity, Collaboration,
                                    Time Management, and Feedback Gathering.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="program-content-section">
                        <div className="contents">
                            <div className="step">
                                <div className="step-count">2</div>
                                <div className="step-img">
                                    <img src={step2} alt="Idea to Launch Image" />
                                </div>
                            </div>

                            <div className="main-text">
                                <div className="header">Prototyping</div>
                                <div className="content">
                                    Insights into the second phase, including Market Research,
                                    Prototype Development, Marketing Plan creation, Iteration, and Launch strategies.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="program-content-section">
                        <div className="content">
                            <div className="step">
                                <div className="step-count">3</div>
                                <div className="step-img">
                                    <img src={step3} alt="Specialized Tracks  & Expert Lectures Image" />
                                </div>
                            </div>

                            <div className="main-text">
                                <div className="header">Specialized Tracks & <br />Expert Lectures</div>
                                <div className="content">
                                    Specialize in digital technology or social entrepreneurship
                                    and mention the guest lectures from industry experts.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="program-content-section">
                        <div className="contents">
                            <div className="step">
                                <div className="step-count">4</div>
                                <div className="step-img">
                                    <img src={step4} alt="Schedule & Fees Image" />
                                </div>
                            </div>

                            <div className="main-text">
                                <div className="header">Schedule & Fees</div>
                                <div className="content grid m-0">
                                    Each cohort will last 8 weeks. Classes will be on weekends (Saturday or Sunday mornings).
                                    <br />
                                    The fee is $500/Student/Month.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="eclipse-right">
                    <img src={eclipseRight} alt="" />
                </div>
            </div>

        </>
    )
}

export default ProgramDetails