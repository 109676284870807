import React, { useEffect, useState } from 'react';
import "./HowItWorks.scss";
import Image1 from "../../../Assets/Investor/Discover_Opportunities.png";
import Image2 from "../../../Assets/Investor/Connect_with_founders_open.png";
import Image3 from "../../../Assets/Investor/Invest_and_mentor_open.png";
import Image4 from "../../../Assets/Investor/Track_and_expand_open.png";

const HowItWorks = () => {

    const [showContent, setShowContent] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(" ");

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem("step1");
    };

    useEffect(() => {
        const hash = window.location.hash;

        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }

        } else {
            setShowContent(false);
        }
    }, [window.location.hash]);

    return (
        <div>
            <div className="howitworks-heading">
                <h2>How It Works?</h2>
                <p>Connect, mentor, and invest in high-potential
                    high school startups through an intuitive platform designed for seamless investor engagement.</p>
            </div>

            <div className="system-banner-section">
                <div className={`item ${hoveredItem === "step1" ? 'hovered' : ''}`} data-order="1" onMouseEnter={() => handleMouseEnter("step1")} onMouseLeave={handleMouseLeave}>

                    {hoveredItem === "step1" ? (
                        <div className="content">
                            <h2>Discover Opportunities</h2>
                            <p>Browse our curated selection of promising startups across key industries.</p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "#220D50CC", height: "100%" }}>
                            <h2 className='default-label'>Discover Opportunities</h2>
                        </div>
                    }
                </div>

                <div className={`item ${hoveredItem === "step2" ? 'hovered' : ''}`} data-order="2" onMouseEnter={() => handleMouseEnter("step2")} onMouseLeave={handleMouseLeave}>
                    {hoveredItem === "step2" ? (
                        <div className="content">
                            <h2>Connect with Founders </h2>
                            <p>Use our platform to directly engage with entrepreneurs and delve into their business models and growth plans. </p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "#EFE5D9CC", height: "100%" }}>
                            <h2 className='default-label'>Connect with Founders </h2>
                        </div>
                    }
                </div>

                <div className={`item ${hoveredItem === "step3" ? 'hovered' : ''}`} data-order="3" onMouseEnter={() => handleMouseEnter("step3")} onMouseLeave={handleMouseLeave} >
                    {hoveredItem === "step3" ? (
                        <div className="content">

                            <h2>Invest and Mentor</h2>
                            <p>Choose your investment ventures and take an active role in mentoring to guide startups towards success. </p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "#8DAF6180", height: "100%" }}>
                            <h2 className='default-label'>Invest and Mentor</h2>
                        </div>
                    }
                </div>

                <div className={`item ${hoveredItem === "step4" ? 'hovered' : ''}`} data-order="4" onMouseEnter={() => handleMouseEnter("step4")} onMouseLeave={handleMouseLeave} >
                    {hoveredItem === "step4" ? (
                        <div className="content">

                            <h2>Track and Expand</h2>
                            <p>Monitor your investments and explore further opportunities for engagement and growth within our community.</p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "#1F306E80", height: "100%" }}>
                            <h2 className='default-label'>Track and Expand</h2>
                        </div>
                    }
                </div>
            </div>

            <div className="mob-system-banner-section">
                <div className="content">
                    <div className="overlay" style={{ background: "#220D50CC" }}>
                        <h2>Discover Opportunities</h2>
                        <p>Browse our curated selection of promising startups across key industries.</p>
                    </div>
                    <img src={Image1} alt="Discover Opportunities" className="image" />
                </div>
                <div className="content">
                    <div className="overlay" style={{ background: "#EFE5D9CC" }}>
                        <h2>Connect with Founders</h2>
                        <p>Use our platform to directly engage with entrepreneurs and delve into their business models and growth plans.</p>
                    </div>
                    <img src={Image2} alt="Connect with Founders" className="image" />
                </div>
                <div className="content">
                    <div className="overlay" style={{ background: "#8DAF6180" }}>
                        <h2>Invest and Mentor</h2>
                        <p>Choose your investment ventures and take an active role in mentoring to guide startups towards success.</p>
                    </div>
                    <img src={Image3} alt="Invest and Mentor" className="image" />
                </div>
                <div className="content">
                    <div className="overlay" style={{ background: "#1F306E80" }}>
                        <h2>Track and Expand</h2>
                        <p>Monitor your investments and explore further opportunities for engagement and growth within our community.</p>
                    </div>
                    <img src={Image4} alt="Track and Expand" className="image" />
                </div>
            </div>
        </div>
    )
}

export default HowItWorks